import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Text,
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Divider,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
  GridItem,
  useBreakpointValue,
  useToast,
  ModalFooter,
  Center,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SelectItem from './SelectItem';
import CCIcon from '../../../../assests/Icons/Resource/BookBookmark.png';
import UHIcon from '../../../../assests/Icons/Upload/Upload.png';
import KNIcon from '../../../../assests/Icons/KnowledgeNugget/Hexagon.png';
import { ErrorMessage, Form, Formik } from 'formik';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import gtag from 'ga-gtag';
import { AiOutlineSearch } from 'react-icons/ai';

function CreateProject({
  isOpen,
  onClose,
  nonsummaryhiveData,
  resourceData,
  knowledgeNuggetData,
  knowledgeLibraryData,
  projectData,
  userInfo,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');

  const history = useHistory();

  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const initialValues = {
    project_name: '',
    selected_resourse_ids: [],
    selected_nonsummary_ids: [],
    selected_knowledeNuggets_ids: [],
  };
  const validationSchema = Yup.object({
    project_name: Yup.string()
      .required('Project name is required')
      .test(
        'len',
        'Project Name Must be less than  20 characters',
        val => val?.length < 20
      ),
  });
  const onSubmit = async (values, actions) => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const { REACT_APP_GA_MEASUREMENT_ID } = process.env;

    let userLocalData = JSON.parse(localStorage.user);
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };

    await Axios.post(
      `${REACT_APP_EH_BACKEND}/projects/${userLocalData._id}`,
      values,
      headers
    ).then(res => {
      if (res.data.status) {
        gtag('config', REACT_APP_GA_MEASUREMENT_ID);
        alertHandler({
          status: res.data.status,
          message: res.data.message,
        });
        if (res.data.status === 'success') {
          gtag('event', 'create_project', {
            project_name: `${values.project_name}`,
            userid: `${userLocalData._id}`,
            full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
            verified: `${userLocalData.isVerified}`,
            selected_knowledeNuggets: `${values.selected_knowledeNuggets_ids}`,
            selected_nonsummaries: `${values.selected_nonsummary_ids}`,
            selected_cources: `${values.selected_resourse_ids}`,
          });
        } else {
          gtag('event', 'exception', {
            process: `creating project of name: ${values.project_name} by ${userLocalData.firstName} ${userLocalData.lastName}`,
            description: `error occured while crating the project "${values.project_name}" by "${userLocalData.firstName} ${userLocalData.lastName}" user id: ${userLocalData._id}`,
            fatal: false,
          });
        }
        actions.setSubmitting(false);
        actions.resetForm();
      }
      history.push(`/project/${res.data.data._id}`);
    });
  };

  const handlethis = e => {
    e.preventDefault();
  };

  const handleNonName = id => {
    const nonsummobj = nonsummaryhiveData?.find(item => item._id === id);
    return nonsummobj.filename;
  };
  const handleResName = id => {
    const resobj = resourceData?.find(item => item.resource._id === id);
    return resobj.resource.resource_name;
  };
  const handleKNuggetName = id => {
    const knuggetobj = knowledgeNuggetData.find(item => item._id === id);
    return knuggetobj.knowledge_nugget_name;
  };
  const handleKNlibName = id => {
    const knuggetobj = knowledgeLibraryData.find(item => item._id === id);
    return knuggetobj.knowledge_nugget_name;
  };
  const handleKNuggetType = id => {
    const knuggetobj = knowledgeNuggetData.find(item => item._id === id);
    return knuggetobj.type;
  };
  const handleKNlibType = id => {
    const knuggetobj = knowledgeLibraryData.find(item => item._id === id);
    return knuggetobj.type;
  };

  return (
    <Box>
      <Modal
        size={'5xl'}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        shadow="md"
        scrollBehavior="outside"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formik => (
            <Form onSubmit={handlethis} autoComplete="off">
              <ModalOverlay bg="rgba(0,0,0,0.48)" />
              <ModalContent
                color="black"
                bg="gray.50"
                px="6"
                rounded="16px"
                boxShadow="md"
                minH="590px"
              >
                <ModalHeader pl={0}>
                  <Text
                    color="gray.900"
                    fontFamily="body"
                    fontWeight="bold"
                    fontSize="20px"
                  >
                    Create Project
                  </Text>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody p="0" m="0" overflowY="auto">
                  <Box>
                    <FormControl
                      isInvalid={
                        (formik.errors.project_name &&
                          formik.touched.project_name) ||
                        projectData?.find(
                          item =>
                            item.project_name === formik.values.project_name
                        )
                      }
                    >
                      <FormLabel htmlFor="project_name" />
                      <Input
                        id="project_name"
                        // p="12px"
                        w={['full', 'full', '60%']}
                        type="text"
                        borderRadius="4px"
                        placeholder="Name your project"
                        _placeholder={{ fontfamily: 'body' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.project_name}
                      />
                      <FormErrorMessage>
                        <ErrorMessage name="project_name" />
                      </FormErrorMessage>
                      {projectData?.find(
                        item => item.project_name === formik.values.project_name
                      ) && (
                        <Text
                          fontFamily={'body'}
                          fontSize="14px"
                          fontWeight="normal"
                          color="red"
                          my={2}
                        >
                          Project Already on Hive, Please Add a Different
                          ProjectName
                        </Text>
                      )}
                    </FormControl>
                    <Divider my={'6'} />
                    <FormControl>
                      <FormLabel htmlFor="searchValue" my={3}>
                        <Flex direction={['column', 'row']}>
                          <Text
                            color="gray.900"
                            fontWeight="bold"
                            fontSize="14px"
                          >
                            Organize your knowledge
                          </Text>
                          <Text
                            ml={[0, 2]}
                            fontSize="14px"
                            color="gray.700"
                            fontWeight="normal"
                          >
                            Select learning materials to place in your new
                            project
                          </Text>
                        </Flex>
                      </FormLabel>
                      <InputGroup w={['full', 'full', '50%']}>
                        <Input
                          name="searchValue"
                          id="searchValue"
                          type="text"
                          // p="12px"
                          borderRadius="4px"
                          placeholder="Search my Hive"
                          fontFamily={'body'}
                          onChange={e => setSearchValue(e.target.value)}
                          value={searchValue}
                        />
                        <InputRightElement
                          pointerEvents="none"
                          children={
                            <Icon
                              as={AiOutlineSearch}
                              w="24px"
                              h="24px"
                              color="gray.500"
                            />
                          }
                        />
                      </InputGroup>
                    </FormControl>
                    <Flex
                      direction="column"
                      align="start"
                      justify="space-evenly"
                    >
                      <Box my={3}>
                        {/* conditionally render if user click on any resource or nonsummery data */}
                        {(formik.values.selected_resourse_ids.length > 0 ||
                          formik.values.selected_nonsummary_ids.length > 0 ||
                          formik.values.selected_knowledeNuggets_ids.length >
                            0) && (
                          <Box>
                            <Text fontWeight="normal" color="gray.700">
                              Selected materials
                            </Text>
                            <Center>
                              <Grid
                                templateColumns={[
                                  'repeat(1, 1fr)',
                                  'repeat(1, 1fr)',
                                  'repeat(2, 1fr)',
                                  'repeat(4, 1fr)',
                                ]}
                                gap={2}
                                overflowY="auto"
                                maxH="60"
                              >
                                {formik.values.selected_resourse_ids.map(
                                  (item, index) => (
                                    <GridItem key={index}>
                                      <SelectItem
                                        selected={true}
                                        Icon={CCIcon}
                                        setFieldValue={formik.setFieldValue}
                                        value={
                                          formik.values.selected_resourse_ids
                                        }
                                        id={item}
                                        name={handleResName(item)}
                                      />
                                    </GridItem>
                                  )
                                )}
                                {formik.values.selected_nonsummary_ids.map(
                                  (item, index) => (
                                    <GridItem key={index}>
                                      <SelectItem
                                        selected={true}
                                        Icon={UHIcon}
                                        setFieldValue={formik.setFieldValue}
                                        value={
                                          formik.values.selected_nonsummary_ids
                                        }
                                        id={item}
                                        name={handleNonName(item)}
                                      />
                                    </GridItem>
                                  )
                                )}
                                {formik.values.selected_knowledeNuggets_ids.map(
                                  (item, index) => (
                                    <GridItem key={index}>
                                      <SelectItem
                                        selected={true}
                                        Icon={KNIcon}
                                        setFieldValue={formik.setFieldValue}
                                        value={
                                          formik.values
                                            .selected_knowledeNuggets_ids
                                        }
                                        id={item}
                                        name={
                                          userInfo?.user_KN_Lib?.find(
                                            element => element?.id === item
                                          )
                                            ? handleKNlibName(item)
                                            : handleKNuggetName(item)
                                        }
                                        type={
                                          userInfo?.user_KN_Lib?.find(
                                            element => element?.id === item
                                          )
                                            ? handleKNlibType(item)
                                            : handleKNuggetType(item)
                                        }
                                      />
                                    </GridItem>
                                  )
                                )}
                              </Grid>
                            </Center>
                            <Divider my={6} />
                          </Box>
                        )}
                        {/* filter based on search or show all */}
                        {searchValue ? (
                          <Center>
                            <Grid
                              templateColumns={[
                                'repeat(1, 1fr)',
                                'repeat(1, 1fr)',
                                'repeat(2, 1fr)',
                                'repeat(4, 1fr)',
                              ]}
                              gap={2}
                              overflowY="auto"
                              maxH="60"
                            >
                              {nonsummaryhiveData
                                ?.filter(item => {
                                  if (
                                    item.filename
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase()) &&
                                    formik.values.selected_nonsummary_ids.includes(
                                      item._id
                                    ) === false
                                  ) {
                                    return item;
                                  } else {
                                    return null;
                                  }
                                })
                                ?.map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item._id}
                                      Icon={UHIcon}
                                      name={item.filename}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values.selected_nonsummary_ids
                                      }
                                      data={item}
                                    />
                                  </GridItem>
                                ))}
                              {resourceData
                                ?.filter(item => {
                                  if (
                                    item.resource.resource_name
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase()) &&
                                    formik.values.selected_resourse_ids.includes(
                                      item.resource._id
                                    ) === false
                                  ) {
                                    return item;
                                  } else {
                                    return null;
                                  }
                                })
                                ?.map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item.resource._id}
                                      Icon={CCIcon}
                                      name={item.resource.resource_name}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values.selected_resourse_ids
                                      }
                                      data={item.resource}
                                    />
                                  </GridItem>
                                ))}
                              {knowledgeNuggetData
                                ?.filter(item => {
                                  if (
                                    item.knowledge_nugget_name
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase()) &&
                                    formik.values.selected_knowledeNuggets_ids.includes(
                                      item._id
                                    ) === false
                                  ) {
                                    return item;
                                  } else {
                                    return null;
                                  }
                                })
                                ?.map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item._id}
                                      Icon={KNIcon}
                                      name={item.knowledge_nugget_name}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values
                                          .selected_knowledeNuggets_ids
                                      }
                                      data={item}
                                    />
                                  </GridItem>
                                ))}
                              {knowledgeLibraryData
                                ?.filter(item => {
                                  if (
                                    item.knowledge_nugget_name
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase()) &&
                                    formik.values.selected_knowledeNuggets_ids.includes(
                                      item._id
                                    ) === false
                                  ) {
                                    return item;
                                  } else {
                                    return null;
                                  }
                                })
                                ?.map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item._id}
                                      Icon={KNIcon}
                                      name={item.knowledge_nugget_name}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values
                                          .selected_knowledeNuggets_ids
                                      }
                                      data={item}
                                    />
                                  </GridItem>
                                ))}
                            </Grid>
                          </Center>
                        ) : (
                          <Center>
                            <Grid
                              templateColumns={[
                                'repeat(1, 1fr)',
                                'repeat(1, 1fr)',
                                'repeat(2, 1fr)',
                                'repeat(4, 1fr)',
                              ]}
                              gap={2}
                              maxH={['40', 'full']}
                              overflowY="auto"
                            >
                              {/* conditionally filter based on search values or show all data */}
                              {nonsummaryhiveData
                                ?.filter(item => {
                                  if (
                                    formik.values.selected_nonsummary_ids.includes(
                                      item._id
                                    )
                                  ) {
                                    return null;
                                  } else {
                                    return item;
                                  }
                                })
                                .map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item._id}
                                      Icon={UHIcon}
                                      name={item.filename}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values.selected_nonsummary_ids
                                      }
                                      data={item}
                                    />
                                  </GridItem>
                                ))}
                              {resourceData
                                ?.filter(item => {
                                  if (
                                    formik.values.selected_resourse_ids.includes(
                                      item.resource._id
                                    )
                                  ) {
                                    return null;
                                  } else {
                                    return item;
                                  }
                                })
                                .map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item.resource._id}
                                      Icon={CCIcon}
                                      name={item.resource.resource_name}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values.selected_resourse_ids
                                      }
                                      data={item.resource}
                                    />
                                  </GridItem>
                                ))}
                              {knowledgeNuggetData
                                ?.filter(item => {
                                  if (
                                    formik.values.selected_knowledeNuggets_ids.includes(
                                      item._id
                                    )
                                  ) {
                                    return null;
                                  } else {
                                    return item;
                                  }
                                })
                                .map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item._id}
                                      Icon={KNIcon}
                                      name={item.knowledge_nugget_name}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values
                                          .selected_knowledeNuggets_ids
                                      }
                                      data={item}
                                    />
                                  </GridItem>
                                ))}
                              {knowledgeLibraryData
                                ?.filter(item => {
                                  if (
                                    formik.values.selected_knowledeNuggets_ids.includes(
                                      item._id
                                    )
                                  ) {
                                    return null;
                                  } else {
                                    return item;
                                  }
                                })
                                .map((item, index) => (
                                  <GridItem key={index}>
                                    <SelectItem
                                      selected={false}
                                      id={item._id}
                                      Icon={KNIcon}
                                      name={item.knowledge_nugget_name}
                                      setFieldValue={formik.setFieldValue}
                                      value={
                                        formik.values
                                          .selected_knowledeNuggets_ids
                                      }
                                      data={item}
                                    />
                                  </GridItem>
                                ))}
                            </Grid>
                          </Center>
                        )}
                      </Box>
                    </Flex>
                  </Box>
                </ModalBody>
                <ModalFooter alignItems="center" justifyContent="center">
                  <Button
                    isDisabled={
                      formik.values.selected_resourse_ids.length > 0 ||
                      formik.values.selected_nonsummary_ids.length > 0 ||
                      formik.values.selected_knowledeNuggets_ids.length > 0
                        ? false
                        : true
                    }
                    type="button"
                    colorScheme={'yellow'}
                    isLoading={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                  >
                    <Text
                      fontWeight="bold"
                      fontSize="18px"
                      color="gray.900"
                      fontFamily={'body'}
                    >
                      Save Project
                    </Text>
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </Box>
  );
}

export default CreateProject;
