import { Flex, Spinner, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ url, fileName }) {
  const [numPages, setNumPages] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [errorBlob, setErrorBlob] = useState(null);
  let pageNumber = 1;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (fileName && url) {
      try {
        const { REACT_APP_EH_BACKEND } = process.env;
        axios
          .put(
            `${REACT_APP_EH_BACKEND}/nonsummery/download`,
            {
              url: url,
            },
            {
              responseType: 'blob',
              headers: {
                Authorization: 'Bearer ' + localStorage.login_token,
              },
            }
          )
          .then(response => {
            setPdfBlob(response.data);
            setErrorBlob(null);
          })
          .catch(err => {
            console.log(err);
            setPdfBlob(null);
            setErrorBlob(true);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [fileName, url]);


  return (
    <>
      {pdfBlob ? (
        <Flex align={'center'} justify="center" flexDir={'column'}>
          <Document
            file={pdfBlob}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={error => console.log('Inside Error', error)}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <Text my={2} fontFamily="body" fontSize={'16px'}>
            {' '}
            Page {pageNumber} of {numPages}
          </Text>
        </Flex>
      ) : errorBlob ? (
        <Text fontFamily={'body'}>Preview Unavailable</Text>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default PDFViewer;
