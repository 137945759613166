import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import cytoscape from 'cytoscape';
import axios from 'axios';

const KnowledgeGraph = () => {
  const graphRef = useRef(null);
  const location = useLocation();
  const { REACT_APP_EH_BACKEND } = process.env;
  const [knowledgeGraphData, setKnowledgeGraphData] = useState([]);
  const [haveprojdata, setHaveprojectdata] = useState(false);
  const [projectdata, setProjectData] = useState();
  const [nonSummeryData, setNonsummerydata] = useState();
  const [resourceData, setResourceData] = useState();
  const [knowledgeNuggetData, setKnowledgeNuggetData] = useState();
  const projectId = new URLSearchParams(location.search).get('project_id');

  //GET PROJECT DATA
  useEffect(() => {
    let userLocalData = JSON.parse(localStorage.user);
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/getprojects/${userLocalData._id}/${projectId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(projectDataResponse => {
        console.log('project data', projectDataResponse.data);
        if (projectDataResponse.data) {
          setProjectData(projectDataResponse.data);
          setHaveprojectdata(true);
        }
      })
      .catch(projectDataError => {
        return projectDataError;
      });
  }, [REACT_APP_EH_BACKEND, projectId]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;

    const nonSummeryObj = {};
    const resourceObj = {};
    const knobj = {};
    // based on the reseived data fetch resource data and nonsummery data and knowledge knugget data from the stored
    // data in the database
    if (haveprojdata) {
      projectdata[0]?.selected_nonsummary_ids?.map((nonsummeryItem, index) => {
        nonSummeryObj[index] = nonsummeryItem;
        return null;
      });
      projectdata[0]?.selected_resourse_ids?.map((resourceItem, index) => {
        resourceObj[index] = resourceItem;
        return null;
      });
      projectdata[0]?.selected_knowledeNuggets_ids?.map((knItem, index) => {
        knobj[index] = knItem;
        return null;
      });
      const nonsummeryQueryString = Object.keys(nonSummeryObj)
        .map(key => '_id=' + nonSummeryObj[key])
        .join('&');
      const resourceQueryString = Object.keys(resourceObj)
        .map(key => '_id=' + resourceObj[key])
        .join('&');
      const knQueryString = Object.keys(knobj)
        .map(key => '_id=' + knobj[key])
        .join('&');

      if (
        nonsummeryQueryString !== '' ||
        resourceQueryString !== '' ||
        knQueryString !== ''
      ) {
        axios({
          method: 'GET',
          url: `${REACT_APP_EH_BACKEND}/getnonsummary?${nonsummeryQueryString}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        })
          .then(nonsummeryResponse => {
            setNonsummerydata(nonsummeryResponse.data);
          })
          .catch(err => {
            return err;
          });
        axios({
          method: 'GET',
          url: `${REACT_APP_EH_BACKEND}/resources?${resourceQueryString}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        })
          .then(resourceResponse => {
            setResourceData(resourceResponse.data);
          })
          .catch(err => {
            return err;
          });
        axios({
          method: 'GET',
          url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?${knQueryString}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        })
          .then(knResponse => {
            setKnowledgeNuggetData(knResponse.data);
          })
          .catch(err => {
            return err;
          });
      }
    }
  }, [haveprojdata, projectdata]);

  console.log('nonesummary data', nonSummeryData);
  console.log('resource data', resourceData);
  console.log('k nugget data', knowledgeNuggetData);

  let NonSummeryNameList = [];
  let ResourseNameList = [];
  let KnowledgeNuggetNameList = [];
  let nonSummeryTagList = [];
  let resourceTagList = [];
  let knowledgeNuggetTagList = [];

  let final_list_without_tags = useMemo(() => [], []);
  let final_list_with_tags = useMemo(() => [], []);

  nonSummeryData &&
    nonSummeryData.map(item =>
      NonSummeryNameList.push(item.nonsummaryitem.filename)
    );
  resourceData &&
    resourceData.map(item =>
      ResourseNameList.push(item.resource.resource_name)
    );
  knowledgeNuggetData &&
    knowledgeNuggetData.map(item =>
      KnowledgeNuggetNameList.push(item.knowledgeNugget.knowledge_nugget_name)
    );

  nonSummeryData &&
    nonSummeryData.map(item => {
      let tagList = [];
      let itemName = item.nonsummaryitem.filename;
      item.nonsummaryitem.tags.map(tagItem => tagList.push(tagItem));
      nonSummeryTagList.push([itemName, tagList]);
      return null;
    });
  resourceData &&
    resourceData.map(item => {
      let tagList = [];
      let itemName = item.resource.resource_name;
      item.resource.tags.map(tagItem => tagList.push(tagItem.tag_name));
      resourceTagList.push([itemName, tagList]);
      return null;
    });
  knowledgeNuggetData &&
    knowledgeNuggetData.map(item => {
      let tagList = [];
      let itemName = item.knowledgeNugget.knowledge_nugget_name;
      item.knowledgeNugget.tags.map(tagItem => tagList.push(tagItem.tag_name));
      knowledgeNuggetTagList.push([itemName, tagList]);
      return null;
    });

  final_list_without_tags.push(
    NonSummeryNameList,
    KnowledgeNuggetNameList,
    ResourseNameList
  );
  final_list_with_tags.push(
    nonSummeryTagList,
    knowledgeNuggetTagList,
    resourceTagList
  );

  //CREATE KNOWLEDGE GRAPH
  useEffect(() => {
    axios({
      method: 'POST',
      url: `${REACT_APP_EH_BACKEND}/knowledge/graph`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
      data: {
        final_list_without_tags: final_list_without_tags,
        final_list_with_tags: final_list_with_tags,
      },
    })
      .then(knowledgeGraphResponse => {
        setKnowledgeGraphData(knowledgeGraphResponse.data.graph);
        console.log('knowledge grap data', knowledgeGraphResponse.data.graph);
      })
      .catch(knowledgeGraphError => {
        return knowledgeGraphError;
      });
  }, [REACT_APP_EH_BACKEND, final_list_with_tags, final_list_without_tags]);

  const drawGraph = useCallback(() => {
    let cy = cytoscape({
      container: graphRef.current,
      style: cytoscape
        .stylesheet()
        .selector('node')
        .css({
          label: 'data(label)',
          // Shape
          width: 35,
          height: 35,
          // shape: "rhomboid",
          // Background
          'background-opacity': 0.8,
          // Border
          'border-width': 1,
          'border-style': 'solid',
          'border-color': 'black',
          'border-opacity': 0.8,
          // Padding
          // padding: 10,
          // Background image
          'text-valign': 'center',
          // color: "white",
          // "text-outline-width": 2,
          'font-size': '10px',
        })
        .selector('edge')
        .css({
          label: 'data(label)',
          width: 1,
          'curve-style': 'straight',
          // "haystack-radius": 1,
          'line-color': '#00FFFF',
          // "line-style": "dotted",
          // "line-cap": "round",
          // "line-dash-offset": 25,
          // "selection-color": "red",
          // "selection-glow-strength": 1,
          // "hover-opacity": 0.2,
          'font-size': '8px',
          // tooltip: "data(id)",
        })
        .selector(':selected')
        .css({
          'background-color': 'red',
          'line-color': 'red',
          // "target-arrow-color": "black",
          // "source-arrow-color": "black",
          'text-outline-color': 'red',
          'text-outline-width': 0.3,
        }),

      layout: {
        name: 'grid',
        rows: 1,
        cols: 3,
      },
      // motionBlur: false,
      // motionBlurOpacity: 0.8,
    });

    cy.add(knowledgeGraphData);

    cy.on('click', 'node', function (evt) {
      var node = evt.target;
      console.clear();
      console.log(node.position());
    });

    cy.on('mouseover', 'node', function (evt) {
      var node = evt.target;
      console.clear();
      console.log(node.position());
      console.log(node.data());
      console.log(node.id());
      console.log(node.data().location);
    });
  }, [knowledgeGraphData]);

  useEffect(() => {
    drawGraph();
  }, [drawGraph, knowledgeGraphData]);

  return (
    <ChakraProvider>
      <Box ref={graphRef.current} style={{ width: '100%', height: '100vh' }} />
    </ChakraProvider>
  );
};

export default KnowledgeGraph;
