import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  Box,
  Spacer,
  Text,
  Flex,
  Input,
  useToast,
  useBreakpointValue,
  FormControl,
  FormLabel,
  ModalCloseButton,
  Progress,
  Switch,
  SimpleGrid,
  Center,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import gtag from 'ga-gtag';
import { Image } from '@chakra-ui/react';
import uploadKNlogo from '../../assests/gifs/eh-anim_1_1.gif';

function CreateKnowledgeNugget({
  isOpen,
  onClose,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  updatehivedata,
  setUpdatehivedata,
  nonsummaryhiveData,
  resourceData,
  storageUsed,
  removeAudio,
}) {
  const [url, setUrl] = useState('');
  const [checked, setChecked] = useState(false);
  let isUploaded = false;
  const [isUploading, setIsUploading] = useState(false);
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [progress, setProgress] = useState(0);
  const [haveURL, setHaveURL] = useState(false);
  const [update, setUpdate] = useState(false);
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  let history = useHistory();

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  const ValueIncrement = () => {
    var secs = 120 * 1000;
    var timer = setInterval(function () {
      secs -= 1000;
      if (secs === 0) {
        clearInterval(timer);
      }
      setProgress(progress => progress + 1.2);
    }, 1 * 1000);
  };

  const handlethis = e => {
    e.preventDefault();
  };

  const getSummary = async () => {
    const { REACT_APP_PAPER_DIGEST } = process.env;
    const { REACT_APP_PAPER_DIGEST_KEY } = process.env;
    const { REACT_APP_EH_BACKEND } = process.env;
    const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
    let userLocalData = JSON.parse(localStorage.user);

    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };

    if (url !== '') {
      if (checked === true) {
        const config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
          onUploadProgress: progressEvent => {
            setProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        };
        try {
          const response = await axios.get(
            `${REACT_APP_PAPER_DIGEST}/?key=${REACT_APP_PAPER_DIGEST_KEY}&article_id=${url.trim()}`
          );

          if (
            response.data.hasOwnProperty('metadata') &&
            response.data.hasOwnProperty('summary')
          ) {
            const metadata = {
              title: response.data.metadata.title,
              authors: response.data.metadata.authors,
              doi: response.data.metadata.doi,
              abstract: response.data.metadata.abstract,
            };

            const summary = {
              introduction: response.data.summary.introduction,
              results: response.data.summary.results,
              discussion: response.data.summary.discussion,
              conclusions: response.data.summary.conclusions,
            };

            const newKnowledgeNugget = {
              type: 'Academic paper',
              knowledge_nugget_name: response.data.metadata.title,
              metadata: metadata,
              summary: summary,
            };

            await axios
              .post(
                `${REACT_APP_EH_BACKEND}/knowledge/nuggets/${userLocalData._id}`,
                newKnowledgeNugget,
                config
              )
              .then(createKnowledgeNuggetRes => {
                if (createKnowledgeNuggetRes.status === 200) {
                  if (createKnowledgeNuggetRes.data.status) {
                    alertHandler({
                      status: createKnowledgeNuggetRes.data.status,
                      message: createKnowledgeNuggetRes.data.message,
                    });

                    if (createKnowledgeNuggetRes.data.status === 'success') {
                      const knowledgeNuggetId =
                        createKnowledgeNuggetRes.data.knowledgenuggetId;
                      history.push(
                        `/knowledge/nugget?knowledge_nugget_id=${knowledgeNuggetId}`
                      );
                      setChecked(false);
                      setHaveURL(false);
                      setUrl('');
                      setUpdate(!update);
                      onClose();
                      setUpdatehivedata(!updatehivedata);
                      setUpdateKnowledgeNuggetData(!updateKnowledgeNuggetData);

                      gtag('event', 'create_knowledge_nugget', {
                        knowledge_nugget_name: `${newKnowledgeNugget.knowledge_nugget_name}`,
                        type: `${newKnowledgeNugget.type}`,
                        title: `${response.data.metadata.title}`,
                        doi: `${response.data.metadata.doi}`,
                        userid: `${userLocalData._id}`,
                        full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
                        verified: `${userLocalData.isVerified}`,
                      });
                    } else {
                      gtag('event', 'exception', {
                        process: `creating a knowledge nugget of name: ${newKnowledgeNugget.knowledge_nugget_name} by ${userLocalData.firstName} ${userLocalData.lastName}`,
                        description: `error occured while creating a knowledge nugget of name "${newKnowledgeNugget.knowledge_nugget_name}" by "${userLocalData.firstName} ${userLocalData.lastName}" user id: ${userLocalData._id}, to their hive`,
                        fatal: false,
                      });
                      setChecked(false);
                      setHaveURL(false);
                      setIsUploading(false);
                      setUrl('');
                      setUpdate(!update);
                      onClose();
                    }
                  }
                }
              });
          } else if (response.data.error) {
            // error handlingwith the API
            alertHandler({
              status: 'error',
              message:
                response.data.error.replace(/_/g, ' ') === 'not open access'
                  ? 'Sorry, seems that is not an open access article'
                  : response.data.error.replace(/_/g, ' ') ===
                    'no valid link found'
                  ? 'Sorry, it does not look like a DOI or PDF url'
                  : 'Sorry, the file is unsupported',
            });

            gtag('event', 'exception', {
              process: `creating a knowledge nugget by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
              description: response.data.error.replace(/_/g, ' '),
              fatal: false,
            });
            setChecked(false);
            setHaveURL(false);
            setIsUploading(false);
            setUrl('');
            setUpdate(!update);
            onClose();
          } else {
            gtag('event', 'exception', {
              process: `creating a knowledge nugget by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
              description: 'Not a valid link',
              fatal: false,
            });
            return 'Not a valid link';
          }
        } catch (err) {
          gtag('event', 'exception', {
            process: `creating a knowledge nugget by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
            description: err,
            fatal: false,
          });
          return err;
        } finally {
          return 'loading false';
        }
      } else {
        ValueIncrement();
        try {
          await axios
            .post(
              `${REACT_APP_EH_BACKEND}/create-kn-using-web-scrapping/${userLocalData._id}`,
              { url: url },
              headers
            )
            .then(createKnowledgeNuggetRes => {
              if (createKnowledgeNuggetRes.status === 200) {
                if (createKnowledgeNuggetRes.data.status) {
                  alertHandler({
                    status: createKnowledgeNuggetRes.data.status,
                    message: createKnowledgeNuggetRes.data.message,
                  });

                  if (createKnowledgeNuggetRes.data.status === 'success') {
                    const knowledgeNuggetId =
                      createKnowledgeNuggetRes.data.knowledgenuggetId;
                    history.push(
                      `/knowledge/nugget?knowledge_nugget_id=${knowledgeNuggetId}`
                    );
                    setChecked(false);
                    setHaveURL(false);
                    setIsUploading(false);
                    setUrl('');
                    setUpdate(!update);
                    onClose();
                    setUpdatehivedata(!updatehivedata);
                    setUpdateKnowledgeNuggetData(!updateKnowledgeNuggetData);

                    gtag('event', 'create_knowledge_nugget', {
                      knowledge_nugget_name: `${createKnowledgeNuggetRes.data.knowledgeNugget.knowledge_nugget_name}`,
                      type: `${createKnowledgeNuggetRes.data.knowledgeNugget.type}`,
                      title: `${createKnowledgeNuggetRes.data.knowledgeNugget.metadata.title}`,
                      doi: `${createKnowledgeNuggetRes.data.knowledgeNugget.metadata.doi}`,
                      userid: `${userLocalData._id}`,
                      full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
                      verified: `${userLocalData.isVerified}`,
                    });
                  } else {
                    setChecked(false);
                    setHaveURL(false);
                    setIsUploading(false);
                    setUrl('');
                    setUpdate(!update);
                    onClose();
                    setUpdatehivedata(!updatehivedata);
                    setUpdateKnowledgeNuggetData(!updateKnowledgeNuggetData);

                    gtag('event', 'exception', {
                      process: `creating a knowledge nugget of name: ${createKnowledgeNuggetRes.data.knowledgeNugget.knowledge_nugget_name} by ${userLocalData.firstName} ${userLocalData.lastName}`,
                      description: `error occured while creating a knowledge nugget of name "${createKnowledgeNuggetRes.data.knowledgeNugget.knowledge_nugget_name}" by "${userLocalData.firstName} ${userLocalData.lastName}" user id: ${userLocalData._id}, to their hive`,
                      fatal: false,
                    });
                  }
                }
              }
            });
        } catch (err) {
          gtag('event', 'exception', {
            process: `creating a knowledge nugget by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
            description: err,
            fatal: false,
          });
          setChecked(false);
          setHaveURL(false);
          setIsUploading(false);
          setUrl('');
          setUpdate(!update);
          onClose();
          return err;
        }
      }
    } else {
      gtag('event', 'exception', {
        process: `creating a knowledge nugget by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
        description: 'Failed! Some error occured while summarying',
        fatal: false,
      });
      onClose();
    }
  };

  const createNugget = () => {
    if (removeAudio !== undefined) {
      removeAudio();
    }
    setIsUploading(true);
    setHaveURL(true);
    getSummary();
  };



  return (
    <Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay bg="rgba(0,0,0,0.48)" />
        <ModalContent
          color="black"
          bg="white"
          overflow="auto"
          w="500px"
          px="24px"
          py="16px"
          rounded="16px"
          boxShadow="md"
          maxW="500px"
        >
          <ModalHeader p="0" m="0">
            <Flex>
              <Text
                py="1"
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="20px"
                paddingBottom="4"
              >
                Create Knowledge Nugget
              </Text>
              <Spacer />
              <ModalCloseButton
                onClose={() => {
                  setUrl('');
                }}
              />
            </Flex>
          </ModalHeader>
          <Formik>
            {formik =>
              formik.isSubmitting || isUploading ? (
                <>
                  <Box height="fit-content">
                    <Center>
                      <SimpleGrid columns={1}>
                        <Center>
                          <Image
                            src={uploadKNlogo}
                            width="179.2px"
                            alt="Upload logo"
                          />
                        </Center>
                        <Center>
                          <Text
                            fontFamily={'bold'}
                            fontSize="16px"
                            fontWeight="400"
                            color="gray.700"
                          >
                            Summarizing...
                          </Text>
                        </Center>
                        <Center>
                          <Text
                            fontFamily={'body'}
                            fontSize="12px"
                            fontWeight="400"
                            color="gray.700"
                          >
                            this may take a minute
                          </Text>
                        </Center>
                      </SimpleGrid>
                    </Center>

                    <Text
                      fontFamily={'body'}
                      fontSize="14px"
                      fontWeight="400"
                      color="gray.700"
                      marginTop="4px"
                    >
                      Pasted link
                    </Text>
                    <Text as="u" color="purple.600">
                      {url}
                    </Text>
                    <Progress mt={4} colorScheme={'yellow'} value={progress} />
                  </Box>
                </>
              ) : (
                <>
                  <Form
                    onSubmit={handlethis}
                    autoComplete="off"
                    encType="multipart/form-data"
                  >
                    <ModalBody p="0">
                      <Box>
                        <Text
                          marginTop={4}
                          fontFamily={'Inter'}
                          color="gray.700"
                          fontSize="14px"
                          fontWeight="400"
                          marginBottom={-2}
                        >
                          Get summary of web articles and open-access academic
                          papers in text, visual & audio form.
                        </Text>
                      </Box>
                      <Box>
                        <FormControl>
                          <FormLabel>
                            <Text
                              fontSize="14px"
                              fontFamily={'Inter'}
                              fontWeight="700"
                              color="gray.700"
                              pt="6"
                              pb="1"
                              marginBottom={-1}
                            >
                              Paste link to web article or academic paper
                              {/* or paste link */}
                            </Text>
                          </FormLabel>

                          <Input
                            disabled={haveURL ? true : false}
                            type="text"
                            fontFamily={'body'}
                            placeholder="Paste URL or DOI link"
                            onChange={event => {
                              setUrl(event.target.value);
                            }}
                          />
                        </FormControl>

                        <Box my={4}>
                          <SimpleGrid columns={1}>
                            <Flex my={2} verticalAlign="center">
                              <SimpleGrid columns={1}>
                                <Text
                                  color="gray.900"
                                  fontWeight="400"
                                  fontSize="14px"
                                >
                                  Academic Paper
                                </Text>
                                <Text
                                  fontFamily={'body'}
                                  color="gray.600"
                                  fontSize="10px"
                                  fontWeight="400"
                                  width="336px"
                                  // padding only for pilot launch
                                >
                                  Use Format: https://doi.org/10.papername.doi{' '}
                                </Text>
                                <Text
                                  fontFamily={'body'}
                                  color="gray.600"
                                  fontSize="10px"
                                  fontWeight="400"
                                  width="336px"
                                  // padding only for pilot launch
                                >
                                  OR https://.../papername.pdf{' '}
                                </Text>
                              </SimpleGrid>
                              <Spacer />

                              <Switch
                                id="academic-paper"
                                size="md"
                                isChecked={checked}
                                onChange={() => {
                                  setChecked(!checked);
                                }}
                              />
                            </Flex>
                          </SimpleGrid>
                        </Box>
                      </Box>
                    </ModalBody>
                    <Box align="center">
                      <Button
                        type="button"
                        disabled={
                          (url === '' && isUploaded === false) ||
                          (isUploaded === true && url !== '') ||
                          formik.isSubmitting ||
                          url === ''
                            ? true
                            : false
                        }
                        py="2"
                        px="4"
                        bg="yellow.400"
                        rounded="4px"
                        color="gray.900"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="bold"
                        fontSize="16px"
                        _hover={{ bg: 'yellow.500' }}
                        _active={{
                          bg: 'yellow.500',
                          transform: 'scale(0.98)',
                        }}
                        _focus={{
                          outline: 'none',
                        }}
                        isLoading={formik.isSubmitting || haveURL}
                        onClick={() => {
                          createNugget();
                        }}
                      >
                        Create Nugget
                      </Button>
                    </Box>
                  </Form>
                </>
              )
            }
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CreateKnowledgeNugget;
