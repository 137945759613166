import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import ResourceOverview from './Pages/ResourceOverview';
import PrivateRoute from './Components/PrivateRoute';
import PublicRoute from './Components/PublicRoute';
import SignIn from './Pages/SignIn';
import SignUp from './Pages/Signup';
import ForgotPassword from './Pages/ForgotPassword';
import PasswordReset from './Pages/PasswordReset';
import LessonSummary from './Pages/LessonSummary';
import NonSummaryViewer from './Pages/NonSummaryViewer';
import AuthVerify from './utils/auth-verify';
import ProjectViewer from './Pages/ProjectViewer';
import KnowledgeNugget from './Pages/KnowledgeNugget';
import KnowledgeLibrary from './Pages/KnowledgeLibrary';
import KnowledgeGraph from './Pages/KonwledgeGraph';

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
  }

  logOut() {
    AuthVerify.logOut();
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <PublicRoute
              restricted={false}
              component={PasswordReset}
              path="/passwordreset/:id"
              exact
            />
            <PublicRoute
              restricted={true}
              component={SignIn}
              path="/signin"
              exact
            />
            <PublicRoute
              restricted={false}
              component={SignUp}
              path="/signup"
              exact
            />
            <PublicRoute
              restricted={false}
              component={ForgotPassword}
              path="/forgotpassword"
              exact
            />
            <PrivateRoute
              component={KnowledgeNugget}
              path="/knowledge/nugget"
              exact
            />
             <PrivateRoute
              component={KnowledgeGraph}
              path="/knowledge/graph"
              exact
            />
            <PrivateRoute
              component={LessonSummary}
              path="/resource/overview/lessonsummary"
              exact
            />
            <PrivateRoute
              component={ResourceOverview}
              path="/resource/overview"
              exact
            />
            <PrivateRoute
              component={NonSummaryViewer}
              path="/nonsummary/:id"
              exact
            />
            <PrivateRoute component={ProjectViewer} path="/project/:id" exact />
            <PrivateRoute
              component={KnowledgeLibrary}
              path="/knowledge-library"
              exact
            />
            <PrivateRoute component={HomePage} path="/homepage" exact />
            <PublicRoute restricted={false} component={SignIn} path="/" exact />
          </Switch>
        </BrowserRouter>
        <AuthVerify logOut={this.logOut} />
      </>
    );
  }
}

export default App;
