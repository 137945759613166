import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  ChakraProvider,
  Flex,
  Icon,
  Link,
  Text,
  theme,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import ResourceStructure from './ResourceStructure';
import TaughtNApprove from './TaughtNApproved';
import RatingModel from './Ratings/RatingModel';
import { useHistory } from 'react-router-dom';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import { IconContext } from 'react-icons/lib';
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';

function ResourceOverviewAccordions({
  resource,
  modules,
  resourceUpdate,
  setResourceUpdate,
}) {
  const {
    isOpen: isRatingOpen,
    onOpen: onRatingOpen,
    onClose: onRatingClose,
  } = useDisclosure();
  const history = useHistory();
  const userData = JSON.parse(localStorage.user);

  return (
    <ChakraProvider theme={theme}>
      <Accordion
        defaultIndex={[0]}
        allowMultiple
        px={{ md: '12' }}
        mx="2"
        pt="7"
        color="black"
      >
        <AccordionItem border="none">
          <AccordionButton
            p="0"
            _hover={{ bg: 'gray.50' }}
            _focus={{
              outline: 'none',
            }}
            bg="gray.50"
          >
            <Box flex="1" textAlign="left">
              {/* back button with text */}
              <Flex
                color="gray.900"
                cursor="pointer"
                onClick={() => history.push('/homepage')}
              >
                <Box>
                  <Icon as={AiOutlineArrowLeft} fontSize="16px" />
                </Box>
              </Flex>
              <Flex pt="3" pb="12">
                {/* title of the resource accordian */}
                <Text
                  pr="5"
                  color="black"
                  fontFamily="Inter"
                  fontWeight="bold"
                  fontSize={{
                    base: '20px',
                    sm: '24px',
                    md: '24px',
                    lg: '36px',
                  }}
                >
                  {resource.resourceName}
                </Text>
                <Box>
                  {/* badge on in my hive */}
                  <Badge
                    align="end"
                    textAlign="center"
                    textTransform="uppercase"
                    bg="purple.500"
                    color="#fff"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="12px"
                  >
                    in my hive
                  </Badge>
                </Box>
              </Flex>
            </Box>
            <AccordionIcon fontWeight="normal" fontSize="30px" />
          </AccordionButton>
          <AccordionPanel p="0">
            <Box color="black">
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
                align="center"
              >
                <Box>
                  <Flex align="center">
                    <Text
                      color="gray.600"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize={{
                        base: '12px',
                        sm: '14px',
                        md: '14px',
                        lg: '16px',
                      }}
                      pb="4"
                      pr="4"
                    >
                      Summary last updated
                      <Text
                        as="span"
                        color="gray.900"
                        fontWeight="700"
                        pl="2"
                        fontSize={{
                          base: '12px',
                          sm: '14px',
                          md: '14px',
                          lg: '16px',
                        }}
                      >
                        {resource.summaryUpdated}
                      </Text>
                    </Text>
                    {/* textbook section */}
                    <Flex cursor="pointer" mt={['-4', '0', '0', '-3']}>
                      <IconContext.Provider
                        value={{
                          style: {
                            color: '#805AD5',
                            border: '1.5px',
                            cursor: 'pointer',
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      >
                        <BsArrowUpRightSquare />
                      </IconContext.Provider>
                      <Link href={resource.sourceLink} isExternal>
                        <Text
                          color="purple.500"
                          fontWeight={'bold'}
                          fontSize="12px"
                          ml={3}
                        >
                          view textbook
                        </Text>
                      </Link>
                    </Flex>
                  </Flex>
                  {/* description section */}
                  <Box w={['full']}>
                    <Text
                      pb="5"
                      color="gray.900"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="400"
                      fontSize={['14px', '14px', '14px', '16px']}
                    >
                      {resource.description}
                    </Text>
                  </Box>
                  {/* tags section */}
                  <Flex flexWrap="wrap">
                    {resource.tags.map((tag, index) => (
                      <Box mb="2" key={index}>
                        <Badge
                          variant={'subtle'}
                          borderRadius="20px"
                          bgColor={'gray.200'}
                          mr={3}
                        >
                          <Text
                            fontFamily={'body'}
                            fontSize="14px"
                            color={'gray.600'}
                            p="2"
                          >
                            {tag.tag_name}
                          </Text>
                        </Badge>
                      </Box>
                    ))}
                  </Flex>
                </Box>
                {/* right section */}
                <Box mb={{ base: '25px' }} mr="2px" textAlign="left">
                  {/* textbook section */}
                  {/* <Flex
                    cursor="pointer"
                    display={['none', 'flex', 'flex', 'none']}
                    ml={['0', '9', '9', '0']}
                    mt={['0', '8', '8', '0']}
                  >
                    <IconContext.Provider
                      value={{
                        style: {
                          color: '#805AD5',
                          border: '1.5px',
                          cursor: 'pointer',
                          width: '16px',
                          height: '16px',
                        },
                      }}
                    >
                      <BsArrowUpRightSquare />
                    </IconContext.Provider>
                    <Text
                      color="purple.500"
                      fontWeight={'bold'}
                      fontSize="12px"
                      ml={3}
                    >
                      view textbook
                    </Text>
                  </Flex> */}
                  <Box mt={['0', '12', '12', '0']} my={['3', '0']}>
                    {/* rating container */}
                    <Flex
                      flexDirection={{ base: 'row', md: 'column' }}
                      justify={{ base: 'space-between' }}
                      align={['center', 'start']}
                      w={['90vw', 'full']}
                      mt={['0', '8', '8', '0']}
                    >
                      <Tooltip
                        hasArrow
                        label="Summary rated by students who already viewed this course"
                        bg="gray.800"
                        placement="top"
                      >
                        <Box>
                          <Text
                            pb="2"
                            cursor="pointer"
                            color="gray.600"
                            fontFamily="Inter"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="16px"
                          >
                            Summary rating
                          </Text>

                          {/* rating section */}
                          <Flex>
                            <Text
                              pr="10px"
                              fontFamily="Inter"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="24px"
                              color={'black'}
                            >
                              {Number(resource.totalRatings).toFixed(1)}
                            </Text>
                            <Flex align="center">
                              {Array(Math.trunc(Number(resource.totalRatings))) // Variable ratings
                                .fill('')
                                .map((_, i) => (
                                  <Icon
                                    as={AiFillStar}
                                    color="yellow.400"
                                    border="1.5px"
                                    borderColor={'yellow.400'}
                                    fontSize="20px"
                                    mr="1"
                                    key={i}
                                  />
                                ))}
                              {Array(
                                5 - Math.trunc(Number(resource.totalRatings))
                              )
                                .fill('')
                                .map((_, i) => (
                                  <Icon
                                    as={AiFillStar}
                                    color="gray.400"
                                    border="1.5px"
                                    fontSize="20px"
                                    mr="1"
                                    key={i}
                                  />
                                ))}
                            </Flex>
                          </Flex>
                        </Box>
                      </Tooltip>
                      <Text
                        onClick={onRatingOpen}
                        cursor="pointer"
                        textDecoration="underline"
                        color="purple.600"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        mt={{ base: '40px' }}
                      >
                        {resource.ratingList.find(
                          rating => rating.user_id === userData._id
                        )
                          ? 'Edit Your Rating'
                          : 'Click to rate'}
                      </Text>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
              <Flex
                mt={4}
                justify="space-between"
                align={['start', 'start', 'start', 'center']}
                flexDir={['column', 'column', 'column', 'row']}
                w={'full'}
              >
                <TaughtNApprove
                  institution={resource.institution}
                  approvedLecturers={resource.approvedLecturers}
                />
                {/* description section */}
                <Box
                  display="flex"
                  w={['full', 'full', 'full', '400px']}
                  borderRadius="2xl"
                  bgColor="gray.200"
                  p={5}
                  mb={3}
                >
                  <AiOutlineQuestionCircle
                    style={{
                      color: '#4A5568',
                      border: '1.5px',
                      width: '24px',
                      height: '24px',
                      marginRight: '5px',
                    }}
                  />
                  <Text
                    color="gray.600"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="16px"
                  >
                    Summaries are generated by AI. Our humans have checked{' '}
                    {resource.humanReviewed} of this book’s summaries. Please
                    contact us if you find any inappropriate or offensive
                    content.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {/* resource and modules list */}
      <Box>
        <ResourceStructure
          resourceId={resource.resourceId}
          resourceName={resource.resourceName}
          modules={modules}
        />
      </Box>
      {onRatingOpen && (
        <RatingModel
          resourceUpdate={resourceUpdate}
          setResourceUpdate={setResourceUpdate}
          isOpen={isRatingOpen}
          onClose={onRatingClose}
        />
      )}
    </ChakraProvider>
  );
}

export default ResourceOverviewAccordions;
