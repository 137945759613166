import {
  Badge,
  Box,
  Button,
  CloseButton,
  Flex,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik, ErrorMessage, Form } from 'formik';
import Dragdrop from './DragDrop';
import InputTag from './InputTag';
import Axios from 'axios';
import gtag from 'ga-gtag';

function UploadToHive({
  isOpen,
  onClose,
  onCreateKnowledgeNuggetOpen,
  updatehivedata,
  setUpdatehivedata,
  nonsummaryhiveData,
  resourceData,
  storageUsed,
}) {
  const cancelRef = useRef();
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const ex_tags = ['Design', 'Strategy', 'Human Centered Design'];
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [suggestionTag, setSuggestionTag] = useState();
  const [update, setUpdate] = useState(false);
  const [progress, setProgress] = useState(0);
  const userTags = [];

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  nonsummaryhiveData &&
    nonsummaryhiveData.map(item => {
      item.tags.map(tag => {
        userTags.push(tag);
        return null;
      });
      return null;
    });

  resourceData &&
    resourceData.map(item => {
      item.resource.tags.map(tag => {
        userTags.push(tag.tag_name);
        return null;
      });
      return null;
    });

  const shuffledUserTags = userTags.slice(0, 3);
  const TagData =
    resourceData || nonsummaryhiveData ? shuffledUserTags : ex_tags;

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  const initialValues = {
    files: null,
    tags: [],
  };
  const validationSchema = yup.object({
    files: yup.mixed().required('File is Required'),
    tags: yup.mixed().required('tags required'),
  });
  const onSubmit = async (values, actions) => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
    let userLocalData = JSON.parse(localStorage.user);

    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 50;
        setProgress(progress);
      },
    };
    // form formdata to send to backend
    const formData = new FormData();
    formData.append('files', values.files[0]);
    formData.append('tags', values.tags);
    await Axios.post(
      `${REACT_APP_EH_BACKEND}/addtohive/${userLocalData._id}`,
      formData,
      headers
    )
      .then(res => {
        if (res.data.status) {
          alertHandler({
            status: res.data.status,
            message: res.data.message,
          });

          if (res.data.status === 'success') {
            gtag('event', 'upload_to_hive', {
              file_name: `${values.files[0].name}`,
              file_size: `${values.files[0].size} bytes`,
              file_type: `${values.files[0].type}`,
              file_path: `${values.files[0].path}`,
              tags: `${values.tags}`,
              userid: `${userLocalData._id}`,
              full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
              verified: `${userLocalData.isVerified}`,
            });
          } else {
            gtag('event', 'exception', {
              process: `uploading a file to hive of name: ${values.files[0].name} by ${userLocalData.firstName} ${userLocalData.lastName}`,
              description: `error occured while uploading the file "${values.files[0].name}" by "${userLocalData.firstName} ${userLocalData.lastName}" user id: ${userLocalData._id}, to their hive`,
              fatal: false,
            });
          }
          actions.setSubmitting(false);
          setUpdate(!update); //cleanup function trigger in inputtag component
          actions.resetForm();
          onClose();
          setUpdatehivedata(!updatehivedata);
        } else {
          gtag('event', 'exception', {
            process: `uploading a file to hive of name: ${values.files[0].name} by ${userLocalData.firstName} ${userLocalData.lastName}`,
            description: `error occured while uploading the file "${values.files[0].name}" by "${userLocalData.firstName} ${userLocalData.lastName}" user id: ${userLocalData._id}, to their hive`,
            fatal: false,
          });
          actions.setSubmitting(false);
          setUpdate(!update);
          actions.resetForm();
          onClose();
        }
      })
      .catch(err => {
        gtag('event', 'exception', {
          process: `uploading a file to hive of name: ${values.files[0].name} by ${userLocalData.firstName} ${userLocalData.lastName}`,
          description: err,
          fatal: false,
        });
        return err;
      });
  };

  const handlethis = e => {
    e.preventDefault();
  };

  const handleClose = () => {
    onClose();
    onCreateKnowledgeNuggetOpen();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      {/* update code on model here */}
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={'bold'} color="gray.900">
            Upload to Hive
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={3}>
            <Box>
              <Text fontWeight={'normal'} color="gray.700">
                This learning content will not be summarised. To summarize your
                content, use{' '}
                <Link onClick={handleClose} color={'purple.400'}>
                  Create Knowledge Nugget
                </Link>{' '}
                option instead.
              </Text>
            </Box>
            <Box>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {formik => (
                  <Form
                    onSubmit={handlethis}
                    autoComplete="off"
                    encType="multipart/form-data"
                  >
                    <FormLabel htmlFor="file">
                      <Text
                        fontSize="sm"
                        fontWeight="normal"
                        color="gray.900"
                        fontFamily={'body'}
                      >
                        Upload files
                      </Text>
                    </FormLabel>
                    {/* drag droop sec dynamicly renders while subbmitting and initially shown*/}
                    {formik.isSubmitting ? (
                      <>
                        <Grid
                          templateColumns="repeat(3, 1fr)"
                          gap={2}
                          overflow="hidden"
                        >
                          {formik.values.files &&
                            formik.values.files.map((file, index) => (
                              <GridItem key={index}>
                                <Badge
                                  variant="solid"
                                  borderRadius="xl"
                                  bgColor={'gray.500'}
                                  px={2}
                                  h="8"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  my={2}
                                >
                                  <Text fontFamily={'body'}>{file.name}</Text>
                                  <CloseButton colorScheme={'blackAlpha'} />
                                </Badge>
                              </GridItem>
                            ))}
                        </Grid>
                        <Progress colorScheme={'yellow'} value={progress} />
                      </>
                    ) : (
                      // drag drop sec initial view
                      <>
                        <Dragdrop setFieldValue={formik.setFieldValue} />

                        <Grid
                          templateColumns="repeat(3, 1fr)"
                          gap={2}
                          overflow="hidden"
                        >
                          {formik.values.files &&
                            formik.values.files.map((file, index) => (
                              <GridItem key={index}>
                                <Badge
                                  variant="solid"
                                  borderRadius="xl"
                                  bgColor={'gray.500'}
                                  px={2}
                                  h="8"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  my={2}
                                >
                                  <Text
                                    fontFamily={'body'}
                                    fontSize="14px"
                                    fontWeight={'normal'}
                                    isTruncated
                                    w={'200px'}
                                  >
                                    {file.name}
                                  </Text>
                                  <CloseButton
                                    onClick={() =>
                                      formik.setFieldValue('files', null)
                                    }
                                    colorScheme={'blackAlpha'}
                                  />
                                </Badge>
                              </GridItem>
                            ))}
                        </Grid>

                        {nonsummaryhiveData &&
                          formik.values.files &&
                          nonsummaryhiveData.find(
                            element =>
                              element.filename === formik.values.files[0].name
                          ) && (
                            <Text
                              fontFamily={'body'}
                              fontSize="14px"
                              fontWeight="normal"
                              color="red"
                            >
                              File Already on Hive, Please Upload a Different
                              File
                            </Text>
                          )}

                        {formik.values.files &&
                          (formik.values.files[0].size / 1000000 > 2048 ||
                            2048 - storageUsed / 1000000 <
                              formik.values.files[0].size / 1000000) && (
                            <Text
                              fontFamily={'body'}
                              fontSize="14px"
                              fontWeight="normal"
                              color="red"
                            >
                              File size should be less than 2GB
                            </Text>
                          )}

                        {formik.values.files &&
                          (formik.values.files[0].type === 'video/mp4' ||
                            formik.values.files[0].type === 'audio/mpeg' ||
                            formik.values.files[0].type === '') && (
                            <Box>
                              <Text
                                fontFamily={'body'}
                                color="red"
                                fontSize="14px"
                                fontWeight={'normal'}
                              >
                                This file Type is not supported. Please upload a
                                different file.
                              </Text>
                            </Box>
                          )}
                        {formik.errors.files && formik.touched.files && (
                          <Text fontFamily={'body'} color="red">
                            {formik.errors.files}
                          </Text>
                        )}
                      </>
                    )}

                    <FormErrorMessage>
                      <ErrorMessage name="file" />
                    </FormErrorMessage>

                    <FormLabel htmlFor="tags" mt={4}>
                      <Text
                        fontSize="sm"
                        fontWeight="normal"
                        color="gray.900"
                        fontFamily={'body'}
                      >
                        Tags
                      </Text>
                    </FormLabel>

                    <InputTag
                      setFieldValue={formik.setFieldValue}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur.call}
                      values={formik.values.tags}
                      errors={formik.errors.tags}
                      suggestionTag={suggestionTag}
                      setSuggestionTag={setSuggestionTag}
                      update={update}
                    />

                    <FormErrorMessage>
                      <ErrorMessage name="tags" />
                    </FormErrorMessage>

                    <Box
                      aria-invalid="true"
                      display={'flex'}
                      flexDir="row"
                      gap={2}
                      my={2}
                    >
                      <Flex>
                        <Text fontFamily={'body'} pr="2">
                          Suggested
                        </Text>
                        <Box w="320px">
                          <Grid
                            pb="2"
                            templateColumns="repeat(3, 1fr)"
                            gap={2}
                            overflow="hidden"
                            display="flex"
                            overflowX="auto"
                          >
                            {TagData.map(
                              (tag, index) => (
                                <GridItem key={index}>
                                  <Box onClick={() => setSuggestionTag(tag)}>
                                    <Badge
                                      variant={'subtle'}
                                      borderRadius="20px"
                                      bgColor={'gray.200'}
                                      _hover={{
                                        cursor: 'pointer',
                                        bgColor: 'gray.300',
                                      }}
                                    >
                                      <Text
                                        fontFamily={'body'}
                                        fontSize="14px"
                                        color={'gray.600'}
                                      >
                                        {tag}
                                      </Text>
                                    </Badge>
                                  </Box>
                                </GridItem>
                              ),
                              this
                            )}
                          </Grid>
                        </Box>
                      </Flex>
                    </Box>

                    <Box display={'flex'} justifyContent="center" my={3}>
                      <Button
                        type="button"
                        ref={cancelRef}
                        colorScheme="yellow"
                        isLoading={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                        disabled={
                          (formik.values.files === null ||
                          formik.isSubmitting ||
                          formik.values.files[0].size / 1000000 > 1024 ||
                          1024 - storageUsed / 1000000 <
                            formik.values.files[0].size / 1000000 )||
                          (nonsummaryhiveData &&
                            nonsummaryhiveData.find(
                              element =>
                                element.filename === formik.values.files[0].name
                            ))
                            ? true
                            : false
                        }
                      >
                        <Text
                          fontWeight="bold"
                          fontSize="18px"
                          color="gray.900"
                          fontFamily={'body'}
                        >
                          Upload
                        </Text>
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UploadToHive;
