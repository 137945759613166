import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Icon,
  Button,
  ChakraProvider,
  Flex,
  Spacer,
  Text,
  theme,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';

function DeclineNRemove({
  isOpen,
  onClose,
  type,
  sharedData,
  name,
  userList,
  update,
  setUpdate,
}) {
  let userLocalData = JSON.parse(localStorage.user);
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  const handleDeclined = async declinedData => {
    await axios
      .put(
        `${process.env.REACT_APP_EH_BACKEND}/shared-project-decline/${declinedData.shared_resource_id}/${declinedData.shared_resource_type}/${userLocalData._id}`,
        null,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          alertHandler({
            status: res.data.status,
            message: res.data.message,
          });
        }
        if (res.status === 200) {
          setUpdate(!update);
          onClose();
        }
      })
      .catch(err => {
        return err;
      });
  };

  const handleRemove = async removeData => {
    await axios
      .delete(
        `${process.env.REACT_APP_EH_BACKEND}/shared-project-remove/${removeData.shared_resource_id}/${name.user_id}`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        }
      )
      .then(res => {
        if (res.status === 200) {
          setUpdate(!update);
          onClose();
        }
      })
      .catch(err => {
        return err;
      });
  };

  return (
    <ChakraProvider theme={theme}>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered w="448px">
        <AlertDialogOverlay bg="rgba(0,0,0,0.48)">
          <AlertDialogContent rounded="16px" shadow="md">
            <AlertDialogHeader fontWeight="bold" pb="24px">
              <Flex>
                <Text
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                  overflow={'hidden'}
                >
                  {type === 'Decline'
                    ? 'Project invitations'
                    : 'Remove person from project'}
                </Text>
                <Spacer />
                <Box>
                  <Icon
                  as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
              <>
                {type === 'Decline' ? (
                  <Text
                    pt="14px"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    Are you sure you want to decline this invitation?
                  </Text>
                ) : (
                  <Text
                    pt="14px"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    Are you sure you want to remove
                    <Text as="span" fontWeight="700 !important">
                      {' '}
                      {userList
                        ? userList.find(
                            reguser => reguser.email === name.user_email
                          )?.firstName
                        : ''}{' '}
                      {userList
                        ? userList.find(
                            reguser => reguser.email === name.user_email
                          )?.lastName
                        : ''}
                    </Text>{' '}
                    from the project?
                  </Text>
                )}
              </>
            </AlertDialogHeader>

            <AlertDialogFooter>
              <Button
                onClick={onClose}
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                _focus={{ outline: 'none' }}
              >
                {type === 'Decline' ? 'Back' : 'Cancel'}
              </Button>
              <Button
                ml={3}
                colorScheme="red"
                color="white"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                onClick={
                  type === 'Decline'
                    ? () => handleDeclined(sharedData)
                    : () => handleRemove(sharedData)
                }
              >
                {type === 'Decline' ? 'Decline' : 'Remove'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </ChakraProvider>
  );
}

export default DeclineNRemove;
