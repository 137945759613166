import {
  Avatar,
  Box,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  Text,
  theme,
} from '@chakra-ui/react';
import React from 'react';

function TaughtNApprove({ institution, approvedLecturers }) {
  return (
    <ChakraProvider theme={theme}>
      <Flex flexDirection={{ base: 'column', md: 'row' }} mr="12">
        <Box mb={{ base: '25px' }} mr={{ md: '120px' }}>
          <Text
            pb="4"
            color="gray.600"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
          >
            Reviewed/Approved by
          </Text>

          <Flex flexDirection={{ base: 'column', md: 'row' }}>
            <Grid
              gridTemplateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
              ]}
              gap={5}
            >
              {approvedLecturers.map((lecturer, index) => (
                <GridItem key={index}>
                  <Flex
                    mb={{ base: '20px' }}
                    mr={{ md: '40px' }}
                    align="center"
                  >
                    <Avatar
                      src={lecturer.lecturer_src}
                      alt="Lecturer 1 of summary acceptance"
                      w="40px"
                      h="40px"
                    />
                    <Text
                      pl="4"
                      color="gray.900"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                    >
                      {lecturer.lecturer_name}
                    </Text>
                  </Flex>
                </GridItem>
              ))}
            </Grid>

            {/* For Pilot */}
            {/* <Flex align="center" mt={[0, -5, -5, -5]}>
              <Avatar
                // src={Lecturer2}
                alt="Lecturer 2 of summary acceptance"
                w="40px"
                h="40px"
              />
              <Text
                pl="4"
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                {approvedLecturer2}
              </Text>
            </Flex> */}
          </Flex>
        </Box>
        {/* For Pilot */}
        <Box mb={{ base: '20px' }}>
          <Text
            pb="4"
            color="gray.600"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
          >
            For students of
          </Text>
          <Flex align="center">
            <Avatar alt="Institution" w="40px" h="40px" />
            <Text
              pl="4"
              color="gray.900"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="700"
              fontSize="16px"
            >
              {institution}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </ChakraProvider>
  );
}

export default TaughtNApprove;
