import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import dp from '../../../../../assests/Lecturer 1.png';
import DeclineNRemove from './DeclineNRemove';
import axios from 'axios';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';

function ProjectSharing({ isOpen, onClose, ResourceId, ResourceName }) {
  const {
    isOpen: isDeclineNRemoveOpen,
    onOpen: onDeclineNRemoveOpen,
    onClose: onDeclineNRemoveClose,
  } = useDisclosure();
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [invitedUserpostResponse, setInvitedUserpostResponse] = useState();
  const [update, setUpdate] = useState(false);
  const [newalert, setNewAlert] = useState(false);
  const [newalert2, setNewAlert2] = useState(false);
  const [userList, setUserList] = useState();
  const [haveUsers, setHaveUsers] = useState(false);
  const [sharedData, setSharedData] = useState('');
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [name, setName] = useState('');
  const { REACT_APP_EH_BACKEND } = process.env;
  let userLocalData = JSON.parse(localStorage.user);

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  useEffect(() => {
    axios
      .get(
        `${REACT_APP_EH_BACKEND}/shared-project/${userLocalData._id}/${ResourceId}/project`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        }
      )
      .then(res => {
        setInvitedUserpostResponse(res.data);
      });
  }, [REACT_APP_EH_BACKEND, ResourceId, userLocalData._id, update]);

  useEffect(() => {
    axios
      .get(`${REACT_APP_EH_BACKEND}/auth/listusers`)
      .then(res => {
        setUserList(res.data);
        setHaveUsers(true);
      })
      .catch(err => {
        return err;
      });
  }, [REACT_APP_EH_BACKEND]);

  const handleSubmit = async invitedData => {
    if (userList?.find(user => user.email === invitedData)) {
      let sharedUserArray = [];
      const userData = userList.find(user => user.email === invitedData);
      sharedUserArray.push({
        user_id: userData._id,
        user_email: invitedData,
        created_at: new Date(),
      });
      await axios
        .post(
          `${REACT_APP_EH_BACKEND}/shared-project/${userLocalData._id}/${ResourceId}/${ResourceName}/project`,
          { shared_users: sharedUserArray },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.login_token,
            },
          }
        )
        .then(res => {
          if (res.data.status) {
            alertHandler({
              status: res.data.status,
              message: res.data.message,
            });
          }

          setNewAlert(false);
          setUpdate(!update);
        })
        .catch(err => {
          return err;
        });
    } else {
      setNewAlert(true);
    }
  };

  const updateInvitedUser = async invitedData => {
    if (userList?.find(user => user.email === invitedData)) {
      // this is a registered user
      if (
        invitedUserpostResponse.shared_users.find(
          user => user.user_email === invitedData
        )
      ) {
        setNewAlert2(true);
      } else {
        const userData = userList.find(user => user.email === invitedData);
        let sharedUserArray = {
          user_id: userData._id,
          user_email: invitedData,
          created_at: new Date(),
        };

        await axios
          .put(
            `${REACT_APP_EH_BACKEND}/shared-project/${userLocalData._id}/${ResourceId}/${ResourceName}/project`,
            { shared_users: sharedUserArray },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.login_token,
              },
            }
          )
          .then(res => {
            if (res.data.status) {
              alertHandler({
                status: res.data.status,
                message: res.data.message,
              });
            }

            setNewAlert(false);
            setNewAlert2(false);
            setUpdate(!update);
          })
          .catch(err => {
            return err;
          });
      }
    } else {
      setNewAlert(true);
    }
  };

  const handleDelete = (DeleteData, user) => {
    setSharedData(DeleteData);
    setName(user);
    onDeclineNRemoveOpen();
  };
  const initialValues = {
    invitedUser: '',
  };

  const validationSchema = yup.object({
    invitedUser: yup
      .string()
      .email('Please enter a valid email')
      .required('email is required'),
  });

  const onSubmit = (values, actions) => {
    invitedUserpostResponse || invitedUserpostResponse?.shared_users?.length > 0
      ? updateInvitedUser(values.invitedUser)
      : handleSubmit(values.invitedUser);

    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <>
      <Modal isOpen={isOpen} size="xl" onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0,0,0,0.48)" />
        <ModalContent rounded="16px" shadow="md" pb="7">
          <ModalHeader fontWeight="bold" pb="24px" pt="21px">
            <Flex>
              <Text
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="20px"
                overflow={'hidden'}
              >
                Project sharing permissions
              </Text>
              <Spacer />
              <Box>
                <Icon
                as={AiOutlineClose}
                  fontSize="14px"
                  _hover={{ cursor: 'pointer' }}
                  onClick={onClose}
                />
              </Box>
            </Flex>
            <Text
              pt="29px"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
            >
              Share your project with other Elephant Hive users.
            </Text>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {formik => (
                <Form autoComplete="off">
                  <Box pb="4">
                    <Text
                      pb="2"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                    >
                      Inivte users
                    </Text>
                    {haveUsers ? (
                      <Flex>
                        <FormControl
                          isInvalid={
                            newalert ||
                            newalert2 ||
                            (formik.errors.invitedUser &&
                              formik.touched.invitedUser)
                          }
                        >
                          <InputGroup>
                            <InputRightElement
                              cursor="pointer"
                              children={
                                <Icon
                                as={AiOutlineSearch}
                                  color="gray.500"
                                  fontSize="20px"
                                  fontWeight="normal"
                                />
                              }
                            />
                            <Input
                              id="invitedUser"
                              type={'text'}
                              px="12px"
                              py="9.5px"
                              rounded="4px"
                              colorScheme={'yellow'}
                              placeholder="Add email address"
                              value={formik.values.invitedUser}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              _placeholder={{
                                color: 'gray.400',
                                fontWeight: '400',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                              }}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            <ErrorMessage name="invitedUser" />
                            {newalert &&
                              formik.values.invitedUser.length === 0 &&
                              `${' '}This User is not signed Up to Elephant Hive`}
                            {newalert2 &&
                              formik.values.invitedUser.length === 0 &&
                              `${' '}This Project is already shared with this User`}
                          </FormErrorMessage>
                        </FormControl>
                        <Button
                          type="submit"
                          isLoading={formik.isSubmitting}
                          ml="2"
                          py="14px"
                          px="10px"
                          bg="yellow.400"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontWeight="600"
                          fontSize="12px"
                          rounded="6px"
                          _hover={{ bg: 'yellow.500' }}
                          _active={{
                            bg: 'yellow.500',
                            transform: 'scale(0.98)',
                          }}
                          _focus={{
                            outline: 'none',
                          }}
                        >
                          Invite
                        </Button>
                      </Flex>
                    ) : (
                      <Spinner />
                    )}
                  </Box>
                </Form>
              )}
            </Formik>

            <Box>
              <Text
                pb="2"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="14px"
              >
                Shared with
              </Text>
              <Box overflowY="auto" h="138px">
                <Flex align="center" pb="2">
                  <Flex align="center">
                    <Avatar alt={dp} w="40px" h="40px" />
                    <Text
                      color="gray.500"
                      px="3"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="500"
                      fontSize="14px"
                    >
                      {`${userLocalData.firstName} ${userLocalData.lastName}`}
                    </Text>
                  </Flex>
                  <Spacer />
                  <Text
                    pr="2"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="12px"
                  >
                    OWNER
                  </Text>
                </Flex>
                {/* this section need to be an map of elements */}
                {haveUsers ? (
                  invitedUserpostResponse &&
                  invitedUserpostResponse.shared_users.map((user, index) => (
                    <Flex key={index} align="center" pb="2">
                      <Flex align="center">
                        <Avatar alt={dp} w="40px" h="40px" />
                        <Text
                          color="gray.500"
                          px="3"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontWeight="500"
                          fontSize="14px"
                        >
                          {
                            userList?.find(
                              userReg => userReg.email === user.user_email
                            ).firstName
                          }{' '}
                          {
                            userList?.find(
                              userReg => userReg.email === user.user_email
                            ).lastName
                          }
                        </Text>
                      </Flex>
                      <Spacer />
                      <Box pr="5">
                        <Icon
                        as={AiOutlineClose}
                          color="#718096"
                          fontSize="14px"
                          _hover={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleDelete(invitedUserpostResponse, user)
                          }
                        />
                      </Box>
                    </Flex>
                  ))
                ) : (
                  <Spinner />
                )}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {haveUsers && onDeclineNRemoveOpen && (
        <DeclineNRemove
          isOpen={isDeclineNRemoveOpen}
          onClose={onDeclineNRemoveClose}
          sharedData={sharedData}
          name={name}
          userList={userList}
          update={update}
          setUpdate={setUpdate}
          type={'Remove'}
        />
      )}
    </>
  );
}

export default ProjectSharing;
