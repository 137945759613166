import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  Icon,
  Image,
  Spacer,
  Text,
  theme,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IconRes from '../../../../../assests/Icons/Resource/BookBookmark.png';
import IconKn from '../../../../../assests/Icons/KnowledgeNugget/Hexagon.png';
import IconNonSum from '../../../../../assests/Icons/nonsummary/Upload.png';
import IconProj from '../../../../../assests/Icons/Project/Folder.png';

import DeclineNRemove from './DeclineNRemove';
import { AiOutlineClose } from 'react-icons/ai';

function ProjectInvitation({
  isOpen,
  onClose,
  sharedRes,
  userList,
  update,
  setUpdate,
  updatehivedata,
  setUpdatehivedata,
}) {
  let userLocalData = JSON.parse(localStorage.user);
  const [sharedData, setSharedData] = useState('');
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');

  const {
    isOpen: isDeclineNRemoveOpen,
    onOpen: onDeclineNRemoveOpen,
    onClose: onDeclineNRemoveClose,
  } = useDisclosure();

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  const handleAccept = async AcceptData => {
    await axios
      .put(
        `${process.env.REACT_APP_EH_BACKEND}/shared-project-accept/${AcceptData.shared_resource_id}/${AcceptData.shared_resource_type}/${userLocalData._id}`,
        null,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          alertHandler({
            status: res.data.status,
            message: res.data.message,
          });
        }
        setUpdate(!update);
        setUpdatehivedata(!updatehivedata);
        onClose();
        // trigger a boolean
        // to update status on header
        // and call onclose
      })
      .catch(err => {
        return err;
      });
  };

  const handleDeclined = declinedData => {
    setSharedData(declinedData);
    onDeclineNRemoveOpen();

    sharedRes.length <= 1 && onClose();
  };

  return (
    <ChakraProvider theme={theme}>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered w="448px">
        <AlertDialogOverlay bg="rgba(0,0,0,0.48)">
          <AlertDialogContent rounded="16px" shadow="md">
            <AlertDialogHeader fontWeight="bold" pb="24px">
              <Flex>
                <Text
                  color="gray.900"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="700"
                  fontSize="20px"
                  overflow={'hidden'}
                >
                  Project invitations
                </Text>
                <Spacer />
                <Box>
                  <Icon
                  as={AiOutlineClose}
                    fontSize="14px"
                    _hover={{ cursor: 'pointer' }}
                    onClick={onClose}
                  />
                </Box>
              </Flex>
              <Text
                pt="14px"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
              >
                Click Accept to join.
              </Text>
            </AlertDialogHeader>
            <Flex flexDirection={'column'}>
              {sharedRes.map((res, id) => (
                <Flex key={id} flexDirection={'column'}>
                  <AlertDialogBody
                    color="gray.700"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    <Box>
                      <Flex align="center" pb="10px">
                        <Avatar
                          alt="Lecturer 1 of summary acceptance"
                          w="40px"
                          h="40px"
                        />
                        <Text
                          px="2"
                          fontFamily="Inter"
                          fontStyle="normal"
                          fontWeight="400"
                          fontSize="14px"
                        >
                          <Text as="span" fontWeight="700 !important">
                            {
                              userList.find(user => user._id === res.user_id)
                                .firstName
                            }{' '}
                            {
                              userList.find(user => user._id === res.user_id)
                                .lastName
                            }
                          </Text>{' '}
                          have invited you to a {res.shared_resource_type}
                        </Text>
                      </Flex>
                      <Box
                        cursor="pointer"
                        overflow="hidden"
                        p="2"
                        bg="white"
                        shadow="base"
                        rounded="16px"
                        border="1px solid #fff"
                      >
                        <Flex align="center">
                          <Center
                            w="8"
                            h="8"
                            shadow="base"
                            rounded="12px"
                            bg="white"
                          >
                            <Image
                              src={
                                res.shared_resource_type === 'project'
                                  ? IconProj
                                  : res.shared_resource_type === 'Non Summery'
                                  ? IconNonSum
                                  : res.shared_resource_type === 'Resource'
                                  ? IconRes
                                  : IconKn
                              }
                              rounded
                            />
                          </Center>
                          <Text
                            px="2"
                            fontFamily="Inter"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="14px"
                            overflow="hidden"
                          >
                            {res.shared_resource_name}
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      colorScheme="red"
                      color="white"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                      onClick={() => handleDeclined(res)}
                    >
                      Decline
                    </Button>
                    <Button
                      ml={3}
                      onClick={() => handleAccept(res)}
                      color="gray.900"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                      _focus={{ outline: 'none' }}
                    >
                      Accept
                    </Button>
                  </AlertDialogFooter>
                </Flex>
              ))}
            </Flex>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {onDeclineNRemoveOpen && (
        <DeclineNRemove
          isOpen={isDeclineNRemoveOpen}
          onClose={onDeclineNRemoveClose}
          sharedData={sharedData}
          update={update}
          setUpdate={setUpdate}
          type={'Decline'}
        />
      )}
    </ChakraProvider>
  );
}

export default ProjectInvitation;
