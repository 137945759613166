import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { VscArrowLeft } from 'react-icons/vsc';
import Header2 from '../Components/Header/Header2';
import axios from 'axios';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BsPencil } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import Delete from '../Components/LessonSummary/HeaderComps/Delete';
import EditTag from '../Components/Homepage/Components/HiveUploadContainer/EditTag';
import Loading from '../Components/Loading/Loading';
import { HiDotsVertical } from 'react-icons/hi';
import { useIntercom } from 'react-use-intercom';
import fileDownload from 'js-file-download';
import PDFViewer from '../Components/NonsummeryPDF/PDFViewer';

function NonSummaryViewer(props) {
  let itemID = props.match.params.id;
  const [storageUsed, setStorageUsed] = useState(0);
  const [nonhiveData, setNonhiveData] = useState();
  const [typeoffile, setTypeoffile] = useState();
  const [isloading, setIsloading] = useState(true);
  const [update, setUpdate] = useState(false);
  const { boot, shutdown } = useIntercom();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isOptionDrawerOpen,
    onOpen: onOptionDrawerOpen,
    onClose: onOptionDrawerClose,
  } = useDisclosure();

  const { REACT_APP_EH_BACKEND } = process.env;
  let userLocalData = JSON.parse(localStorage.user);
  const headers = {
    headers: {
      Authorization: 'Bearer ' + localStorage.login_token,
    },
  };

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  // get non summery information based on id
  useEffect(() => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };

    setTimeout(() => {
      axios
        .get(
          `${REACT_APP_EH_BACKEND}/nonsummaryhivesingledata/${itemID}`,
          headers
        )
        .then(responseData => {
          setNonhiveData(responseData.data);
          setIsloading(false);
        })
        .catch(error => {
          return error;
        });
    }, 1000);
  }, [REACT_APP_EH_BACKEND, itemID, update]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id, update]);

  useEffect(() => {
    setTypeoffile(nonhiveData?.filename.split('.').slice(-1)[0]);
  }, [nonhiveData?.filename]);

  const handleback = () => {
    props.history.goBack();
  };
  const handleDelete = async () => {
    // delete function when confirm delete button is clicked
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivesingledata/${itemID}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deletenonsummerydataproj/${userLocalData._id}/${itemID}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                //deleted
              }
            })
            .catch(error => {
              return error;
            });
          props.history.push('/homepage');
        }
      })
      .catch(error => {
        return error;
      });
  };

  const handleDownload = async (url, filename) => {
    try {
      await axios
        .put(
          `${REACT_APP_EH_BACKEND}/nonsummery/download`,
          {
            url: url,
          },
          {
            responseType: 'blob',
            headers: {
              Authorization: 'Bearer ' + localStorage.login_token,
            },
          }
        )
        .then(response => {
          fileDownload(response.data, filename);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* loading screen while data oads */}
      {isloading ? (
        <Loading />
      ) : (
        <Box overflow={'hidden'} h="100vh">
          <Header2 storageUsed={storageUsed} />
          <Box overflowY={'auto'} h={'calc(100vh - 72px)'}>
            <Flex
              align="center"
              justify="space-between"
              flexDir={'row'}
              border="1px"
              color="gray.50"
              borderColor="gray.200"
              // h={['72px', '58px']}
              overflow="hidden"
            >
              {/* left section */}
              <Box
                display={'flex'}
                className="l-container"
                alignItems={['start', 'start', 'center']}
                justifyContent="space-evenly"
                flexDirection={['column', 'column', 'row']}
                my={2}
              >
                <Box display="flex">
                  <Box ml={[0, 4, 10]} mr={[0, 0, 5]}>
                    <VscArrowLeft
                      size={24}
                      onClick={() => handleback()}
                      style={{ color: '#718096', cursor: 'pointer' }}
                    />
                  </Box>
                  <Text fontWeight={'bold'} color="gray.900">
                    {nonhiveData.filename}
                  </Text>
                </Box>
                <Box my={1}>
                  <Grid
                    overflowY={'auto'}
                    overflowX="hidden"
                    templateColumns={[
                      'repeat(1, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(5, 1fr)',
                    ]}
                  >
                    {nonhiveData?.tags.map((tag, index) => (
                      <GridItem key={index}>
                        <Badge
                          variant="solid"
                          borderRadius="xl"
                          bgColor={'gray.200'}
                          p={2}
                          h={6}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          ml={2}
                          my={1}
                        >
                          <Text fontFamily={'body'} color="gray.600">
                            {tag}
                          </Text>
                        </Badge>
                      </GridItem>
                    ))}
                  </Grid>
                </Box>
              </Box>
              {/* right section */}
              <Box
                display={'flex'}
                mr="5"
                alignItems="center"
                className="r-container"
              >
                {/* divider */}
                <Box height={'50'} ml="5" color={'gray.200'}>
                  <Divider border="1px" orientation="vertical" />
                </Box>
                <Box display={['flex', 'flex', 'none']}>
                  <Button
                    variant={'outline'}
                    onClick={onOptionDrawerOpen}
                    ml="5"
                    borderRadius={'md'}
                  >
                    <HiDotsVertical
                      style={{ border: '1.5px', color: 'gray' }}
                    />
                  </Button>

                  <Drawer
                    isOpen={isOptionDrawerOpen}
                    onClose={onOptionDrawerClose}
                    placement="bottom"
                  >
                    <DrawerContent
                      marginBottom="0"
                      borderRadius="8px 8px 0px 0px"
                      boxShadow="0px -10px 15px -3px rgba(0, 0, 0, 0.1), 0px -4px 6px -2px rgba(0, 0, 0, 0.05)"
                    >
                      <DrawerBody>
                        <Flex
                          align="start"
                          flexDirection="column"
                          justify="center"
                        >
                          <Flex
                            dir="row"
                            align="center"
                            my={2}
                            onClick={onEditOpen}
                            _hover={{ bg: 'gray.400' }}
                            cursor="pointer"
                            w="full"
                            borderRadius="md"
                          >
                            <BsPencil size={12} style={{ color: '#718096' }} />
                            <Text ml={2} fontSize="16px" fontWeight={'normal'}>
                              Edit
                            </Text>
                          </Flex>
                          <Flex
                            dir="row"
                            align="center"
                            my={2}
                            onClick={onDeleteOpen}
                            _hover={{ bg: 'gray.400' }}
                            cursor="pointer"
                            w="full"
                            borderRadius="md"
                          >
                            <RiDeleteBinLine style={{ color: '#C53030' }} />
                            <Text ml={2} fontSize="16px" fontWeight={'normal'}>
                              Delete
                            </Text>
                          </Flex>
                        </Flex>
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </Box>
                <Box display={['none', 'none', 'flex']}>
                  <Button
                    onClick={onDeleteOpen}
                    variant={'outline'}
                    ml="5"
                    borderRadius={'md'}
                  >
                    <RiDeleteBinLine style={{ color: '#C53030' }} />
                  </Button>
                  {onDeleteOpen && (
                    <Delete
                      isOpen={isDeleteOpen}
                      onClose={onDeleteClose}
                      content={nonhiveData?.filename}
                      onDelete={handleDelete}
                    />
                  )}
                  <Button onClick={onEditOpen} variant={'outline'} ml="5">
                    <Flex align={'center'} justify="center" flexDir={'row'}>
                      <BsPencil size={12} style={{ color: '#718096' }} />
                      <Text
                        ml={2}
                        fontSize="16px"
                        color={'gray.900'}
                        fontWeight={'bold'}
                      >
                        Edit tags
                      </Text>
                    </Flex>
                  </Button>
                  {onEditOpen && (
                    <EditTag
                      isOpen={isEditOpen}
                      onClose={onEditClose}
                      itemID={itemID}
                      setUpdate={setUpdate}
                      update={update}
                      nonhiveData={nonhiveData}
                      isproject={false}
                      type={'nonsummary'}
                    />
                  )}
                </Box>
              </Box>
            </Flex>
            <Box borderRadius="lg" bgColor={'#EDF2F7'} mx={'24px'} my={'24px'}>
              {typeoffile !== 'pdf' &&
              typeoffile !== 'doc' &&
              typeoffile !== 'docx' &&
              typeoffile !== 'ppt' &&
              typeoffile !== 'txt' &&
              typeoffile !== 'pptx' &&
              typeoffile !== 'xls' &&
              typeoffile !== 'aac' &&
              typeoffile !== 'abw' &&
              typeoffile !== 'arc' &&
              typeoffile !== 'azw' &&
              typeoffile !== 'bz' &&
              typeoffile !== 'bz2' &&
              typeoffile !== 'csh' &&
              typeoffile !== 'css' &&
              typeoffile !== 'csv' &&
              typeoffile !== 'eot' &&
              typeoffile !== 'bin' &&
              typeoffile !== 'epub' &&
              typeoffile !== 'gz' &&
              typeoffile !== 'htm' &&
              typeoffile !== 'html' &&
              typeoffile !== 'ics' &&
              typeoffile !== 'jar' &&
              typeoffile !== 'js' &&
              typeoffile !== 'json' &&
              typeoffile !== 'jsonld' &&
              typeoffile !== 'mjs' &&
              typeoffile !== 'mp3' &&
              typeoffile !== 'odp' &&
              typeoffile !== 'ods' &&
              typeoffile !== 'odt' &&
              typeoffile !== 'oga' &&
              typeoffile !== 'ogx' &&
              typeoffile !== 'opus' &&
              typeoffile !== 'otf' &&
              typeoffile !== 'php' &&
              typeoffile !== 'rar' &&
              typeoffile !== 'rtf' &&
              typeoffile !== 'sh' &&
              typeoffile !== 'swf' &&
              typeoffile !== 'tar' &&
              typeoffile !== 'ttf' &&
              typeoffile !== 'vsd' &&
              typeoffile !== 'wav' &&
              typeoffile !== 'weba' &&
              typeoffile !== 'woff' &&
              typeoffile !== 'woff2' &&
              typeoffile !== 'xhtml' &&
              typeoffile !== 'xml' &&
              typeoffile !== 'xul' &&
              typeoffile !== 'zip' &&
              typeoffile !== '7z' &&
              typeoffile !== 'xlsx' ? (
                <Box
                  overflow="hidden"
                  display="flex"
                  alignItems={'center'}
                  justifyContent="center"
                >
                  <Image
                    src={nonhiveData.file_source[0]}
                    alt={nonhiveData.filename}
                    objectFit="cover"
                  />
                </Box>
              ) : typeoffile === 'pdf' ? (
                <Box
                  borderRadius="lg"
                  bgColor={'#EDF2F7'}
                  mx={'24px'}
                  my={'24px'}
                >
                  <Flex
                    align={'center'}
                    justify="center"
                    py={10}
                    flexDir={'column'}
                  >
                    <PDFViewer
                      url={nonhiveData.file_source[0]}
                      fileName={nonhiveData.filename}
                    />
                  </Flex>

                  <Center>
                    <Button
                      my={5}
                      borderColor={'#718096'}
                      variant="outline"
                      fontFamily={'body'}
                      fontWeight={'bold'}
                      onClick={() =>
                        handleDownload(
                          nonhiveData.file_source[0],
                          nonhiveData.filename
                        )
                      }
                    >
                      Download file
                    </Button>
                  </Center>
                </Box>
              ) : (
                <Box
                  borderRadius="lg"
                  bgColor={'#EDF2F7'}
                  h="80vh"
                  mx={'24px'}
                  my={'24px'}
                >
                  <Box
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    top={80}
                  >
                    <Box position="absolute">
                      <Box
                        borderRadius="full"
                        h={'172px'}
                        w={'172px'}
                        display="flex"
                        color="gray.400"
                        bgColor="gray.200"
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <IoDocumentTextOutline size={70} />
                      </Box>
                      <Center>
                        <Button
                          my={5}
                          variant="outline"
                          onClick={() =>
                            handleDownload(
                              nonhiveData.file_source[0],
                              nonhiveData.filename
                            )
                          }
                        >
                          <Text>Download file</Text>
                        </Button>
                      </Center>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default NonSummaryViewer;
