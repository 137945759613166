import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Text,
  Spacer,
  Box,
  Center,
  SimpleGrid,
  ModalFooter,
  useBreakpointValue,
  useToast,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ChooseProject from './ChooseProject';
import CPIcon from '../../../../assests/Icons/Project/Heart.png';
import gtag from 'ga-gtag';
import { AiOutlineClose } from 'react-icons/ai';

function AddToProject({
  isOpen,
  onClose,
  type,
  id,
  sharedProjectData,
  update,
  setUpdate,
}) {
  let userLocalData = JSON.parse(localStorage.user);
  const [upateprojectData, setUpdateprojectData] = useState(false);
  const [projectData, setProjectData] = useState();
  const [sharedData, setSharedData] = useState();
  const [updatedProjects, setUpdatedProjects] = useState();
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [chose, setChose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setselected] = useState([false]);
  let filteredProjects = [];
  const { REACT_APP_EH_BACKEND } = process.env;

  let filteredSharedProjects =
    sharedProjectData &&
    sharedProjectData?.map(project => project.shared_resource_id);
  const sharedprojquery =
    filteredSharedProjects &&
    filteredSharedProjects?.map(id => '_id=' + id).join('&');

  // get shared project data
  useEffect(() => {
    sharedprojquery &&
      axios
        .get(`${REACT_APP_EH_BACKEND}/shared-resources?${sharedprojquery}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        })
        .then(res => {
          setSharedData(res.data);
        })
        .catch(err => {
          return err;
        });
  }, [REACT_APP_EH_BACKEND, sharedprojquery]);

  // get projectData
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/getprojects/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(projectResponse => {
        setProjectData(projectResponse.data);
      })
      .catch(projectError => {
        return projectError;
      });
  }, [userLocalData._id]);


  // update boolean if user have project data
  useEffect(() => {
    if (projectData?.length > 0) {
      setUpdateprojectData(true);
    } else {
      setUpdateprojectData(false);
    }
  }, [projectData?.length]);

  sharedData &&
    projectData &&
    sharedData.map(proj => {
      if (projectData.find(project => project._id === proj._id)) {
        return null;
      } else {
        projectData.push(proj);
        return null;
      }
    });

  projectData?.map(project => {
    project['id'] = project['_id'];

    return null;
  });

  //update: filter project data so if the releventid is in projects
  // discard them and
  // only show the projects that don't have this elements
  useEffect(() => {
    let alldata;
    if (upateprojectData) {
      alldata =
        projectData &&
        // eslint-disable-next-line react-hooks/exhaustive-deps
        projectData.filter(project => {
          // based on the type it returns true or fales if it includes id to allProjects
          if (type === 'resource') {
            return !project.selected_resourse_ids.includes(id);
          } else if (type === 'nonsummary') {
            return !project.selected_nonsummary_ids.includes(id);
          } else {
            return !project.selected_knowledeNuggets_ids.includes(id);
          }
        });
    }

    setUpdatedProjects(alldata);
  }, [id, projectData, type, upateprojectData]);

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const selectProject = project => {
    for (let i in updatedProjects) {
      if (updatedProjects[i].id === project.id) {
        if (updatedProjects[i].selection === true) {
          updatedProjects[i].selection = false;
        } else {
          updatedProjects[i].selection = true;
        }
      }
    }

    setUpdatedProjects(updatedProjects);
    setselected(!selected);

    for (let i in updatedProjects) {
      if (updatedProjects[i].selection === true) {
        setChose(true);
        break;
      }
      setChose(false);
    }
  };

  const ClesrSelection = selectedProjects => {
    for (let i in selectedProjects) {
      selectedProjects[i].selection = false;
    }
    return selectedProjects;
  };

  const handleSubmit = async () => {
    setLoading(true);
    updatedProjects?.map(project => {
      if (project.selection) {
        filteredProjects.push(project.id);
      }

      return null;
    });
    const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
    const queryString = filteredProjects.map(key => '_id=' + key).join('&');

    let kindOf;
    switch (type) {
      case 'resource':
        kindOf = 'course';
        break;
      case 'nonsummary':
        kindOf = 'upload to hive data';
        break;
      default:
        kindOf = 'knowledge nugget';
        break;
    }

    queryString !== '' &&
      (await axios({
        method: 'PUT',
        url: `${REACT_APP_EH_BACKEND}/addprojects/${userLocalData._id}/${id}/${type}?${queryString}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      })
        .then(res => {
          if (res.data.status) {
            gtag('config', REACT_APP_GA_MEASUREMENT_ID);

            if (res.data.status === 'success') {
              gtag('event', 'add_to_project', {
                material_id: `${id}`,
                material_type: `${kindOf}`,
                added_projects: `${filteredProjects}`,
                userid: `${userLocalData._id}`,
                full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
                verified: `${userLocalData.isVerified}`,
              });
            } else {
              gtag('event', 'exception', {
                process: `adding a ${kindOf} of id: ${id} to projects of ids: ${filteredProjects} `,
                description: `error occured while crating the project by user: ${userLocalData._id}`,
                fatal: false,
              });
            }
            alertHandler({
              status: res.data.status,
              message: res.data.message,
            });

            setUpdatedProjects(() => ClesrSelection(updatedProjects));
            setLoading(false);
            onClose();
            setUpdate(!update);
          }
        })
        .catch(loginError => {
          gtag('event', 'exception', {
            process: `adding a ${kindOf} of id: ${id} to projects of ids: ${filteredProjects} `,
            description: loginError,
            fatal: false,
          });
          return loginError;
        }));
  };

  return (
    <Modal
      size="6xl"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setUpdatedProjects(ClesrSelection(updatedProjects));
      }}
      shadow="md"
    >
      <ModalOverlay bg="rgba(0,0,0,0.48)" />
      <ModalContent
        minH="590px"
        color="black"
        bg="gray.50"
        overflow="auto"
        px="6"
        py="4"
        rounded="16px"
        boxShadow="md"
      >
        <ModalHeader p="0" m="0">
          <Flex pb="1">
            <Text
              py="1"
              color="gray.900"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="700"
              fontSize="20px"
            >
              Add to Project
            </Text>
            <Spacer />
            <Box py="7px" px="3">
              <Icon
                as={AiOutlineClose}
                fontSize="14px"
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  onClose();
                  setUpdatedProjects(ClesrSelection(updatedProjects));
                }}
              />
            </Box>
          </Flex>
        </ModalHeader>

        <ModalBody p="0" m="0" overflow={'auto'}>
          <Box pt="4" pb="8">
            <Text
              fontFamily="Inter"
              color="gray.900"
              fontStyle="normal"
              fontWeight="700"
              fontSize="14px"
            >
              Select Project
              <Text as="span" color="gray.700" pl="2" fontWeight="400">
                You can place learning materials in multiple projects
              </Text>
            </Text>
          </Box>

          <Box alignItems="center" justifyContent="center">
            <SimpleGrid
              boxSizing="borderBox"
              pb="2"
              gap={2}
              columns={[1, 2, 2, 4]}
              spacing="2"
              maxH="340px"
              overflow="auto"
            >
              {selected ? (
                updatedProjects?.map(project => (
                  <Box key={project.id} onClick={() => selectProject(project)}>
                    <ChooseProject
                      Icon={CPIcon}
                      projectName={project.project_name}
                      selected={project.selection}
                    />
                  </Box>
                ))
              ) : setProjectData ? (
                updatedProjects?.map(project => (
                  <Box key={project.id} onClick={() => selectProject(project)}>
                    <ChooseProject
                      Icon={CPIcon}
                      projectName={project.project_name}
                      selected={project.selection}
                    />
                  </Box>
                ))
              ) : (
                <Spinner />
              )}
            </SimpleGrid>
          </Box>
        </ModalBody>
        <ModalFooter alignItems="center !important" p="0" textAlign="center">
          <Center pt="4" w="100%" m="auto">
            {loading ? (
              <Button
                isLoading
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                bg="yellow.400"
                rounded="4px"
                px="4"
                py="2"
                _hover={{ bg: 'yellow.500' }}
                _active={{
                  bg: 'yellow.500',
                  transform: 'scale(0.98)',
                }}
                _focus={{
                  outline: 'none',
                }}
              >
                Add to Project
              </Button>
            ) : chose ? (
              <Button
                onClick={handleSubmit}
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                bg="yellow.400"
                rounded="4px"
                px="4"
                py="2"
                _hover={{ bg: 'yellow.500' }}
                _active={{
                  bg: 'yellow.500',
                  transform: 'scale(0.98)',
                }}
                _focus={{
                  outline: 'none',
                }}
              >
                Add to Project
              </Button>
            ) : (
              <Button
                isDisabled
                // onClick={handleSubmit}
                color="gray.900"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
                bg="yellow.400"
                rounded="4px"
                px="4"
                py="2"
                _hover={{ bg: 'yellow.500' }}
                _active={{
                  bg: 'yellow.500',
                  transform: 'scale(0.98)',
                }}
                _focus={{
                  outline: 'none',
                }}
              >
                Add to Project
              </Button>
            )}
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddToProject;
