import {
  Box,
  Stack,
  Heading,
  Text,
  SimpleGrid,
  useDisclosure,
  useBreakpointValue,
  Image,
  Flex,
} from '@chakra-ui/react';
import { BannerCard } from './Components/BannerCard';
import KNImg from '../../assests/Icons/HomePGCard/Knowledge_nugget.png';
// commented out for new pilot project ui design 
//////////////////////////////////////////////////////////////////////////////// /
// import UHImg from '../../assests/Icons/HomePGCard/Upload_to_hive.png';
// import CPImg from '../../assests/Icons/HomePGCard/Add_project.png';
// import CCImg from '../../assests/Icons/HomePGCard/Claim_course.png';
////////////////////////////////////////////////////////////////////////////////
import BulbLogo from '../../assests/Icons/Group 1.png';
import ClaimResource from './Components/ClaimResource';
import UploadToHive from '../../Components/Homepage/Components/HiveUploadContainer/UploadToHive';
import CreateKnowledgeNugget from '../KnowledgeNugget/CreateKnoledgeNugget';
import WHITELOGO from '../../assests/Elephant hive - Logo-Bottom Vector.png';
import Header2 from '../Header/Header2';
import { useHistory } from 'react-router-dom';

const FirstTimeUser = ({
  onClaimResource,
  updatehivedata,
  setUpdatehivedata,
  storageUsed,
  
}) => {
  const {
    isOpen: isCreateKnowledgeNuggetOpen,
    onOpen: onCreateKnowledgeNuggetOpen,
    onClose: onCreateKnowledgeNuggetClose,
  } = useDisclosure();
  const {
    isOpen: isClaimResourceOpen,
    onOpen: onClaimResourceOpen,
    onClose: onClaimResourceClose,
  } = useDisclosure();
  const {
    isOpen: isUploadToHiveOpen,
    onOpen: onUploadToHiveOpen,
    onClose: onUploadToHiveClose,
  } = useDisclosure();

  const handleClaimResourceFinish = values => {
    onClaimResource(values);
  };
  const history = useHistory();
  const userLocalData = JSON.parse(localStorage.getItem('user'));

  const data = [
    {
      banner_bg: 'yellow.400',
      icon_bg: 'white',
      tag_color: 'gray.700',
      icon_and_color: KNImg,
      text_line: 'Create Knowledge Nugget',
      tag_line: 'Summarize learning content',
      on_click: () => onCreateKnowledgeNuggetOpen(),
    },
    // comented out for new pilot project ui design
    ////////////////////////////////////////////////////////////////////////
    // {
    //   banner_bg: 'white',
    //   icon_bg: 'gray.200',
    //   tag_color: 'gray.500',
    //   icon_and_color: UHImg,
    //   text_line: 'Upload to Hive',
    //   tag_line: 'Save learning content',
    //   on_click: () => onUploadToHiveOpen(),
    // },
    // {
    //   banner_bg: 'white',
    //   icon_bg: 'gray.200',
    //   tag_color: 'gray.500',
    //   icon_and_color: CPImg,
    //   text_line: 'Create Project',
    //   tag_line: 'Organize your knowledge',
    // },
    // {
    //   banner_bg: 'white',
    //   icon_bg: 'gray.200',
    //   tag_color: 'gray.500',
    //   icon_and_color: CCImg,
    //   text_line: 'Claim Course Summary',
    //   tag_line: 'Access your institution course',
    //   on_click: () => onClaimResourceOpen(),
    // },
    //////////////////////////////////////////////////////////////////////////////
    {
      banner_bg: 'white',
      icon_bg: 'gray.200',
      tag_color: 'gray.500',
      icon_and_color: BulbLogo,
      text_line: 'View Knowledge Library',
      tag_line: 'Get ready-made summaries',
      on_click: () => history.push('/knowledge-library'),
    },
  ];

  return (
    <>
      <Header2
        storageUsed={storageUsed}
        updatehivedata={updatehivedata}
        setUpdatehivedata={setUpdatehivedata}
      />
      <Box
        bg="gray.50"
        display="flex"
        h="calc(100vh - 72px)"
        flexDirection="column"
        overflow="hidden"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Stack
          pt={useBreakpointValue({ base: '4', md: '4' })}
          spacing={{ base: '2', md: '4' }}
          align="center"
        >
          <Heading
            size={useBreakpointValue({ base: 'md', md: 'lg', lg: '2xl' })}
            textAlign="center"
          >
            Welcome {userLocalData.firstName}!
          </Heading>
          <Text
            color="gray.500"
            maxW="2xl"
            textAlign="center"
            fontSize={useBreakpointValue({ base: 'md', md: 'xl' })}
          >
            Save your knowledge in bite size summaries
          </Text>
        </Stack>
        <Box
          // maxW="8xl"
          w={["100vw","100vw","100vw","70vw"]}
          mt={['0','-10','-36','-12']}
          px={{ base: '4', md: '8', lg: '24' }}
          py={{ base: '8', md: '8', lg: '12' }}
        >
          <SimpleGrid
            columns={{ base: 1,sm: 1, md: 2, lg: 2 }}
            gap={{ base: '2', md: '3', lg: '8' }}
            justifyContent="space-between"
          >
            {data.map((card_data, indexNo) => (
              <BannerCard
                key={indexNo}
                bannerBg={card_data.banner_bg}
                iconBg={card_data.icon_bg}
                tagColor={card_data.tag_color}
                IconAndcolor={card_data.icon_and_color}
                textLine={card_data.text_line}
                tagLine={card_data.tag_line}
                onClick={card_data.on_click}
                firstUser={true}
              />
            ))}
          </SimpleGrid>
        </Box>
        {onCreateKnowledgeNuggetOpen && (
          <CreateKnowledgeNugget
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            isOpen={isCreateKnowledgeNuggetOpen}
            onClose={onCreateKnowledgeNuggetClose}
          />
        )}
        {onUploadToHiveOpen && (
          <UploadToHive
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            onCreateKnowledgeNuggetOpen={onCreateKnowledgeNuggetOpen}
            isOpen={isUploadToHiveOpen}
            onClose={onUploadToHiveClose}
            storageUsed={storageUsed}
          />
        )}
        <ClaimResource
          isOpen={isClaimResourceOpen}
          onOpen={onClaimResourceOpen}
          onClose={onClaimResourceClose}
          onFinish={handleClaimResourceFinish}
        />
      </Box>
      <Box position="relative">
        <Box position="absolute" ml={10} bottom={2}>
          <Flex align="end">
            <Image
              src={WHITELOGO}
              alt="Elephant Hive"
              w={['138.13px']}
              h={['99.99px']}
              fit="cover"
              display={['none', 'block']}
            />
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default FirstTimeUser;
