import {
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Img,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import KnBtn from '../../../assests/Icons/Base.png';
import KNLibdoc from '../../../assests/Icons/KnowledgeLibrary/KNLib-elem.png';
import KNLibTextBook from '../../../assests/Icons/KnowledgeLibrary/ResBook-elem.png';
import KNLibPodecst from '../../../assests/Icons/KnowledgeLibrary/podecast-elem.png';
import KNLibArticle from '../../../assests/Icons/KnowledgeLibrary/Article-elem.png';
import KNLibAccademic from '../../../assests/Icons/KnowledgeLibrary/Accademic-elem.png';
import { AiFillStar } from 'react-icons/ai';

function TableBody({
  searchValue,
  props,
  haveknowledgeData,
  filteredKnowledgeData,
  indexOfFirstPost,
  indexOfLastPost,
  addToMyKnowledgeNuggets,
}) {
  let ratings;
  return (
    <>
      <Tbody bg="white" w={'full'} my="6">
        {haveknowledgeData &&
          filteredKnowledgeData
            ?.filter(item => {
              if (
                item?.metadata?.title
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase())
              ) {
                return item;
              } else {
                return null;
              }
            })
            .slice(indexOfFirstPost, indexOfLastPost)
            .map((knowledge, index) => (
              <Tr
                key={index}
                // onClick={() => {
                //   props.history.push(
                //     `/knowledge/nugget?knowledge_nugget_id=${knowledge._id}`
                //   );
                // }}
                borderBottom="1px solid #E2E8F0"
              >
                <Td textAlign="center" borderBottom="none">
                  <Center>
                    <Button variant={'unstyled'} _hover={{cursor:"text"}}>
                      <Image
                        src={
                          knowledge.type === 'course'
                            ? KNLibTextBook
                            : knowledge.type === 'Article'
                            ? KNLibArticle
                            : knowledge.type === 'Podcast'
                            ? KNLibPodecst
                            : knowledge.type === 'Accademic'
                            ? KNLibAccademic
                            : KNLibdoc
                        }
                        alt={knowledge.type}
                      />
                    </Button>
                  </Center>
                </Td>
                <Td
                  px="3"
                  py="5"
                  w={['205px', '255px', '280px', '460px']}
                  overflow="hidden"
                  borderBottom="none"
                >
                  <Tooltip
                    hasArrow
                    label={knowledge.metadata.title}
                    bg="gray.800"
                    placement="top"
                  >
                    <Text
                      w={['205px', '255px', '280px', '460px']}
                      fontFamily={'body'}
                      color="gray.600"
                      fontSize="16px"
                      fontWeight={'bold'}
                    >
                      {knowledge.metadata.title}
                    </Text>
                  </Tooltip>
                </Td>
                <Td
                  // display={['none', 'none', 'block', 'block', 'block']}
                  px="3"
                  py="5"
                  borderBottom="none"
                  mt="7px"
                >
                  <Tooltip
                    hasArrow
                    label={knowledge.metadata.authors}
                    bg="gray.800"
                    placement="top"
                  >
                    <Text
                      fontFamily={'body'}
                      color="gray.400"
                      fontSize="16px"
                      fontWeight={'normal'}
                    >
                      {knowledge.metadata.authors}
                    </Text>
                  </Tooltip>
                </Td>
                <Td px="3" py="5" borderBottom="none">
                  <Text
                    // display={['none', 'none', 'none', 'none', 'block']}
                    fontFamily={'body'}
                    color="gray.400"
                    fontSize="16px"
                    fontWeight={'normal'}
                  >
                    {new Date(knowledge.created_at).toLocaleDateString(
                      'en-US',
                      {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      }
                    )}
                  </Text>
                </Td>
                <Td
                  px="3"
                  py="5"
                  w={'20px'}
                  textAlign="center"
                  borderBottom="none"
                >
                  <Tooltip
                    hasArrow
                    label="view source"
                    bg="gray.800"
                    placement="top"
                  >
                    <Center>
                      <BsArrowUpRightSquare
                        style={{
                          color: 'purple',
                          border: '1.5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          let sourceURL = knowledge.metadata.doi;
                          console.log('sourceURL', sourceURL);
                          window.open(
                            sourceURL.includes('https://')
                              ? sourceURL
                              : ` https://doi.org/${knowledge.metadata.doi}`
                          );
                        }}
                      />
                    </Center>
                  </Tooltip>
                </Td>
                <Td
                  // display={['none', 'none', 'none', 'none', 'block']}
                  px="3"
                  py="5"
                  borderBottom="none"
                >
                  <Flex align="center">
                    <Text display="none">{(ratings = 0)}</Text>
                    {knowledge?.ratings?.map(ratValue => {
                      ratings += Number(ratValue?.rating || 0);
                      return null;
                    })}

                    <Text display="none">
                      {' '}
                      {(ratings /= knowledge?.ratings?.length || 0)}
                    </Text>

                    {Array(Math.trunc(Number(ratings || 0))) // Variable ratings
                      .fill('')
                      .map((_, i) => (
                        <Icon
                          as={AiFillStar}
                          color="yellow.400"
                          fontSize="16px"
                          border="1.5px"
                          borderColor="gray.400"
                          mr="1"
                          key={i}
                        />
                      ))}
                    {Array(5 - Math.trunc(Number(ratings || 0)))
                      .fill('')
                      .map((_, i) => (
                        <Icon
                          as={AiFillStar}
                          color="gray.200"
                          fontSize="16px"
                          border="1.5px"
                          borderColor={'gray.400'}
                          mr="1"
                          key={i}
                        />
                      ))}
                  </Flex>
                </Td>
                <Td px="3" py="5" borderBottom="none">
                  <Tooltip
                    hasArrow
                    label="Add to Hive"
                    bg="gray.800"
                    placement="top"
                  >
                    <Button
                      variant="unstyled"
                      size="sm"
                      mr={2}
                      onClick={event => {
                        addToMyKnowledgeNuggets(
                          event,
                          knowledge._id,
                          knowledge.metadata.title,
                          knowledge.type
                        );
                      }}
                    >
                      <Img
                        boxSize="8"
                        src={KnBtn}
                        alt="Knowledge-library-nugget"
                      />
                    </Button>
                  </Tooltip>
                </Td>
              </Tr>
            ))}
      </Tbody>
    </>
  );
}

export default TableBody;
