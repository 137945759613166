import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import ResourceOverviewAccordions from '../Components/ResourceOverview/ResourceOverviewAccordions';
import Loading from '../Components/Loading/Loading';
import axios from 'axios';
import Header2 from '../Components/Header/Header2';
import { useIntercom } from 'react-use-intercom';
import gtag from 'ga-gtag';

const resource = {
  resourceId: '',
  resourceName: '',
  description: '',
  institution: '',
  sourceLink: '',
  humanReviewed: '',
  approvedLecturers: [],
  summaryUpdated: '',
  totalRatings: 0,
  tags: [],
  ratingList: [],
};

const modules = [];

const ResourceOverview = () => {
  const location = useLocation();
  const [storageUsed, setStorageUsed] = useState(0);
  const resourceId = new URLSearchParams(location.search).get('resource_id');
  const { REACT_APP_EH_BACKEND } = process.env;
  const [loading, setLoading] = useState(true);
  const [resourceUpdate, setResourceUpdate] = useState(false);
  const { boot, shutdown } = useIntercom();
  const userLocalData = JSON.parse(localStorage.user);
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id]);

  useEffect(() => {
    if (resourceId) {
      axios({
        method: 'GET',
        url: `${REACT_APP_EH_BACKEND}/resources/${resourceId}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      })
        .then(resourceResponse => {
          gtag('config', REACT_APP_GA_MEASUREMENT_ID);
          const resourceData = resourceResponse.data[0];
          resource.resourceId = resourceData._id;
          resource.resourceName = resourceData.resource_name;
          let ratings = 0;
          resourceData.rating.map(rating => {
            ratings += Number(rating.rating);
            return null;
          });
          // use the average rating of all provided ratings
          ratings /= resourceData.rating.length;
          resource.totalRatings = ratings;
          resource.tags = resourceData.tags;
          resource.summaryUpdated = new Date(
            resourceData.created_at
          ).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          });
          resource.ratingList = resourceData.rating;
          resource.description = resourceData.description;
          resource.sourceLink = resourceData.source_link;
          resource.humanReviewed = resourceData.human_reviewed;
          resource.approvedLecturers = resourceData.approvedLecturers;
          resource.institution = resourceData.institute.institute_name;

          const moduleObj = {};

          resourceData.module_id.map((module_data, index) => {
            moduleObj[index] = module_data.id;
            return null;
          });

          const queryString = Object.keys(moduleObj)
            .map(key => '_id=' + moduleObj[key])
            .join('&');
          if (queryString !== '') {
            axios({
              method: 'GET',
              url: `${REACT_APP_EH_BACKEND}/modules?${queryString}`,
              headers: {
                Authorization: 'Bearer ' + localStorage.login_token,
              },
            })
              .then(moduleResponse => {
                const moduleData = moduleResponse.data;

                gtag('event', 'access_course', {
                  course_id: `${resourceData._id}`,
                  course_name: `${resourceData.resource_name}`,
                  course_ratings: `${ratings}`,
                  userid: `${userLocalData._id}`,
                  full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
                  verified: `${userLocalData.isVerified}`,
                });

                modules.splice(0, modules.length);
                moduleData.map(modu => {
                  modu.module['lessons'] = modu.module['lesson'];
                  delete modu.module['lesson'];
                  modules.push(modu.module);
                  return null;
                });
                setLoading(false);
              })
              .catch(loginError => {
                gtag('event', 'exception', {
                  process: `accessing the resource "${resource.resourceName}" of Id: "${resource.resourceId}" by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
                  description: loginError,
                  fatal: false,
                });
                return loginError;
              });
          }
        })
        .catch(loginError => {
          gtag('event', 'exception', {
            process: `accessing the resource "${resource.resourceName}" of Id: "${resource.resourceId}" by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
            description: loginError,
            fatal: false,
          });
          return loginError;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [REACT_APP_EH_BACKEND, resourceId, resourceUpdate]);

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <Box overflow={'hidden'} h="100vh">
          <Header2 storageUsed={storageUsed} />
          <Box overflowY={'auto'} h={'calc(100vh - 72px)'}>
            <ResourceOverviewAccordions
              setResourceUpdate={setResourceUpdate}
              resourceUpdate={resourceUpdate}
              resource={resource}
              modules={modules}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ResourceOverview;
