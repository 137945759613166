import { Controls, useReactFlow } from 'react-flow-renderer';
import React /*, { useState }*/ from 'react';
import { Box, ChakraProvider, Flex, Image, Text } from '@chakra-ui/react';
import extendTheme from '../../extendTheme';
import Plus from '../../assests/Icons/Zoom/PlusCircle.png';
import Minus from '../../assests/Icons/Zoom/MinusCircle.png';

function ZoomController({ mdLeftVal, lgLeftVal, zoom }) {
  // const [zoomVal, setZoomVal] = useState(zoom);
  const { zoomIn, zoomOut } = useReactFlow();

  // const calPercentage = value => {
  //   return Math.floor(50 * value);
  // };

  return (
    <ChakraProvider theme={extendTheme}>
      <Box position="absolute" left={{ md: mdLeftVal, lg: lgLeftVal }}>
        <Controls
          showZoom={false}
          showFitView={false}
          showInteractive={false}
          style={{ position: 'absolute', background: '#fff' }}
        >
          <Box
            bg="#e2e8f0"
            rounded="full"
            position="absolute"
            top={{ md: '-215', lg: '-264' }}
          >
            <Flex align="center">
              <Box
                p={{ md: '8px', lg: '12px' }}
                position="absolute"
                cursor="pointer"
                _hover={{ background: '#fff' }}
                rounded="full"
                onClick={() => {
                  // if (zoomVal > 0) {
                  zoomOut();
                  //   setZoomVal(Number(zoomVal) - 0.25);
                  // }
                }}
              >
                <Image w="20px" src={Minus} alt="zoom out" />
              </Box>
              <Text
                position="relative"
                left="45px"
                mr={{ md: '20px', lg: '30px' }}
                w="60px"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="600"
                fontSize="16px"
                color="#1A202C"
                textAlign="center"
                zIndex="3"
                cursor="pointer"
              >
                {/* {calPercentage(zoomVal)}% */}
              </Text>
              <Box
                p={{ md: '8px', lg: '12px' }}
                cursor="pointer"
                _hover={{ background: '#fff' }}
                rounded="full"
                onClick={() => {
                  // if (zoomVal < 2) {
                  zoomIn();
                  //   setZoomVal(Number(zoomVal) + 0.25);
                  // }
                }}
              >
                <Image w="30px" src={Plus} alt="zoom in" />
              </Box>
            </Flex>
          </Box>
        </Controls>
      </Box>
    </ChakraProvider>
  );
}

export default ZoomController;
