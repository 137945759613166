import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ResourceContext } from '../Context/ResourceContext';
import { ModuleContext } from '../Context/ModuleContext';
import TabController from '../Components/LessonSummary/TabViews/TabController';
import Header2 from '../Components/Header/Header2';
import Loading from '../Components/Loading/Loading';
import axios from 'axios';
import { useIntercom } from 'react-use-intercom';

const LessonSummary = () => {
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const [storageUsed, setStorageUsed] = useState(0);
  const resourceId = new URLSearchParams(location.search).get('resource_id');
  const resourceName = new URLSearchParams(location.search).get(
    'resource_name'
  );
  const moduleId = new URLSearchParams(location.search).get('module_id');
  const moduleName = new URLSearchParams(location.search).get('module_name');
  const lessonId = new URLSearchParams(location.search).get('lesson_id');
  const lessonCount = new URLSearchParams(location.search).get('lesson_count');
  const [lessonCode, setLessonCode] = useState();
  const [lessonName, setLessonName] = useState();
  const [lessonData, setLessonData] = useState();
  const [previousLessonId, setPreviousLessonId] = useState();
  const [nextLessonId, setNextLessonId] = useState();
  const [resourceData, setResourceData] = useState();
  const [moduleData, setModuleData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOk, setIsOk] = useState(false);
  const [path, setPath] = useState('');
  const { boot, shutdown } = useIntercom();
  let userLocalData = JSON.parse(localStorage.user);

  const goToPreviousLesson = value => {
    setPreviousLessonId(value);
  };

  const goToNextLesson = value => {
    setNextLessonId(value);
  };

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id, update, refresh]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    let urlLessonCode = new URLSearchParams(location.search).get('lesson_code');
    let urlLessonName = new URLSearchParams(location.search).get('lesson_name');
    setLessonCode(urlLessonCode);
    setLessonName(urlLessonName);

    if (moduleId && lessonId) {
      axios({
        method: 'GET',
        url: `${REACT_APP_EH_BACKEND}/modules/${moduleId}/${lessonId}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      })
        .then(lessonResponse => {
          setLessonData(lessonResponse.data);
          let incomingLessonCode = '';
          let incomingLessonName = '';
          lessonResponse.data.map(lesson_data => {
            lesson_data.module.lesson.map(lesson_in_data => {
              incomingLessonName = lesson_in_data.name;
              incomingLessonCode = lesson_in_data.code;

              return null;
            });
            return null;
          });
          setLessonCode(incomingLessonCode);
          setLessonName(incomingLessonName);
          setLoading(false);
        })
        .catch(loginError => {
          return loginError;
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousLessonId, nextLessonId]);

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    const resourceObj = {};

    userLocalData.resource_id.map((resource_data, index) => {
      resourceObj[index] = resource_data.id;
      return null;
    });
    const queryString = Object.keys(resourceObj)
      .map(key => '_id=' + resourceObj[key])
      .join('&');
    if (queryString !== '') {
      axios({
        method: 'GET',
        url: `${REACT_APP_EH_BACKEND}/resources?${queryString}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      })
        .then(resourceResponse => {
          setResourceData(resourceResponse.data);

          const moduleResourceObj = {};

          resourceResponse.data.map((resource, index) => {
            moduleResourceObj[index] = resource.resource._id;
            return null;
          });
          const queryString = Object.keys(moduleResourceObj)
            .map(key => 'resource_id=' + moduleResourceObj[key])
            .join('&');
          axios({
            method: 'GET',
            url: `${REACT_APP_EH_BACKEND}/modules?${queryString}`,
            headers: {
              Authorization: 'Bearer ' + localStorage.login_token,
            },
          })
            .then(moduleResponse => {
              setModuleData(moduleResponse.data);
            })
            .catch(loginError => {
              return loginError;
            });
        })
        .catch(loginError => {
          return loginError;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, refresh]);

  const removeAudio = () => {
    // console.log('fhjhgjhggg 22');
    console.log('ohhkl', isOk);

    if (isOk) {
      const { REACT_APP_EH_BACKEND } = process.env;
      axios({
        method: 'PUT',
        url: `${REACT_APP_EH_BACKEND}/nuggets/audio-rem/${userLocalData._id}/Lessons/${moduleId}${lessonId}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
      })
        .then(audioRemoveResponse => {
          console.log(audioRemoveResponse);
          setIsOk(false);
          setPath('');
        })
        .catch(loginError => {
          return loginError;
        });
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header2 storageUsed={storageUsed} removeAudio={removeAudio} />
          <Box overflowY={'auto'} h={'calc(100vh - 72px)'}>
            <ResourceContext.Provider value={resourceData}>
              <ModuleContext.Provider value={moduleData}>
                <TabController
                  lesson={lessonData}
                  resourceId={resourceId}
                  resourceName={resourceName}
                  moduleId={moduleId}
                  moduleName={moduleName}
                  lessonId={lessonId}
                  lessonCode={lessonCode}
                  lessonName={lessonName}
                  lessonCount={lessonCount}
                  onPreviousLesson={goToPreviousLesson}
                  onNextLesson={goToNextLesson}
                  update={update}
                  setUpdate={setUpdate}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  removeAudio={removeAudio}
                  isOk={isOk}
                  setIsOk={setIsOk}
                  path={path}
                  setPath={setPath}
                />
              </ModuleContext.Provider>
            </ResourceContext.Provider>
          </Box>
        </>
      )}
    </>
  );
};

export default LessonSummary;
