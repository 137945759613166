import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FiPause } from 'react-icons/fi';
// import { FiSkipForward } from 'react-icons/fi';
// import { FiSkipBack } from 'react-icons/fi';
import { BsPlay } from 'react-icons/bs';
import extendTheme from '../../extendTheme';
import { GiSpeaker } from 'react-icons/gi';
// import axios from 'axios';
// import song from '../../assests/Wiz Khalifa - See You Again ft. Char.mp3';

// const summary = [];
function SummaryPlayer({
  w,
  lesson,
  pauseStateVal,
  isOk,
  path,
  getAudio,
  onChange,
  percentage,
  audioRef,
  play,
  getCurrDuration,
  // duration,
  setDuration,
  position,
  // setPosition,
  currentTime,
  isPlaying2,
  isListenClicked,
  setIsListenClicked,
}) {
  // const history = useHistory();
  // const userLocalData = JSON.parse(localStorage.user);

  // const [isPlaying, setPlaying] = useState(false);
  // const [isActive, setIsActive] = useState(false);
  // const [isPaused, setIsPaused] = useState(true);
  // // const [isOk, setIsOk] = useState(false);
  // // const [path, setPath] = useState('');
  // // const [materialId, setMaterialId] = useState();
  // const [speechTxtLength, setSpeechTxtLength] = useState(0);
  // const [time, setTime] = useState(0);
  // const { speak, cancel } = useSpeechSynthesis();
  // const lessonData = lesson;
  const posi = isNaN(position) && position !== undefined ? position : 0;
  // const { REACT_APP_EH_BACKEND } = process.env;
  // const pauseState = pauseStateVal;
  // let speechText2 = '';

  // const unlisten = history.listen(({ action, location }) => {
  //   if (action === undefined) {
  //     cancel();
  //     setPlaying(false);
  //     setIsActive(false);
  //     setPlaying(false);
  //     setTime(0);
  //   }
  // });

  // useEffect(() => {
  //   cancel();
  //   setPlaying(false);
  //   setIsActive(false);
  //   setPlaying(false);
  //   setTime(0);
  //   unlisten();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lessonData]);

  // useEffect(() => {
  //   cancel();
  //   setPlaying(false);
  //   setIsActive(false);
  //   setPlaying(false);
  //   setTime(0);
  //   unlisten();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pauseState]);

  // lessonData?.map(lesson_data => {
  //   lesson_data.module.lesson.map(lesson_in_data => {
  //     summary.splice(0, summary.length);
  //     // setMaterialId(`${lesson_data.module._id}${lesson_in_data.id}`);
  //     lesson_in_data.initialNodes.map(initial_nodes_data => {
  //       if (initial_nodes_data.className !== 'main-node') {
  //         summary.push(initial_nodes_data?.data?.label);
  //       }

  //       return null;
  //     });

  //     return null;
  //   });

  //   return null;
  // });

  // React.useEffect(() => {
  //   let interval = null;

  //   if (isActive && isPaused === false) {
  //     interval = setInterval(() => {
  //       setTime(time => time + 10);
  //     }, 10);
  //   } else {
  //     clearInterval(interval);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [isActive, isPaused]);

  // const playPause = () => {
  //   if (isPlaying) {
  //     cancel();
  //     setPlaying(false);
  //     setIsActive(false);
  //     setTime(0);
  //   } else {
  //     let speechText = '';
  //     summary.map(summary_data => {
  //       speechText += summary_data;
  //       speechText2 += summary_data;
  //       return null;
  //     });
  //     speak({
  //       text: speechText,
  //       default: true,
  //       lang: 'en-AU',
  //       localService: true,
  //       name: 'Karen',
  //       voiceURI: 'Karen',
  //     });
  //     setSpeechTxtLength(speechText.length);
  //     setPlaying(true);
  //     setIsPaused(!isPaused);
  //     setIsActive(true);
  //     setIsPaused(false);
  //   }
  // };

  // useEffect(() => {
  //   if (((time * 1.5) / speechTxtLength).toFixed(0) > 100) {
  //     setPlaying(false);
  //     setIsActive(false);
  //     setTime(0);
  //   }
  // }, [time, speechTxtLength]);

  // console.log('jhkjhj 2', lessonData);

  // const getAudio = () => {
  //   console.log('fhjhgjhggg');
  //   let speechText = '';
  //   // let materialId = '';
  //   summary.map(summary_data => {
  //     speechText += summary_data;
  //     return null;
  //   });
  //   console.log('jhkjhj', speechText);

  //   // lessonData?.map(lesson_data => {
  //   //   lesson_data.module.lesson.map(lesson_in_data => {
  //   //     materialId = `${lesson_data.module._id}${lesson_in_data.id}`;

  //   //     return null;
  //   //   });

  //   //   return null;
  //   // });
  //   console.log('jhkjhj 3', materialId);

  //   axios({
  //     method: 'POST',
  //     // url: `${REACT_APP_EH_BACKEND}/nuggets/audio/${speechText}`,
  //     url: `${REACT_APP_EH_BACKEND}/nuggets/audio/${userLocalData._id}/Lessons/${materialId}`,
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.login_token,
  //     },
  //     data: { text: speechText },
  //   })
  //     .then(audioResponse => {
  //       // const data = [];
  //       // knowledgeNuggetResponse.data?.map(knowledge_nugget_data => {
  //       //   knowledge_nugget_data.knowledgeNugget.notes.map(note_data => {
  //       //     data.push({
  //       //       id: note_data.id,
  //       //       note: note_data.note,
  //       //       userId: note_data?.userId,
  //       //       timestamp: note_data.timestamp,
  //       //     });

  //       //     return null;
  //       //   });

  //       //   return null;
  //       // });
  //       // setNoteData(data);
  //       // setNoteDataLength(data.length);
  //       console.log('asddh', audioResponse);
  //       setIsOk(true);
  //       setPath(audioResponse?.data?.filePath);
  //     })
  //     .catch(loginError => {
  //       return loginError;
  //     });
  // };

  console.log('pppejrj', percentage);

  function secondsToHms(seconds) {
    if (!seconds) return '00:00';

    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }

    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}:${min}:${sec}`;
    } else if (Number(min) === 0) {
      return `00:${sec}`;
    } else {
      return `${min}:${sec}`;
    }
  }

  return (
    <ChakraProvider theme={extendTheme}>
      <Box
        borderTop="1px solid #E2E8F0"
        bg="white"
        w={{ base: '100%', sm: w, md: w, lg: w }}
        h="120px"
        position="fixed"
        zIndex={2}
        bottom="0"
        p="4"
        float="left"
      >
        {/* <speak
          xmlns="http://www.w3.org/2001/10/synthesis"
          // xmlns:mstts="http://www.w3.org/2001/mstts"
          // xmlns:emo="http://www.w3.org/2009/10/emotionml"
          version="1.0"
          // xml:lang="en-US"
        >
          <voice name="en-GB-LibbyNeural">
            <prosody rate="1%" pitch="11%">
              You can replace this text with any text you wish. You can either
              write in this text box or paste your own text here. Try different
              languages and voices. Change the speed and the pitch of the voice.
              You can even tweak the SSML (Speech Synthesis Markup Language) to
              control how the different sections of the text sound. Click on
              SSML above to give it a try! Enjoy using Text to Speech!
            </prosody>
          </voice>
        </speak> */}
        {/* http://localhost:8080/nuggets/audio/Hello */}
        {/* {isOk ? (
          <Box position="absolute" top="-200">
            <video
              id="vid1"
              // className="azuremediaplayer amp-default-skin"
              // autoPlay
              controls
              width="640"
              height="400"
              poster="poster.jpg"
              data-setup='{"nativeControlsForTouch": false}'
            >
              <source src={path} />
              <p class="amp-no-js">
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that supports HTML5 video
              </p>
            </video>
          </Box>
        ) : (
          ''
        )} */}

        <Box>
          <audio
            ref={audioRef}
            src={path}
            onLoadedData={e => {
              setDuration(e.currentTarget.duration.toFixed(2));
            }}
            onTimeUpdate={getCurrDuration}
          ></audio>
        </Box>

        <Box>
          {/* {console.log(position)}
          {console.log('ispjklj', isPlaying2)} */}
          <Slider
            aria-label="slider-ex-2"
            display={isOk ? 'inline-block' : 'none'}
            // value={((time * 1.5) / speechTxtLength || 0).toFixed(0)}
            // defaultValue={30}
            value={!isNaN(posi) && posi !== undefined ? posi : 0}
            min={0}
            max={100}
            onChange={v => {
              onChange(v);
            }}
            p="0"
          >
            <SliderTrack bg="gray.300" p="0" h="6px" rounded="2px">
              <SliderFilledTrack bg="yellow.400" />
            </SliderTrack>
            <SliderThumb
              bg={'yellow.400'}
              boxShadow="lg"
              boxSize="4"
              _focus={{
                outline: 'none',
              }}
            />
          </Slider>
          <Text
            pt="1"
            display={isOk ? 'block' : 'none'}
            color="gray.600"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="16px"
          >
            {/* {('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
            {('0' + Math.floor((time / 1000) % 60)).slice(-2)} */}
            {secondsToHms(currentTime)}
          </Text>
          <Center>
            <Flex>
              {/* <Button
                p="0"
                color="gray.500"
                _hover={{ color: 'gray.900' }}
                _focus={{
                  outline: 'none',
                }}
                bg="white"
                border="1px solid #E2E8F0"
              >
                <Box fontSize="20px">
                  <FiSkipBack />
                </Box>
              </Button> */}
              <Button
                display={isOk ? 'block' : 'none'}
                mx="4"
                p="0"
                bg="purple.600"
                color="white"
                _hover={{}}
                _focus={{
                  outline: 'none',
                }}
                _active={{ bg: 'purple.500' }}
                // onClick={playPause}
                onClick={play}
              >
                <Box fontSize="20px" align="center">
                  {isPlaying2 ? <FiPause /> : <BsPlay fontSize="25px" />}
                </Box>
              </Button>
              <Button
                display={isOk ? 'none' : 'flex'}
                isLoading={isListenClicked}
                my="4"
                px="4"
                py="6"
                bg="purple.600"
                color="white"
                _hover={{}}
                _focus={{
                  outline: 'none',
                }}
                _active={{ bg: 'purple.500' }}
                onClick={() => {
                  setIsListenClicked(true);
                  getAudio();
                }}
              >
                <Flex align="center">
                  <Text
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="17px"
                    pr="2"
                  >
                    Listen to the summary
                  </Text>
                  <GiSpeaker fontSize="25px" />
                </Flex>
              </Button>
              {/* <Button
                p="0"
                color="gray.500"
                _hover={{ color: 'gray.900' }}
                _focus={{
                  outline: 'none',
                }}
                bg="white"
                border="1px solid #E2E8F0"
              >
                <Box fontSize="20px">
                  <FiSkipForward />
                </Box>
              </Button> */}
            </Flex>
          </Center>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default SummaryPlayer;
