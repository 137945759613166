import {
  Box,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import React, {  useEffect,  useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { VscArrowLeft } from 'react-icons/vsc';
import Header2 from '../Components/Header/Header2';
import Loading from '../Components/Loading/Loading';
import KnowledgeTable from '../Components/KnowledgeNugget/KnowledgeNuggetUpContainer/KnowledgeTable';
import { useIntercom } from 'react-use-intercom';

function KnowledgeLibrary(props) {
  let userLocalData = JSON.parse(localStorage.user);
  const [storageUsed, setStorageUsed] = useState(0);
  const [knowledgeData, setKnowledgeData] = useState();
  const [haveknowledgeData, setHaveknowledgeData] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 8;
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    boot({
      name: userLocalData.firstName,
      email: userLocalData.email,
    });
    return () => {
      shutdown();
    };
  }, [boot, shutdown, userLocalData.email, userLocalData.firstName]);

  // get storage information on user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivestorageinfo/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(resourceResponse => {
        setStorageUsed(resourceResponse.data);
      })
      .catch(storageError => {
        return storageError;
      });
  }, [userLocalData._id]);

  useEffect(() => {
    // get knowledge nugget data in first instance
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/articles`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseknowledgeData => {
        setKnowledgeData(responseknowledgeData.data);

        setHaveknowledgeData(true);
        setIsloading(false);
      })
      .catch(error => {
        return error;
      });
  }, [userLocalData._id]);

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  function sortByTitle(a, b) {
    if (a.metadata.title.toLowerCase() < b.metadata.title.toLowerCase()) {
      return -1;
    }
    if (a.metadata.title.toLowerCase() > b.metadata.title.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function sortByAuthors(a, b) {
    if (a.metadata.authors.toLowerCase() < b.metadata.authors.toLowerCase()) {
      return -1;
    }
    if (a.metadata.authors.toLowerCase() > b.metadata.authors.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function sortByDate(a, b) {
    return new Date(a.created_at) - new Date(b.created_at);
  }

  function sortByRatings(a, b) {
    let ratingA = 0;
    let ratingB = 0;
    a.ratings.map(rate => {
      ratingA += rate.rating;

      return null;
    });
    b.ratings.map(rate => {
      ratingB += rate.rating;

      return null;
    });
    ratingA /= a.ratings.length;
    ratingB /= b.ratings.length;

    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  }


  return (
    <>
      {isloading ? (
        <Loading text="Loading Elephant Hive Library" />
      ) : (
        <Box overflow="hidden" h="100vh">
          <Header2 storageUsed={storageUsed} />
          <Box overflowY={'auto'} h={'calc(100vh - 72px)'}>
            <Box
              py="2"
              px={['4', '6']}
              bg="white"
              borderTop="1px solid #E2E8F0"
              borderBottom="1px solid #E2E8F0"
              display={['block', 'block', 'block', 'none']}
            >
              <FormControl>
                <InputGroup w={'full'} h="40px">
                  <Input
                    id="projsearch"
                    name="projsearch"
                    fontFamily={'body'}
                    placeholder="Search in Library"
                    type="text"
                    onChange={e => setSearchValue(e.target.value)}
                    value={searchValue}
                  />
                  <InputRightElement children={<BsSearch />} />
                </InputGroup>
              </FormControl>
            </Box>
            <Flex
              h={'56px'}
              border="1px"
              bgColor="gray.50"
              borderColor={'gray.200'}
              align="center"
              justify="start"
              flexDir="row"
            >
              <Box display="flex" alignItems="center" className="l-container">
                <Box ml={5} mr={5}>
                  <VscArrowLeft
                    size={24}
                    onClick={() => props.history.goBack()}
                    style={{ color: 'gray', cursor: 'pointer' }}
                  />
                </Box>
                <Box>
                  <Text
                    fontWeight={'bold'}
                    fontFamily={'body'}
                    fontSize={{ base: '18px', sm: '20px', md: '24px' }}
                    color="gray.900"
                  >
                    Knowledge Library
                  </Text>
                </Box>
              </Box>
            </Flex>
            <Box>
              <KnowledgeTable
                haveknowledgeData={haveknowledgeData}
                knowledgeData={knowledgeData}
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                props={props}
                postsPerPage={postsPerPage}
                totalPosts={knowledgeData.length}
                paginate={paginate}
                currentPage={currentPage}
                sortByTitle={sortByTitle}
                sortByAuthors={sortByAuthors}
                sortByDate={sortByDate}
                sortByRatings={sortByRatings}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default KnowledgeLibrary;
