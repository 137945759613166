import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryPlayer from '../SummaryPlayer';
import MindMap from '../KnowledgeNuggetMindMap/MindMap';

function MapTab({
  lesson,
  editStateVal,
  editTagModalXVal,
  editTagModalYVal,
  pauseStateVal,
  mdLeftVal,
  lgLeftVal,
  isOk,
  path,
  getAudio,
  onChange,
  percentage,
  audioRef,
  play,
  getCurrDuration,
  duration,
  setDuration,
  position,
  setPosition,
  currentTime,
  isPlaying2,
  isListenClicked,
  setIsListenClicked,
}) {
  const lessonData = lesson;
  const editState = editStateVal;
  const editTagModalX = editTagModalXVal;
  const editTagModalY = editTagModalYVal;
  const pauseState = pauseStateVal;

  return (
    <ChakraProvider theme={theme}>
      <MindMap
        mdLeftVal={mdLeftVal}
        lgLeftVal={lgLeftVal}
        editStateVal={editState}
        lesson={lessonData}
        editTagModalXVal={editTagModalX}
        editTagModalYVal={editTagModalY}
        pauseStateVal={pauseState}
      />
      <SummaryPlayer
        isOk={isOk}
        path={path}
        getAudio={getAudio}
        onChange={onChange}
        percentage={percentage}
        audioRef={audioRef}
        play={play}
        getCurrDuration={getCurrDuration}
        duration={duration}
        setDuration={setDuration}
        position={position}
        setPosition={setPosition}
        currentTime={currentTime}
        isPlaying2={isPlaying2}
        isListenClicked={isListenClicked}
        setIsListenClicked={setIsListenClicked}
        w="100%"
      />
    </ChakraProvider>
  );
}

export default MapTab;
