import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import React, { useEffect, useState } from 'react';
import AddToProject from './ProjectComps/AddToProject';
import NoProjects from './ProjectComps/NoProjects';
import { useLocation } from 'react-router-dom';
import EditTag from '../../Homepage/Components/HiveUploadContainer/EditTag';
import Delete from '../../LessonSummary/HeaderComps/Delete';
import axios from 'axios';
import { FiShare2 } from 'react-icons/fi';
import ProjectSharing from './ProjectComps/ShareProject/ProjectSharing';

function MaterialMenu({
  type,
  id,
  projecId,
  orgProjectData,
  projectData,
  sharedProjData,
  orgsharedProjectData,
  sharedProjectData,
  resourceData,
  nonsummaryhiveData,
  knowledgeNuggetData,
  elementDataRes,
  elementDataNon,
  elementDataKn,
  upateprojectData,
  setUpdateprojectData,
  updatehivedata,
  setUpdatehivedata,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  isproject,
}) {
  const {
    isOpen: isAddToProjectOpen,
    onOpen: onAddToProjectOpen,
    onClose: onAddToProjectClose,
  } = useDisclosure();
  const {
    isOpen: isNoProjectsOpen,
    onOpen: onNoProjectsOpen,
    onClose: onNoProjectsClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteinsideProjectOpen,
    onOpen: onDeleteinsideProjectOpen,
    onClose: onDeleteinsideProjectClose,
  } = useDisclosure();
  const {
    isOpen: isProjectSharingOpen,
    onOpen: onProjectSharingOpen,
    onClose: onProjectSharingClose,
  } = useDisclosure();

  const location = useLocation();
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');

  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [updatehivedata, updateKnowledgeNuggetData]);

  const { REACT_APP_EH_BACKEND } = process.env;
  let userLocalData = JSON.parse(localStorage.user);
  const headers = {
    headers: {
      Authorization: 'Bearer ' + localStorage.login_token,
    },
  };

  const handleDeletenonsummery = async () => {
    // delete function when confirm delete button is clicked
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/nonsummaryhivesingledata/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deletenonsummerydataproj/${userLocalData._id}/${id}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(error => {
        return error;
      });
    setUpdatehivedata(!updatehivedata);
  };

  const handleDeleteKN = async () => {
    // delete function when confirm delete button is clicked
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/${userLocalData._id}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deleteKNdataproj/${userLocalData._id}/${id}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(error => {
        return error;
      });
    setUpdateKnowledgeNuggetData(!updateKnowledgeNuggetData);
  };

  const handleDeleteResource = async () => {
    // delete function when confirm delete button is clicked for resources
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/resources/delete/${userLocalData._id}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deleteResourcedataproj/${userLocalData._id}/${id}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(error => {
        return error;
      });
    setUpdatehivedata(!updatehivedata);
  };

  const handleremovefromproject = async () => {
    // remove project from projects list
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/projectdataremovesingle/${userLocalData._id}/${type}/${projecId}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          if (responseData.data.status) {
            alertHandler({
              status: responseData.data.status,
              message: responseData.data.message,
            });
          }
        }
      })
      .catch(err => {
        return err;
      });
    setUpdatehivedata(!updatehivedata);
  };

  const isNotTheOwnerFunction = () => {
    let isNotTheOwner;
    if (sharedProjData) {
      if (projectData && projectData[0]?.user_id === userLocalData._id) {
        isNotTheOwner = false;
      } else {
        switch (type) {
          // validation of the user of non summary item
          case 'nonsummary':
            if (elementDataNon?.user_id === userLocalData._id) {
              isNotTheOwner = false;
            } else {
              isNotTheOwner = true;
            }
            break;

          // validation of the user of course item
          // here it needs to check the user record
          case 'resource':
            const sharedUser = sharedProjData?.shared_users?.filter(user => {
              return user.user_id === userLocalData._id;
            });

            if (
              sharedUser &&
              sharedUser[0]?.inserted_resource_ids?.includes(id)
            ) {
              isNotTheOwner = false;
            } else {
              isNotTheOwner = true;
            }
            break;

          // validation of the user of knowledge nugget item
          case 'knowledgeNugget':
            switch (elementDataKn?.type) {
              // validating if the knowledge nugget is a web scraping or an academic paper
              case 'web scrapings':
              case 'Academic paper':
                if (elementDataKn?.user_id === userLocalData._id) {
                  isNotTheOwner = false;
                } else {
                  isNotTheOwner = true;
                }
                break;

              // validate if the knowledge nugget is an article added
              // here it needs to check the user record
              case 'Article':
                const sharedUser = sharedProjData?.shared_users?.filter(
                  user => {
                    return user.user_id === userLocalData._id;
                  }
                );

                if (
                  sharedUser &&
                  sharedUser[0]?.inserted_kNLibrary_ids?.includes(id)
                ) {
                  isNotTheOwner = false;
                } else {
                  isNotTheOwner = true;
                }
                break;
              default:
                isNotTheOwner = false;
            }
            break;

          default:
            isNotTheOwner = false;
        }
      }
    } else {
      isNotTheOwner = false;
    }

    return isNotTheOwner;
  };
  const isNotTheOwnerFunctionEditTags = () => {
    let isNotTheOwner;
    if (sharedProjData) {
      switch (type) {
        // validation of the user of non summary item
        case 'nonsummary':
          if (elementDataNon?.user_id === userLocalData._id) {
            isNotTheOwner = false;
          } else {
            isNotTheOwner = true;
          }
          break;

        // validation of the user of course item
        // here it needs to check the user record
        case 'resource':
          const sharedUser = sharedProjData?.shared_users?.filter(user => {
            return user.user_id === userLocalData._id;
          });

          if (
            sharedUser &&
            sharedUser[0]?.inserted_resource_ids?.includes(id)
          ) {
            isNotTheOwner = false;
          } else {
            isNotTheOwner = true;
          }
          break;

        // validation of the user of knowledge nugget item
        case 'knowledgeNugget':
          switch (elementDataKn?.type) {
            // validating if the knowledge nugget is a web scraping or an academic paper
            case 'web scrapings':
            case 'Academic paper':
              if (elementDataKn?.user_id === userLocalData._id) {
                isNotTheOwner = false;
              } else {
                isNotTheOwner = true;
              }
              break;

            // validate if the knowledge nugget is an article added
            // here it needs to check the user record
            case 'Article':
              const sharedUser = sharedProjData?.shared_users?.filter(user => {
                return user.user_id === userLocalData._id;
              });

              if (
                sharedUser &&
                sharedUser[0]?.inserted_kNLibrary_ids?.includes(id)
              ) {
                isNotTheOwner = false;
              } else {
                isNotTheOwner = true;
              }
              break;
            default:
              isNotTheOwner = false;
          }
          break;

        default:
          isNotTheOwner = false;
      }
    } else {
      isNotTheOwner = false;
    }

    return isNotTheOwner;
  };

  return (
    <Menu bgColor="white" boxShadow="lg" colorScheme={'yellow'}>
      <MenuButton
        rounded="12px"
        p="5px"
        _active={{ border: '1px solid #ECC94B', bgColor: 'gray.200' }}
      >
        <HiOutlineDotsVertical />
      </MenuButton>

      <MenuList>
        {location.pathname === '/homepage' ? (
          <>
            <MenuItem
              p="0"
              _hover={{ bgColor: 'gray.100', rounded: 'md' }}
              onClick={() => {
                if (
                  (orgProjectData && orgProjectData.length > 0) ||
                  (orgsharedProjectData && orgsharedProjectData.length > 0)
                ) {
                  onAddToProjectOpen();
                } else {
                  onNoProjectsOpen();
                }
              }}
            >
              <Flex direction="row" align="center" pl="19px" pr="41px" my={2}>
                <AiOutlineFolderOpen style={{ color: 'gray.500' }} />
                <Text
                  fontWeight="normal"
                  color="gray.900"
                  fontFamily={'body'}
                  fontSize="16px"
                  ml={3}
                >
                  Add to Project
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              isDisabled={elementDataRes ? true : false}
              p="0"
              _hover={{ bgColor: 'gray.100', rounded: 'md' }}
              onClick={onEditOpen}
            >
              {' '}
              <Flex
                _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
                direction="row"
                align="center"
                pl="19px"
                pr="41px"
                my={2}
              >
                <BiPencil style={{ color: 'gray.500' }} />
                <Text
                  fontWeight="normal"
                  color="gray.900"
                  fontFamily={'body'}
                  fontSize="16px"
                  ml={3}
                >
                  Edit tags
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              p="0"
              _hover={{ bgColor: 'gray.100', rounded: 'md' }}
              onClick={onProjectSharingOpen}
              isDisabled={true}
            >
              {' '}
              <Flex
                _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
                direction="row"
                align="center"
                pl="19px"
                pr="41px"
                my={2}
              >
                <FiShare2 style={{ color: 'gray.500' }} />
                <Text
                  fontWeight="normal"
                  color="gray.900"
                  fontFamily={'body'}
                  fontSize="16px"
                  ml={3}
                >
                  Share{' '}
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              p="0"
              _hover={{ bgColor: 'gray.100', rounded: 'md' }}
              onClick={onDeleteOpen}
            >
              <Flex
                _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
                direction="row"
                align="center"
                pl="19px"
                pr="41px"
                my={2}
              >
                <RiDeleteBinLine style={{ color: 'red' }} />
                <Text
                  fontWeight="normal"
                  color="gray.900"
                  fontFamily={'body'}
                  fontSize="16px"
                  ml={3}
                >
                  Delete
                </Text>
              </Flex>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              p="0"
              _hover={{ bgColor: 'gray.100', rounded: 'md' }}
              isDisabled={isNotTheOwnerFunction()}
              onClick={onDeleteinsideProjectOpen}
            >
              <Flex direction="row" align="center" pl="19px" pr="41px" my={2}>
                <AiOutlineFolderOpen style={{ color: 'gray.500' }} />
                <Text
                  fontWeight="normal"
                  color="gray.900"
                  fontFamily={'body'}
                  fontSize="16px"
                  ml={3}
                >
                  Remove from Project
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              isDisabled={
                elementDataRes || isNotTheOwnerFunctionEditTags() ? true : false
              }
              p="0"
              _hover={{ bgColor: 'gray.100', rounded: 'md' }}
              onClick={onEditOpen}
            >
              {' '}
              <Flex
                _hover={{ bgColor: 'gray.100', rounded: 'lg' }}
                direction="row"
                align="center"
                pl="19px"
                pr="41px"
                my={2}
              >
                <BiPencil style={{ color: 'gray.500' }} />
                <Text
                  fontWeight="normal"
                  color="gray.900"
                  fontFamily={'body'}
                  fontSize="16px"
                  ml={3}
                >
                  Edit tags
                </Text>
              </Flex>
            </MenuItem>
          </>
        )}
      </MenuList>

      {onAddToProjectOpen && (
        <AddToProject
          type={type}
          id={id}
          update={updatehivedata}
          setUpdate={setUpdatehivedata}
          sharedProjectData={orgsharedProjectData}
          isOpen={isAddToProjectOpen}
          onClose={onAddToProjectClose}
        />
      )}
      {onEditOpen && (
        <EditTag
          isOpen={isEditOpen}
          onClose={onEditClose}
          itemID={id}
          update={updatehivedata}
          setUpdate={setUpdatehivedata}
          updateKnowledgeNuggetData={updateKnowledgeNuggetData}
          setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
          nonhiveData={elementDataNon ? elementDataNon : elementDataKn}
          resourceData={resourceData}
          nonsummaryhiveData={nonsummaryhiveData}
          knowledgeNuggetData={knowledgeNuggetData}
          isproject={isproject}
          type={type}
        />
      )}
      {onDeleteOpen && (
        <Delete
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          content={
            elementDataRes
              ? elementDataRes.resource_name
              : elementDataKn
              ? elementDataKn.knowledge_nugget_name
              : elementDataNon?.filename
          }
          onDelete={
            elementDataKn
              ? handleDeleteKN
              : elementDataRes
              ? handleDeleteResource
              : handleDeletenonsummery
          }
          isproject={isproject}
          type={type}
        />
      )}
      {onDeleteinsideProjectOpen && (
        <Delete
          isOpen={isDeleteinsideProjectOpen}
          onClose={onDeleteinsideProjectClose}
          content={
            elementDataRes
              ? elementDataRes.resource_name
              : elementDataKn
              ? elementDataKn.knowledge_nugget_name
              : elementDataNon?.filename
          }
          onDelete={handleremovefromproject}
          isproject={isproject}
        />
      )}
      {
        <NoProjects
          isOpen={isNoProjectsOpen}
          onClose={onNoProjectsClose}
          resourceData={resourceData}
          nonsummaryhiveData={nonsummaryhiveData}
          upateprojectData={upateprojectData}
          setUpdateprojectData={setUpdateprojectData}
        />
      }
      {onProjectSharingOpen && (
        <ProjectSharing
          isOpen={isProjectSharingOpen}
          onClose={onProjectSharingClose}
        />
      )}
    </Menu>
  );
}

export default MaterialMenu;
