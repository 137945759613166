import {
  Box,
  Center,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  theme,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import FolderIcon from '../../../assests/Icons/Project/Folder.png';
import FavouriteButton from '../../../assests/Icons/Project/Favourite Button.png';
import { useHistory } from 'react-router-dom';
import { differenceInDays } from 'date-fns';
import axios from 'axios';
import CreateProject from './ProjectComps/CreateProject';

function Projects({
  projectData,
  sharedProjectData,
  nonsummaryhiveData,
  resourceData,
  knowledgeNuggetData,
  knowledgeLibraryData,
  userInfo,
  orgProjectData,
  isSearch,
}) {
  const userLocalData = JSON.parse(localStorage.user);
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [showShared, setShowShared] = useState(false);
  const [sharedprojData, setSharedprojData] = useState([]);
  const history = useHistory();
  const filterValue = useBreakpointValue({
    base: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3,
  });
  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose,
  } = useDisclosure();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_EH_BACKEND}/shared-project-list/${userLocalData._id}/project`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.login_token,
          },
        }
      )
      .then(res => {
        setSharedprojData(res.data);
      });
  }, [userLocalData._id]);

  const gotoproject = async projectId => {
    const { REACT_APP_EH_BACKEND } = process.env;

    // to get the last access time of the resource send request to backend so it will give an dateproperty
    await axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/projects/access/${projectId}/${date.toLocaleDateString()}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(dateResponse => {
        setDate(dateResponse.data.accessdate);
      })
      .catch(err => {
        return err;
      });
    history.push(`/project/${projectId}`);
  };
  const gotosharedproject = async projectId => {
    history.push(`/project/${projectId}`);
  };
  // const projectsSliced = show
  //   ? projectData
  //   : projectData?.slice(0, filterValue);
  const sharedProjectsSliced = show
  ? sharedProjectData
  : sharedProjectData?.slice(0, filterValue);
  return (
    <ChakraProvider theme={theme}>
      <Box px="4">
        <Flex align="center" justify="space-between">
          {/* {projectData?.length > 0 ? ( */}
          <Text
            py="2"
            color="gray.600"
            fontFamily="Inter"
            fontWeight="400"
            fontSize={['16px', '16px', '20px']}
          >
            My Projects
          </Text>
          {/* ) : (
            ''
          )} */}

          {/* {projectData?.length > filterValue ? ( */}
          <Link onClick={() => setShow(!show)}>
            <Text
              color="purple.600"
              fontFamily={'body'}
              fontWeight="normal"
              fontSize="14px"
              textUnderlineOffset="0.5px"
            >
              see all
            </Text>
          </Link>
          {/* ) : (
            ''
          )} */}
        </Flex>
        {orgProjectData?.length > 0 ? (
          <>
            <Grid
              templateRows="repeat(, 1fr)"
              templateColumns={
                'repeat(' + (filterValue !== 1 ? filterValue + 1 : 1) + ', 1fr)'
              }
              gap={['2', '2', '8']}
            >
              <GridItem
                colSpan={
                  filterValue > orgProjectData?.length
                    ? orgProjectData?.length
                    : filterValue
                }
              >
                <SimpleGrid
                  columns={
                    filterValue > orgProjectData?.length
                      ? orgProjectData?.length
                      : [1, 2, 2, 3]
                  }
                  justifyContent="space-between"
                  spacingY="29px"
                  gap={['2', '2', '8']}
                >
                  {projectData
                    ?.slice(0, filterValue)
                    ?.map((project_data, index) => (
                      <Box
                        key={index}
                        onClick={() => gotoproject(project_data._id)}
                      >
                        <ProjectCard
                          IconAndcolor={FolderIcon}
                          projectName={project_data.project_name}
                          daysAgo={differenceInDays(
                            new Date(date),
                            new Date(project_data.accessdate)
                          )}
                          daysAdded={differenceInDays(
                            new Date(date),
                            new Date(project_data.created_at)
                          )}
                          projData={
                            sharedProjectData &&
                            sharedprojData?.find(
                              sharedProj =>
                                sharedProj?.shared_resource_id === project_data?._id
                            )
                          }
                        />
                      </Box>
                    ))}
                </SimpleGrid>
              </GridItem>
              {isSearch ? (
                ''
              ) : (
                <GridItem colSpan={1}>
                  <Box
                    w={['full', 'full', 'full']}
                    bg="white"
                    p="15px"
                    rounded="16px"
                    boxShadow="base"
                    cursor={'pointer'}
                    textAlign="center"
                    onClick={() => onCreateProjectOpen()}
                  >
                    <Center>
                      <Box
                        align="center"
                        textAlign="center"
                        alignItems="center"
                        // w="18%"
                        display="flex"
                      >
                        <Box>
                          <Image w="8" src={FavouriteButton} />
                        </Box>
                        <Spacer />
                        <Box pl="6" overflow="auto">
                          <Text
                            pb="1"
                            color="gray.700"
                            fontFamily="Inter"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="14px"
                            noOfLines={2}
                          >
                            Add new project
                          </Text>
                        </Box>
                      </Box>
                    </Center>
                  </Box>
                </GridItem>
              )}
            </Grid>
            <SimpleGrid
              display={show ? 'grid' : 'none'}
              pt={['2', '2', '8']}
              columns={[1, 3, 3, 4]}
              justifyContent="space-between"
              spacingY={['2', '2', '8']}
              gap={['2', '2', '8']}
            >
              {projectData?.slice(filterValue)?.map((project_data, index) => (
                <Box key={index} onClick={() => gotoproject(project_data._id)}>
                  <ProjectCard
                    IconAndcolor={FolderIcon}
                    projectName={project_data.project_name}
                    daysAgo={differenceInDays(
                      new Date(date),
                      new Date(project_data.accessdate)
                    )}
                    daysAdded={differenceInDays(
                      new Date(date),
                      new Date(project_data.created_at)
                    )}
                    projData={
                      sharedProjectData &&
                      sharedprojData?.find(
                        sharedProj =>
                          sharedProj?.shared_resource_id === project_data?._id
                      )
                    }
                  />
                </Box>
              ))}
            </SimpleGrid>
          </>
        ) : !isSearch ? (
          <Box
            w={['full', 'full', 'full']}
            bg="white"
            p="15px"
            rounded="16px"
            boxShadow="base"
            cursor={'pointer'}
            textAlign="center"
            onClick={() => onCreateProjectOpen()}
          >
            <Center>
              <Center textAlign="center" alignItems="center" display="flex">
                <Box>
                  <Image w="8" src={FavouriteButton} />
                </Box>

                <Box pl="3" mt="5px" overflow="auto">
                  <Text
                    pb="1"
                    color="gray.700"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="17px"
                    noOfLines={2}
                  >
                    Add new project
                  </Text>
                </Box>
              </Center>
            </Center>
          </Box>
        ) : (
          ''
        )}
        {sharedProjectData && (
          <>
            <Flex align="center" justify="space-between">
              <Text
                py="2"
                color="gray.600"
                fontFamily="Inter"
                fontWeight="400"
                fontSize={['16px', '16px', '20px']}
              >
                Shared projects
              </Text>
              <Link onClick={() => setShowShared(!showShared)}>
                <Text
                  color="purple.600"
                  fontFamily={'body'}
                  fontWeight="normal"
                  fontSize="14px"
                  textUnderlineOffset="0.5px"
                >
                  see all
                </Text>
              </Link>
            </Flex>
            <SimpleGrid
              columns={[1, 3, 3, 4]}
              justifyContent="space-between"
              spacingY="29px"
              gap={['2', '2', '8']}
            >
              {sharedProjectsSliced?.map((project_data, index) => (
                <Box
                  key={index}
                  onClick={() =>
                    gotosharedproject(project_data.shared_resource_id)
                  }
                >
                  <ProjectCard
                    IconAndcolor={FolderIcon}
                    projectName={project_data.shared_resource_name}
                    daysAgo={differenceInDays(
                      new Date(date),
                      new Date(
                        project_data?.shared_users?.find(
                          user => user.user_id === userLocalData._id
                        )?.created_at
                      )
                    )}
                    projData={project_data}
                  />
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
      {onCreateProjectOpen && (
        <CreateProject
          isOpen={isCreateProjectOpen}
          onClose={onCreateProjectClose}
          nonsummaryhiveData={nonsummaryhiveData}
          resourceData={resourceData}
          knowledgeNuggetData={knowledgeNuggetData}
          knowledgeLibraryData={knowledgeLibraryData}
          userInfo={userInfo}
          projectData={projectData}
        />
      )}
    </ChakraProvider>
  );
}

export default Projects;
