import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  Container,
  Stack,
  Heading,
  useBreakpointValue,
  useColorModeValue,
  HStack,
  Divider,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { Link as LinkDOM, useHistory, useLocation } from 'react-router-dom';

import EhLogo from '../Components/Logo/EhLogo';
import LoginForm from '../Components/FormControllers/LoginForm';
import GoogleLoginButton from '../Components/GoogleLogin/GoogleLogin';
import queryString from 'query-string';
import Iubenda from 'react-iubenda-policy';

function SignIn() {
  const [alert, setAlert] = useState('');
  const [alertType, setAlertType] = useState('');
  const [update, setUpdate] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const toast = useToast();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.updated === 'true') {
      alertHandler({
        status: 'success',
        message: 'Invitation success. Please login to continue.',
      });
      history.push('/');
    }
    if (parsed.updated === 'false') {
      alertHandler({
        status: 'error',
        message: 'Something went wrong, Please try again.',
      });
      history.push('/');
    }
    if (parsed.verify === 'true') {
      alertHandler({
        status: 'success',
        message: 'Your account has been verified. Please login to continue.',
      });
      history.push('/');
    }
    if (parsed.verify === 'false') {
      alertHandler({
        status: 'error',
        message: 'Something went wrong, Please try again.',
      });
      history.push('/');
    }
    // ToastMessager(alert, alertType);
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, history, location.search, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  return (
    <>
      <Box
        maxH="100%"
        h={'100vh'}
        bg="gray.100"
        textAlign="center"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        overflow={'auto'}
      >
        <Container
          maxW="lg"
          py={{ base: '0', sm: '2', md: '2', lg: '8' }}
          px={{ base: '0', sm: '8' }}
        >
          <Stack spacing={['0', '3', '8']} mx={2}>
            <Stack spacing={['0', '1', '3']}>
              <EhLogo />
              <Stack
                spacing={{ base: '0', sm: '2', md: '3' }}
                textAlign="center"
              >
                <Heading
                  size={useBreakpointValue({ base: 'sm', md: 'sm' })}
                  fontSize={{ base: '24px', md: '36px' }}
                  color="gray.700"
                  fontWeight="bold"
                  fontFamily={'heading'}
                >
                  Welcome back!
                </Heading>
                <Text
                  fontSize={['14px', '16px']}
                  color="gray.700"
                  fontWeight="normal"
                  fontFamily={'body'}
                >
                  Save your knowledge in bite size summaries
                </Text>
              </Stack>
            </Stack>
            <Box
              py={{
                base: '6',
                sm: '8',
              }}
              px={{
                base: '6',
                sm: '10',
              }}
              bg="white"
              boxShadow={{
                base: 'none',
                sm: useColorModeValue('md', 'md-dark'),
              }}
              borderRadius={{
                base: 'md',
                sm: 'xl',
              }}
            >
              <Stack spacing={[0, 2, 6]}>
                <Stack spacing={[0, 2, 5]}>
                  <LoginForm
                    update={update}
                    setUpdate={setUpdate}
                    onAlertHandle={alertHandler}
                  />
                </Stack>
                <Stack spacing={[0, 3, 6]}>
                  <HStack>
                    <Divider />
                    <Text fontSize="14px" whiteSpace="nowrap" color="gray.500">
                      or continue with
                    </Text>
                    <Divider />
                  </HStack>

                  <GoogleLoginButton
                    update={update}
                    setUpdate={setUpdate}
                    onAlertHandle={alertHandler}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <HStack mt={3} align="center" justify="center">
            <Text
              fontWeight="normal"
              color="gray.700"
              fontSize={['10px', '16px']}
            >
              Not an Elephant Hive user?{' '}
            </Text>
            <LinkDOM to="/signup">
              <Button variant="link" size="sm" color="black">
                <Text fontWeight="bold">Create account</Text>
              </Button>
            </LinkDOM>
          </HStack>
          {/* terms and conditions and cookie policy */}
          <Flex
            direction={['column', 'column', 'row']}
            align={'center'}
            justify="center"
            gap={4}
            my={2}
          >
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="privacy"
                styling="nostyle"
              >
                <Text
                  fontSize={['12px', '16px']}
                  color="gray.500"
                  fontWeight="bold"
                  fontFamily="body"
                >
                  Privacy Policy
                </Text>
              </Iubenda>
            </Button>
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="terms-and-conditions"
                styling="nostyle"
              >
                <Text
                  fontSize={['12px', '16px']}
                  color="gray.500"
                  fontWeight="bold"
                >
                  Terms and Conditions
                </Text>
              </Iubenda>
            </Button>
            <Button variant="link" size="sm">
              <Iubenda
                id={`${process.env.REACT_APP_EH_POLICY_ID}`}
                type="cookie"
                styling="nostyle"
              >
                <Text
                  fontSize={['12px', '16px']}
                  color="gray.500"
                  fontWeight="bold"
                  fontFamily="body"
                >
                  Cookie Policy
                </Text>
              </Iubenda>
            </Button>
          </Flex>
        </Container>
      </Box>
    </>
  );
}

export default SignIn;
