import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryList from '../SummaryList';
import SummaryPlayer from '../SummaryPlayer';
import MindMap from '../KnowledgeNuggetMindMap/MindMap';

function ListNMapTab({
  lesson,
  editStateVal,
  editCompleteVal,
  editTagModalXVal,
  editTagModalYVal,
  pauseStateVal,
  mdLeftVal,
  lgLeftVal,
  refresh,
  setRefresh,
  isOk,
  path,
  getAudio,
  onChange,
  percentage,
  audioRef,
  play,
  getCurrDuration,
  duration,
  setDuration,
  position,
  setPosition,
  currentTime,
  isPlaying2,
  isListenClicked,
  setIsListenClicked,
}) {
  const lessonData = lesson;
  const editState = editStateVal;
  const editTagModalX = editTagModalXVal;
  const editTagModalY = editTagModalYVal;
  const pauseState = pauseStateVal;
  const editComplete = editCompleteVal;

  return (
    <ChakraProvider theme={theme}>
      <SummaryList
        editStateVal={editState}
        refresh={refresh}
        setRefresh={setRefresh}
        bgw={{
          /*base: '100%', sm: '320px',*/ base: '100%',
          // md: '298px',
          md: '463px',
        }}
      />
      <SummaryPlayer
        w={{
          /*base: '100%', sm: '320px',*/ base: '100%',
          // md: '298px',
          md: '463px',
        }}
        isOk={isOk}
        path={path}
        getAudio={getAudio}
        onChange={onChange}
        percentage={percentage}
        audioRef={audioRef}
        play={play}
        getCurrDuration={getCurrDuration}
        duration={duration}
        setDuration={setDuration}
        position={position}
        setPosition={setPosition}
        currentTime={currentTime}
        isPlaying2={isPlaying2}
        isListenClicked={isListenClicked}
        setIsListenClicked={setIsListenClicked}
      />
      <MindMap
        mdLeftVal={mdLeftVal}
        lgLeftVal={lgLeftVal}
        editStateVal={editState}
        editCompleteVal={editComplete}
        lesson={lessonData}
        editTagModalXVal={editTagModalX}
        editTagModalYVal={editTagModalY}
        pauseStateVal={pauseState}
      />
    </ChakraProvider>
  );
}
export default ListNMapTab;
