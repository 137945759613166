import {
  Box,
  Text,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  VStack,
  Spacer,
} from '@chakra-ui/react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import React, { useRef } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { login, user } from '../../utils';
import gtag from 'ga-gtag';

function LoginForm(props) {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const history = useHistory();
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;

  const onClickReveal = () => {
    onToggle();

    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  };
  const initialValues = {
    email: '',
    password: '',
  };
  const validationSchema = yup.object({
    email: yup.string().email('invalid Email Address').required(),
    password: yup.string('invalid Password').required('Password is Required'),
  });
  const onSubmit = async (values, actions) => {
    await Axios.post(`${process.env.REACT_APP_EH_BACKEND}/auth/login`, values)
      .then(res => {
        if (res.data.status) {
          props.onAlertHandle({
            status: res.data.status,
            message: res.data.message,
          });
          actions.setSubmitting(false);

          props.setUpdate(!props.update);
        } else {
          actions.setSubmitting(false);
          actions.resetForm();
          const tokenKey = res.data.tokenData.token;
          const userData = res.data.user;

          if (res.status === 200) {
            gtag('config', REACT_APP_GA_MEASUREMENT_ID, {
              //  send_page_view: false ,
              user_id: userData._id,
            });
            gtag('event', 'login', {
              method: 'normal login',
              userid: `${userData._id}`,
              full_name: `${userData.firstName} ${userData.lastName}`,
              verified: `${userData.isVerified}`,
            });
            login(tokenKey);
            user(userData);
            history.push('/homepage');
          } else {
            gtag('event', 'exception', {
              process: `loging to account by ${userData.firstName} ${userData.lastName} with user id: "${userData._id}"`,
              method: 'normal login',
              description: 'error occured while loging in',
              fatal: false,
            });
            history.push('/');
          }
        }
      })
      .catch(err => {
        gtag('event', 'exception', {
          // process:`loging to account by ${userData.firstName} ${userData.lastName} with user id: "${userData._id}"`,
          method: 'normal login',
          description: err,
          fatal: false,
        });
        return err;
      });
  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <Form autoComplete="off">
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.errors.email && formik.touched.email}
              >
                <FormLabel htmlFor="email">
                  <Text
                    fontSize="sm"
                    fontWeight="normal"
                    color="gray.900"
                    fontFamily={'body'}
                  >
                    Email Address
                  </Text>
                </FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  _placeholder={{ fontFamily: 'body' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormErrorMessage>
                  <ErrorMessage name="email" />
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.errors.password && formik.touched.password}
              >
                <FormLabel htmlFor="password">
                  <Text
                    fontSize="sm"
                    fontWeight="normal"
                    color="gray.900"
                    fontFamily={'body'}
                  >
                    Password
                  </Text>
                </FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    ref={inputRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    placeholder="Enter Password"
                    _placeholder={{ fontFamily: 'body' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                      onClick={() => onClickReveal()}
                      variant="link"
                      icon={
                        isOpen ? (
                          <HiEye style={{ color: 'gray.500' }} />
                        ) : (
                          <HiEyeOff style={{ color: 'gray.500' }} />
                        )
                      }
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  <ErrorMessage name="pasword" />
                </FormErrorMessage>
              </FormControl>
              <Flex alignSelf="flex-end" justifyContent="flex-end">
                <Spacer />
                <Link to="/forgotpassword">
                  <Text
                    fontWeight="bold"
                    color="gray.700"
                    fontSize="12px"
                    fontFamily={'body'}
                  >
                    Forgot Password ?
                  </Text>
                </Link>
              </Flex>
              <Button
                type="submit"
                colorScheme="yellow"
                isLoading={formik.isSubmitting}
                isFullWidth
              >
                <Text
                  fontWeight="bold"
                  fontSize="18px"
                  color="gray.900"
                  fontFamily={'body'}
                >
                  Log in
                </Text>
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default LoginForm;
