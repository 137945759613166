import React from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import CytoscapeComponent from 'react-cytoscapejs';



function UserLevelKNGraph({
  isOpen,
  onClose,
  resourceData,
  nonsummaryhiveData,
  knowledgeNuggetData,
  knowledgeLibraryData,
}) {
  const [kNGraphResponse, setKNGraphResponse] = useState();
  const StyleSheet = [
    {
      selector: 'node',
      style: {
        label: 'data(label)',
        color: 'white',
        width: 35,
        height: 35,
        'text-valign': 'center',
        'font-size': '8px',
      },
    },
    {
      selector: 'edge',
      style: {
        width: 2,
        color: '#848484',
        label: 'data(label)',
        'curve-style': 'bezier',
        'line-color': '#848484',
        'font-size': '8px',
      },
    },
    {
      selector: ':selected',
      style: {
        'line-color': '#45BFF8',
        'border-width': 4,
        'border-style': 'solid',
        'border-color': '#45BFF8',
      },
    },
    {
      selector: '.addtohives',
      style: {
        'background-color': '#6ebced',
      },
    },
    {
      selector: '.knowledgenuggets',
      style: {
        'background-color': '#6dcf9e',
      },
    },
    {
      selector: '.resources',
      style: {
        'background-color': '#ff746d',
      },
    },
  ];
  const ULcy = useRef(null);
  const modalref = useRef(null);
  const ULcyPopperRef = useRef(null);

  const createContentFromComponent = component => {
    const dummyDomEle = document.createElement('div');
    dummyDomEle.innerHTML = component;
    dummyDomEle.style =
      'background-color: black;color: #fff;text-align: left; padding: 0 5px;border-radius: 6px';
    document.getElementById(modalref.current.id).appendChild(dummyDomEle);
    return dummyDomEle;
  };

  const setCytoscape = useCallback(
    ref => {
      ULcy.current = ref;
      ref.on('select', 'node', e => {
        let clickedNode = e.target;
        clickedNode
          .connectedEdges()
          .connectedNodes()
          .connectedEdges()
          .connectedNodes()
          .style({
            'border-width': 2,
            'border-style': 'solid',
            'border-color': '#C70039',
          });
        clickedNode
          .connectedEdges()
          .connectedNodes()
          .connectedEdges()
          .style({ 'line-color': '#E5791A', width: 3 });
        clickedNode.connectedEdges().connectedNodes().style({
          'border-width': 3,
          'border-style': 'solid',
          'border-color': '#900C3F',
        });
        clickedNode
          .connectedEdges()
          .style({ 'line-color': '#FF5733', width: 3 });
        clickedNode.style({
          'border-width': 5,
          'border-style': 'solid',
          'border-color': '#581845',
          // 'border-color': '#2b64d4',
        });
      });
      ref.on('unselect', 'node', e => {
        let clickedNode = e.target;
        clickedNode
          .connectedEdges()
          .connectedNodes()
          .connectedEdges()
          .connectedNodes()
          .style({
            'border-width': 0,
          });
        clickedNode
          .connectedEdges()
          .connectedNodes()
          .connectedEdges()
          .style({ 'line-color': '#848484', width: 2 });
        clickedNode.connectedEdges().connectedNodes().style({
          'border-width': 0,
        });
        clickedNode
          .connectedEdges()
          .style({ 'line-color': '#848484', width: 2 });
        clickedNode.style({
          'border-width': 0,
        });
      });
      ref.on('mouseover', 'node', e => {
        let node = e.target;
        let name = node.id();
        ULcyPopperRef.current = e.target.popper({
          content: () => createContentFromComponent(name),
          popper: {
            placement: 'top',
            removeOnDestroy: true,
          },
        });
      });
      ref.on('mouseout', 'node', () => {
        if (ULcyPopperRef) {
          document
            .getElementById(modalref.current.id)
            .removeChild(ULcyPopperRef.current.state.elements.popper);
          ULcyPopperRef.current.destroy();
        }
      });
    },
    [ULcy]
  );

  let NonSummeryNameList = [];
  let ResourseNameList = [];
  let KnowledgeNuggetNameList = [];
  let nonSummeryTagList = [];
  let resourceTagList = [];
  let knowledgeNuggetTagList = [];

  nonsummaryhiveData &&
    nonsummaryhiveData.map(item => NonSummeryNameList.push(item.filename));
  resourceData &&
    resourceData.map(item =>
      ResourseNameList.push(item.resource.resource_name)
    );
  knowledgeNuggetData &&
    knowledgeNuggetData.map(item =>
      KnowledgeNuggetNameList.push(item.knowledge_nugget_name)
    );
  knowledgeLibraryData &&
    knowledgeLibraryData.map(item =>
      KnowledgeNuggetNameList.push(item.knowledge_nugget_name)
    );
  nonsummaryhiveData &&
    nonsummaryhiveData.map(item => {
      let tagList = [];
      let itemName = item.filename;
      item.tags.map(tagItem => tagList.push(tagItem));
      nonSummeryTagList.push([itemName, tagList]);
      return null;
    });
  resourceData &&
    resourceData.map(item => {
      let tagList = [];
      let itemName = item.resource.resource_name;
      item.resource.tags.map(tagItem => tagList.push(tagItem.tag_name));
      resourceTagList.push([itemName, tagList]);
      return null;
    });
  knowledgeNuggetData &&
    knowledgeNuggetData.map(item => {
      let tagList = [];
      let itemName = item.knowledge_nugget_name;
      item.tags.map(tagItem => tagList.push(tagItem.tag_name));
      knowledgeNuggetTagList.push([itemName, tagList]);
      return null;
    });
  knowledgeLibraryData &&
    knowledgeLibraryData.map(item => {
      let tagList = [];
      let itemName = item.knowledge_nugget_name;
      item.tags.map(tagItem => tagList.push(tagItem.tag_name));
      knowledgeNuggetTagList.push([itemName, tagList]);
      return null;
    });

  let final_list_without_tags = [
    NonSummeryNameList,
    KnowledgeNuggetNameList,
    ResourseNameList,
  ];

  let final_list_with_tags = [
    nonSummeryTagList,
    knowledgeNuggetTagList,
    resourceTagList,
  ];

  const { REACT_APP_EH_BACKEND } = process.env;
  const generateGraph = async () => {
    try {
      await axios
        .post(`${REACT_APP_EH_BACKEND}/knowledge/graph`, {
          final_list_without_tags: final_list_without_tags,
          final_list_with_tags: final_list_with_tags,
        })
        .then(response => {
          setKNGraphResponse(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontFamily={'body'} fontSize="16px" fontWeight={'bold'}>
            Knowledge Graph
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody ref={modalref}>
          {kNGraphResponse ? (
            <CytoscapeComponent
              elements={kNGraphResponse}
              zoom={1.3}
              minZoom={0.7}
              pan={{ x: 0, y: 0 }}
              styleEnabled={true}
              textureOnViewport={true}
              hideEdgesOnViewport={true}
              style={{
                width: 'full',
                height: '600px',
                backgroundColor: '#f5f7fa',
              }}
              stylesheet={StyleSheet}
              cy={setCytoscape}
            />
          ) : (
            <Flex align={'center'} justify="center">
              <Button
                fontFamily={'body'}
                fontWeight="bold"
                fontSize={'16px'}
                onClick={generateGraph}
                disabled={
                  final_list_without_tags && final_list_with_tags ? false : true
                }
              >
                Generate Graph
              </Button>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UserLevelKNGraph;
