import {
  Badge,
  Box,
  Center,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ProjectCard({ IconAndcolor, projectName, daysAgo, daysAdded, projData }) {
  let userLocalData = JSON.parse(localStorage.user);
  const [NonsumCount, setNonsumCount] = useState(0);
  const [KNCount, setKNCount] = useState(0);
  const [resCount, setResCount] = useState(0);

  useEffect(() => {
    projData &&
      axios
        .get(
          `${process.env.REACT_APP_EH_BACKEND}/getprojects/${userLocalData._id}/${projData.shared_resource_id}`,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.login_token,
            },
          }
        )
        .then(response => {
          setNonsumCount(response.data[0].selected_nonsummary_ids.length);
          setKNCount(response.data[0].selected_knowledeNuggets_ids.length);
          setResCount(response.data[0].selected_resourse_ids.length);
        });
  }, [projData, userLocalData._id]);

  return (
    <Flex
      w={['full', 'full', 'full']}
      bg="purple.100"
      p="2"
      rounded="16px"
      boxShadow="base"
      cursor={'pointer'}
    >
      <Center
        float="left"
        p="8px"
        h="8"
        w="8"
        bg="#ffffff"
        align="center"
        rounded="12px"
        fontSize="16px"
        shadow="base"
      >
        {/* Variable icon and color */}

          <Image src={IconAndcolor} alt="Project Icon" />
        </Center>
        <Box pl="6" overflow="auto">
          <Text
            pb="1"
            color="black"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="700"
            fontSize="14px"
            noOfLines={2}
          >
            {projectName}
          </Text>
          <Text
            color="gray.700"
            fontFamily="Inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            noOfLines={2}
          >
            {daysAgo === 0 && daysAdded === 0 ? 'Added Today' : daysAgo === 0 ? 'Viewed Today' : `viewed ${daysAgo} days ago`}
          </Text>
        </Box>
      
      {projData && (
        <Tooltip
          hasArrow
          label="Number of items in Project. Please note items may have been added or removed since your last visit"
          bg="purple.100"
          color="gray.800"
          placement="top"
        >
          <Badge
            cursor={'pointer'}
            bg={'purple.700'}
            color={'white'}
            fontSize="16px"
            fontWeight={'bold'}
            rounded="full"
            px={3}
            py={1}
            w={8}
            h={8}
            mt={'-5'}
            
          >
            {NonsumCount + KNCount + resCount}
          </Badge>
        </Tooltip>
      )}
    </Flex>
  );
}

export default ProjectCard;
