import {
  Box,
  ChakraProvider,
  // Divider,
  Flex,
  Select,
  Spacer,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Resources from './Resources';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

const ResourceFilter = ({
  isSearch,
  knowledgeNuggetData,
  knowledgeLibraryData,
  resourceData,
  nonsummaryhiveData,
  orgProjectData,
  projectData,
  orgsharedProjectData,
  sharedProjectData,
  upateprojectData,
  setUpdateprojectData,
  updatehivedata,
  setUpdatehivedata,
  updateKnowledgeNuggetData,
  setUpdateKnowledgeNuggetData,
  userInfo,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterNonsummerData, setFilterNonsummerData] = useState([]);
  const [filterProjectData, setFilterProjectData] = useState([]);
  const [filterSharedProjectData, setFilterSharedProjectData] = useState([]);
  const [filterKnowledgeNuggetData, setFilterKnowledgeNuggetData] = useState(
    []
  );
  const [filterKnowledgeLibraryData, setFilterKnowledgeLibraryData] = useState(
    []
  );
  const [filter, setFilter] = useState('Default');

  let resourceDuplicate = [];
  let nonsummerDuplicate = [];
  let projectDuplicate = [];
  let sharedprojectDuplicate = [];
  let knowledgeNuggetDuplicate = [];
  let knowledgeLibraryDuplicates = [];

  function sortByName(a, b) {
    // for resource data
    if (
      a?.resource?.resource_name.toLowerCase() <
      b?.resource?.resource_name.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.resource?.resource_name.toLowerCase() >
      b?.resource?.resource_name.toLowerCase()
    ) {
      return 1;
    }
    // for nonsummery data
    if (a?.filename?.toLowerCase() < b?.filename?.toLowerCase()) {
      return -1;
    }
    if (a?.filename?.toLowerCase() > b?.filename?.toLowerCase()) {
      return 1;
    }
    // for project data
    if (a?.project_name?.toLowerCase() < b?.project_name?.toLowerCase()) {
      return -1;
    }
    if (a?.project_name?.toLowerCase() > b?.project_name?.toLowerCase()) {
      return 1;
    }
    // for shared project data
    if (
      a?.shared_resource_name?.toLowerCase() <
      b?.shared_resource_name?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.shared_resource_name?.toLowerCase() >
      b?.shared_resource_name?.toLowerCase()
    ) {
      return 1;
    }
    // for knowledge nugget data and knowledge filter Data
    if (
      a?.knowledge_nugget_name?.toLowerCase() <
      b?.knowledge_nugget_name?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a?.knowledge_nugget_name?.toLowerCase() >
      b?.knowledge_nugget_name?.toLowerCase()
    ) {
      return 1;
    }
    // else returen 0 for defaults
    return 0;
  }

  function sortByRatings(a, b) {
    let ratingA = 0;
    let ratingB = 0;
    a.resource.rating.map(review => {
      ratingA += review.rating;

      return null;
    });
    b.resource.rating.map(review => {
      ratingB += review.rating;

      return null;
    });
    ratingA /= a.resource.rating.length;
    ratingB /= b.resource.rating.length;

    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  }

  // function sortByTest(a, b) {
  //   if (
  //     a?.resource?.resource_name.toLowerCase() <
  //     b?.resource?.resource_name.toLowerCase()
  //   ) {
  //     return 1;
  //   }
  //   if (
  //     a?.resource?.resource_name.toLowerCase() >
  //     b?.resource?.resource_name.toLowerCase()
  //   ) {
  //     return -1;
  //   }
  //   // for nonsummery data
  //   if (a?.filename?.toLowerCase() < b?.filename?.toLowerCase()) {
  //     return 1;
  //   }
  //   if (a?.filename?.toLowerCase() > b?.filename?.toLowerCase()) {
  //     return -1;
  //   }
  //   // for project data
  //   if (a?.project_name?.toLowerCase() < b?.project_name?.toLowerCase()) {
  //     return 1;
  //   }
  //   if (a?.project_name?.toLowerCase() > b?.project_name?.toLowerCase()) {
  //     return -1;
  //   }
  //   // for knowledge nugget data and knowledge filter Data
  //   if (
  //     a?.knowledge_nugget_name?.toLowerCase() <
  //     b?.knowledge_nugget_name?.toLowerCase()
  //   ) {
  //     return 1;
  //   }
  //   if (
  //     a?.knowledge_nugget_name?.toLowerCase() >
  //     b?.knowledge_nugget_name?.toLowerCase()
  //   ) {
  //     return -1;
  //   }
  //   // else returen 0 for defaults
  //   return 0;
  // }
  function sortByDate(a, b) {
    if (a?.resource && b?.resource) {
      return differenceInMilliseconds(
        new Date(
          userInfo?.user_RES_Lib.find(
            KNelement => KNelement.id === b.resource._id
          )?.created_at
        ),
        new Date(
          userInfo?.user_RES_Lib.find(
            KNelement => KNelement.id === a.resource._id
          )?.created_at
        )
      );
    } else if (a?.filename && b?.filename) {
      return differenceInMilliseconds(
        new Date(b.created_at),
        new Date(a.created_at)
      );
    }
    // else if (
    //   a?.knowledge_nugget_name &&
    //   a?.user_id &&
    //   b?.knowledge_nugget_name &&
    //   b?.user_id
    // ) {
    // }
    else if (a?.knowledge_nugget_name && b?.knowledge_nugget_name) {
      return differenceInMilliseconds(
        new Date(
          userInfo?.user_KN_Lib?.find(
            KNelement => KNelement.id === b._id
          )?.created_at
        ),
        new Date(
          userInfo?.user_KN_Lib?.find(
            KNelement => KNelement.id === a._id
          )?.created_at
        )
      );
    }
  }

  return (
    <ChakraProvider theme={theme}>
      {resourceData?.map(res => {
        resourceDuplicate.push(res);

        return null;
      })}
      {nonsummaryhiveData?.map(res => {
        nonsummerDuplicate.push(res);

        return null;
      })}
      {projectData?.map(res => {
        projectDuplicate.push(res);

        return null;
      })}
      {sharedProjectData?.map(res => {
        sharedprojectDuplicate.push(res);

        return null;
      })}
      {knowledgeNuggetData?.map(res => {
        knowledgeNuggetDuplicate.push(res);

        return null;
      })}
      {knowledgeLibraryData?.map(res => {
        knowledgeLibraryDuplicates.push(res);
        return null;
      })}

      <Flex
        /*bg="#f0f4f8"*/ px="6"
        pt="2"
        mt="33px"
        borderTop="1px solid #E2E8F0"
      >
        <Spacer />
        <Box>
          <Stack isInline justify="space-between">
            <Box>
              <Text
                py="6px"
                px="4"
                color="gray.500"
                fontFamily="Inter"
                fontWeight="500"
                fontSize="16px"
                line-height="24px"
              >
                Sort by
              </Text>
            </Box>

            <Select
              onChange={event => {
                setFilter(event.target.value);
                switch (event.target.value) {
                  case 'Name':
                    resourceDuplicate?.sort(sortByName);
                    nonsummerDuplicate?.sort(sortByName);
                    projectDuplicate?.sort(sortByName);
                    sharedprojectDuplicate?.sort(sortByName);
                    knowledgeNuggetDuplicate?.sort(sortByName);
                    knowledgeLibraryDuplicates?.sort(sortByName);

                    break;
                  case 'Newest':
                    // resourceDuplicate?.sort(sortByTest);
                    // nonsummerDuplicate?.sort(sortByTest);
                    // projectDuplicate?.sort(sortByTest);
                    // knowledgeNuggetDuplicate?.sort(sortByTest);
                    // knowledgeLibraryDuplicates?.sort(sortByTest);
                    resourceDuplicate?.sort(sortByDate);
                    nonsummerDuplicate?.sort(sortByDate);
                    projectDuplicate?.sort(sortByDate);
                    knowledgeNuggetDuplicate?.sort(sortByDate);
                    knowledgeLibraryDuplicates?.sort(sortByDate);

                    break;

                  case 'Ratings':
                    resourceDuplicate?.sort(sortByRatings);

                    break;

                  default:
                    return (
                      resourceData,
                      nonsummaryhiveData,
                      projectData,
                      sharedProjectData,
                      knowledgeNuggetData
                    );
                }

                setFilteredData(resourceDuplicate);
                setFilterNonsummerData(nonsummerDuplicate);
                setFilterProjectData(projectDuplicate);
                setFilterSharedProjectData(sharedprojectDuplicate);
                setFilterKnowledgeNuggetData(knowledgeNuggetDuplicate);
                setFilterKnowledgeLibraryData(knowledgeLibraryDuplicates);
              }}
              w="100.38px"
              color="gray.700"
              border="1px solid #E2E8F0"
              box-sizing="border-box"
              rounded="8px"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="14px"
            >
              <option value="Newest">Newest</option>
              <option value="Name">Name</option>
              {/* <option value="Ratings">Ratings</option> */}
            </Select>
          </Stack>
        </Box>
      </Flex>
      {filter === 'Default' ? (
        <Box>
          <Resources
            projectData={projectData}
            orgProjectData={orgProjectData}
            sharedProjectData={sharedProjectData}
            orgsharedProjectData={orgsharedProjectData}
            resourceData={resourceData?.sort(sortByDate)}
            knowledgeNuggetData={knowledgeNuggetData?.sort(sortByDate)}
            knowledgeLibraryData={knowledgeLibraryData?.sort(sortByDate)}
            nonsummaryhiveData={nonsummaryhiveData?.sort(sortByDate)}
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            upateprojectData={upateprojectData}
            setUpdateprojectData={setUpdateprojectData}
            updateKnowledgeNuggetData={updateKnowledgeNuggetData}
            setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
            userInfo={userInfo}
          />
        </Box>
      ) : (
        <Box>
          <Resources
            orgProjectData={orgProjectData}
            projectData={filterProjectData}
            orgsharedProjectData={orgsharedProjectData}
            sharedProjectData={filterSharedProjectData}
            resourceData={filteredData}
            knowledgeNuggetData={filterKnowledgeNuggetData}
            knowledgeLibraryData={filterKnowledgeLibraryData}
            nonsummaryhiveData={filterNonsummerData}
            updatehivedata={updatehivedata}
            setUpdatehivedata={setUpdatehivedata}
            upateprojectData={upateprojectData}
            setUpdateprojectData={setUpdateprojectData}
            updateKnowledgeNuggetData={updateKnowledgeNuggetData}
            setUpdateKnowledgeNuggetData={setUpdateKnowledgeNuggetData}
            userInfo={userInfo}
          />
        </Box>
      )}
    </ChakraProvider>
  );
};

export default ResourceFilter;
