import {
  Tab,
  Box,
  Flex,
  Spacer,
  Text,
  Tabs,
  Badge,
  Button,
  TabList,
  TabPanels,
  useDisclosure,
  ChakraProvider,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  TabPanel,
  Grid,
  GridItem,
  FormErrorMessage,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import { ImFileText2 } from 'react-icons/im';
import { CgListTree } from 'react-icons/cg';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { ImAttachment } from 'react-icons/im';
import { BiNote } from 'react-icons/bi';
import { FiDownload, FiExternalLink } from 'react-icons/fi';
import { BiEditAlt } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, ErrorMessage, Form } from 'formik';
import UploadFiles from '../HeaderComps/KNSummaryUpContainer/UploadFiles';
import Notification from '../HeaderComps/Notification';
import AddNotesDrawer from '../HeaderComps/AddNotesDrawer';
import extendTheme from '../../../extendTheme';
import ListTab from './ListTab';
import ListNMapTab from './ListNMapTab';
import MapTab from './MapTab';
import axios from 'axios';
import RatingModel from './Ratings/RatingModel';
import UploadedDrawerWithFiles from '../HeaderComps/UploadedDrawerWithFiles';
import gtag from 'ga-gtag';
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi';

import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
} from 'docx';
import { saveAs } from 'file-saver';
import Delete from '../HeaderComps/Delete';
import InputTagRecurrent from '../../Homepage/Components/HiveUploadContainer/InputTagRecurrent';
import { AiFillStar, AiOutlineArrowLeft, AiOutlineCheck } from 'react-icons/ai';
import { TbGridDots } from 'react-icons/tb';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { GrAttachment } from 'react-icons/gr';

const TabController = ({
  lesson,
  refresh,
  setRefresh,
  removeAudio,
  isOk,
  setIsOk,
  path,
  setPath,
}) => {
  const location = useLocation();
  let lessonData = lesson;
  const { REACT_APP_EH_BACKEND } = process.env;
  const knowledgeNuggetId = new URLSearchParams(location.search).get(
    'knowledge_nugget_id'
  );
  const [leftVal, setLeft] = useState('480px');
  const [rightVal, setRight] = useState('');
  const [editState, setEditState] = useState(false);
  const [academicPaperEdit, setAcademicPapperEdit] = useState(false);
  const [academicPaperEditState, setAcademicPaperEditState] = useState(false);
  const [editComplete, setEditComplete] = useState('editNotComplete');
  const [editTagModalX, setEditTagModalX] = useState('500');
  const [editTagModalY, setEditTagModalY] = useState('450');
  const [mdLeftValZoom, setMdLeftZoom] = useState('390px');
  const [lgLeftValZoom, setLgLeftZoom] = useState('548px');
  const [tagData, setTagData] = useState();
  const [suggestionTag, setSuggestionTag] = useState();
  const [fileName, setFileName] = useState('');
  const [metaData, setMetaData] = useState('');
  const [numOfUploadedFiles, setNumOfUploadedFiles] = useState(0);
  const [haveUploadedFiles, setHaveUploadedFiles] = useState(false);
  const [pauseState, setPauseState] = useState('');
  // const [ratingMdLeft, setRatingMdLeft] = useState('320px');
  const [ratingLgLeft, setRatingLgLeft] = useState('480px');
  // const [ratingMdTop, setRatingMdTop] = useState('85vh');
  const [ratingLgTop, setRatingLgtop] = useState('85vh');
  const [editDisplay, setEditDisplay] = useState(true);
  const [knuggetRating, setKnuggetRating] = useState(0);
  const [numberOfNotes, setNumberOfNotes] = useState(0);
  const [tempTagListnew, setTempTagListnew] = useState([]);
  const [userInfo, setUserInfo] = useState();
  // const [isOk, setIsOk] = useState(false);
  const [isListenClicked, setIsListenClicked] = useState(false);
  // const [path, setPath] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [position, setPosition] = useState(0);
  const audioRef = useRef();
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
  let userLocalData = JSON.parse(localStorage.user);
  const userId = userLocalData._id;
  const cancelRef = useRef();
  let topic = '';
  let childNodeArr = [];
  let miniChildNodeArr = [];
  let summaryListArr = [];
  let filteredSummaryList = [];
  let contentAray = [];
  const stickyWords = [
    'the',
    'there',
    'by',
    'so',
    'have',
    'has',
    'can',
    'you',
    'your',
    'at',
    'and',
    'as',
    'an',
    'with',
    'we',
    'us',
    'a',
    'so',
    'if',
    'than',
    'but',
    'about',
    'their',
    'in',
    'on',
    'the',
    'was',
    'for',
    'that',
    'said',
    'a',
    'or',
    'of',
    'to',
    'there',
    'will',
    'be',
    'what',
    'get',
    'go',
    'think',
    'just',
    'every',
    'are',
    'it',
    'were',
    'had',
    'i',
    'very',
  ];
  let KNObject =
    userInfo && userInfo?.user_KN_Lib?.find(kn => kn.id === knowledgeNuggetId);

  // push all content of Knowledge Nugget in to the array
  lessonData.map(lessonn_content =>
    lessonn_content.module.lesson.map(lesson_element =>
      lesson_element.initialNodes.map(
        node_data =>
          node_data.childNodeName === undefined &&
          contentAray.push(node_data.data.label)
      )
    )
  );

  // concat all content of Knowledge Nugget in to one string
  // then split the string into array of words
  let stringList = contentAray.join('');
  let wordList = stringList.split(/\b/);

  // remove white space from the array and special characters
  for (let i = 0; i < wordList.length; i++) {
    wordList[i] = wordList[i].toLocaleLowerCase();
    wordList[i] = wordList[i].replace(/[^a-zA-Z]+/g, '');
    wordList[i] = wordList[i].trim();
    wordList[i] = wordList[i].replace(/\b\w{1,2}\b[ \t]*/gs, '');
  }

  // remove all the words that are in the stickyWords array
  // and remove all the empty strings
  const result = wordList.filter(word => {
    return word !== '';
  });
  const filteredResult = result.filter(word => {
    return !stickyWords.includes(word);
  });

  // count the number of times each word appears in the array
  var wordcount = {};
  for (var i = 0; i < filteredResult.length; i++) {
    wordcount[filteredResult[i]] = (wordcount[filteredResult[i]] || 0) + 1;
  }

  // sort the array in descending order
  // and take the top 6 words
  const sortable = Object.keys(wordcount).sort(function (a, b) {
    return wordcount[b] - wordcount[a];
  });
  let slicedSuggestionTags = sortable.slice(0, 6);

  const suggestionList = KNObject ? tempTagListnew : slicedSuggestionTags;

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const headers = {
    headers: {
      Authorization: 'Bearer ' + localStorage.login_token,
    },
  };

  useEffect(() => {
    let tempTagList = [];
    lessonData.map(lesson_data =>
      lesson_data.tags.map(tagData => tempTagList.push(tagData.tag_name))
    );
    setTempTagListnew(tempTagList);
  }, [lessonData, refresh]);

  // get tags
  useEffect(() => {
    let fileNameVal = '';
    let metaDataVal = '';
    let tagArray = [];

    if (KNObject) {
      KNObject?.tags?.map(tag => tagArray.push(tag.tag_name));

      lessonData?.map(lesson_data => {
        fileNameVal = lesson_data.file_name;
        metaDataVal = lesson_data.hasOwnProperty('metadata')
          ? lesson_data.metadata
          : '';
        return null;
      });
    } else {
      lessonData?.map(lesson_data => {
        fileNameVal = lesson_data.file_name;
        metaDataVal = lesson_data.hasOwnProperty('metadata')
          ? lesson_data.metadata
          : '';

        (lesson_data.type === 'web scrapings' ||
          lesson_data.type === 'Academic paper') &&
          lesson_data.tags.map(tag_data => {
            tagArray.push(tag_data.tag_name);

            return null;
          });
        return null;
      });
    }
    setFileName(fileNameVal);
    setTagData(tagArray);
    setMetaData(metaDataVal);
  }, [KNObject, lessonData, refresh]);

  const exportRef = useRef();
  let history = useHistory();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isAddNotesOpen,
    onOpen: onAddNotesOpen,
    onClose: onAddNotesClose,
  } = useDisclosure();
  const {
    isOpen: isBottomDrawerOpen,
    onOpen: onBottomDrawerOpen,
    onClose: onBottomDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isRatingOpen,
    onOpen: onRatingOpen,
    onClose: onRatingClose,
  } = useDisclosure();

  // back button process
  const goToPrevious = () => {
    history.push(`/homepage`);
  };

  // edit button click
  const onEditClick = () => {
    setEditState(true);

    if (academicPaperEdit) {
      setAcademicPaperEditState(true);
    } else {
      setAcademicPaperEditState(false);
    }
  };

  const initialValues = {
    tags: [],
  };

  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    let totalNumOfUploadedFiles = 0;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?_id=${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(knowledgeNuggetResponse => {
        gtag('config', REACT_APP_GA_MEASUREMENT_ID);

        knowledgeNuggetResponse.data?.map(nugget_data => {
          if (nugget_data.knowledgeNugget._id === knowledgeNuggetId) {
            if (nugget_data.knowledgeNugget.type === 'web scrapings') {
              setAcademicPapperEdit(true);
            }

            nugget_data.knowledgeNugget.uploadFiles?.map(file_data => {
              if (file_data.user_id === userId) {
                totalNumOfUploadedFiles += 1;
                setHaveUploadedFiles(true);
              }
              return null;
            });
          }
          return null;
        });

        setNumOfUploadedFiles(totalNumOfUploadedFiles);

        if (
          knowledgeNuggetResponse.data[0].knowledgeNugget.type === 'Article'
        ) {
          gtag('event', 'access_library_article', {
            article_id: `${knowledgeNuggetResponse.data[0].knowledgeNugget._id}`,
            article_name: `${knowledgeNuggetResponse.data[0].knowledgeNugget.file_name}`,
            article_doi: `${knowledgeNuggetResponse.data[0].knowledgeNugget.metadata.doi}`,
            userid: `${userLocalData._id}`,
            full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
            verified: `${userLocalData.isVerified}`,
          });
        } else {
          gtag('event', 'access_knowledge_nugget', {
            knowledge_nugget_id: `${knowledgeNuggetResponse.data[0].knowledgeNugget._id}`,
            knowledge_nugget_name: `${knowledgeNuggetResponse.data[0].knowledgeNugget.file_name}`,
            knowledge_nugget_doi: `${knowledgeNuggetResponse.data[0].knowledgeNugget.metadata.doi}`,
            userid: `${userLocalData._id}`,
            full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
            verified: `${userLocalData.isVerified}`,
          });
        }
      })
      .catch(loginError => {
        gtag('event', 'exception', {
          process: `accessing the nugget "${lesson[0].fileName}" of Id: "${lesson[0].module._id}" by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
          description: loginError,
          fatal: false,
        });
        return loginError;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeNuggetId, userId, refresh]);

  // submit the save changes on edit
  const onSubmit = async (values, actions) => {
    const submitTagArr = [];

    values.tags.map(tag_data => {
      submitTagArr.push({
        tag_name: tag_data,
      });

      return null;
    });

    axios({
      method: 'PUT',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/tags/${knowledgeNuggetId}/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
      data: {
        tags: submitTagArr,
      },
    })
      .then(putKnowledgeNuggetResponse => {
        if (putKnowledgeNuggetResponse.status === 200) {
          setRefresh(!refresh);
          actions.setsubmitting(false);
          actions.resetform();
          setSuggestionTag(null);
        }
      })
      .catch(loginError => {
        return loginError;
      });

    setRefresh(!refresh);
    setEditState(false);
    setAcademicPaperEditState(false);
    setEditComplete('editComplete');
  };

  // delete function when confirm delete button is clicked
  const handleDeleteKN = async () => {
    if (removeAudio !== undefined) {
      removeAudio();
    }
    await axios({
      method: 'DELETE',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/${userLocalData._id}/${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(responseData => {
        if (responseData.status === 200) {
          axios
            .delete(
              `${REACT_APP_EH_BACKEND}/deleteKNdataproj/${userLocalData._id}/${knowledgeNuggetId}`,
              headers
            )
            .then(responseData => {
              if (responseData.status === 200) {
                return 'deleted';
              }
            })
            .catch(error => {
              return error;
            });
          if (responseData.data.status) {
            history.push(`/homepage`);
          }
        }
      })
      .catch(error => {
        return error;
      });
  };

  // prevent default
  const handlethis = e => {
    e.preventDefault();
  };

  // get info relevent user
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/auth/${userLocalData._id}/info`,
    })
      .then(userResponse => {
        setUserInfo(userResponse.data);
      })
      .catch(error => {
        return error;
      });
  }, [userLocalData._id, refresh]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets?_id=${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(response => {
        setKnuggetRating(
          response.data[0].knowledgeNugget.ratings.find(
            element => element.user_id === userLocalData._id
          ).rating
        );
      })
      .catch(error => {
        return error;
      });
  }, [
    knuggetRating,
    refresh,
    knowledgeNuggetId,
    REACT_APP_EH_BACKEND,
    userLocalData._id,
  ]);

  // const handleOnRating = rating => {
  //   setKnuggetRating(rating);
  // };

  useEffect(() => {
    lessonData?.map(lesson_data => {
      setNumberOfNotes(
        lesson_data?.notes?.filter(note => note.userId === userLocalData._id)
          ?.length
      );

      return null;
    });
  }, [lessonData, userLocalData._id]);

  const handleOnNoteChange = noteLength => {
    setNumberOfNotes(noteLength);
  };

  // pdf download
  const exportPDF = async value => {
    let childNodeArr = [];
    let miniChildNodeArr = [];
    let summaryListArray = [];
    let summaryListArraynew = [];
    let Title = value[0].metadata.title;
    let Authors = value[0].metadata.authors;
    let lessons = value[0].module.lesson[0].initialNodes;
    // divide content based on nodes
    lessons.map(lesson_elem => {
      if (lesson_elem.className !== 'main-node') {
        if (lesson_elem.className === 'child-node') {
          childNodeArr.push(lesson_elem);
        } else {
          miniChildNodeArr.push(lesson_elem);
        }
      }
      return null;
    });
    // category based on divided
    childNodeArr.map(child_node_elem => {
      miniChildNodeArr.map(mini_child_node_elem => {
        if (
          child_node_elem.childNodeName === mini_child_node_elem.parentNodeName
        ) {
          summaryListArray.push({
            sub_module_topic: child_node_elem?.data?.label,
            sub_module_data: mini_child_node_elem?.data?.label?.replace(
              '\t',
              ''
            ),
          });
        }
        return null;
      });
      return null;
    });
    // seperate topics from the list of objects
    const topicList = new Set(
      summaryListArray.map(topic_data => topic_data.sub_module_topic)
    );
    for (const item of topicList) {
      // filter out data for each topic
      let topic_data = summaryListArray.filter(
        topic_data => topic_data.sub_module_topic === item
      );
      let topic_data_array = [];
      // based on filtered topic, get the relevent sub module data to an array
      topic_data.map(topic_data_elem => {
        topic_data_array.push(topic_data_elem.sub_module_data);
        return null;
      });
      // concat element in a array to a single string joined by gien condition
      let topic_data_string = topic_data_array.join('<join>');
      // push the topic and the data to a new array as objects
      summaryListArraynew.push({
        sub_module_topic: item,
        sub_module_data: topic_data_string,
      });
    }

    // create doc
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: Title,
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              text: Authors,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.START,
              children: [
                new TextRun({
                  break: 1,
                }),
              ],
            }),
            ...summaryListArraynew
              .map(lesson_data => {
                const arr = [];
                // since the data for each topic is now a single string
                // we first break them into individual lines
                const splitedText = lesson_data.sub_module_data.split('<join>');

                arr.push(
                  new Paragraph({
                    text: lesson_data.sub_module_topic,
                    heading: HeadingLevel.HEADING_2,
                    alignment: AlignmentType.START,
                    thematicBreak: true,
                  })
                );
                // based on the splited lines,
                // we create a paragraph for each line
                splitedText.map(text => {
                  arr.push(
                    new Paragraph({
                      alignment: AlignmentType.START,
                      children: [
                        new TextRun({
                          text: text,
                          bold: true,
                        }),
                        new TextRun({
                          break: 1,
                        }),
                      ],
                    })
                  );
                  return null;
                });

                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
          ],
        },
      ],
    });
    await Packer.toBlob(doc)
      .then(blob => {
        saveAs(blob, `${Title}.docx`);
      })
      .catch(err => {
        return err;
      });
  };

  lessonData[0]?.module?.lesson?.map(lesson_data => {
    topic = lesson_data.name;
    lesson_data?.initialNodes?.map(initial_nodes_data => {
      if (initial_nodes_data.className !== 'main-node') {
        if (initial_nodes_data.className === 'child-node') {
          childNodeArr.push(initial_nodes_data);
        } else {
          miniChildNodeArr.push(initial_nodes_data);
        }
      }
      return null;
    });
    return null;
  });

  childNodeArr.map(child_node_data => {
    miniChildNodeArr.map(mini_child_node_data => {
      if (
        child_node_data.childNodeName === mini_child_node_data.parentNodeName
      ) {
        summaryListArr.push({
          sub_module_topic: child_node_data.data?.label,
          sub_module_data: mini_child_node_data.data?.label,
        });
      }

      return null;
    });

    return null;
  });

  const subModules = summaryListArr.map(o => o.sub_module_data);
  filteredSummaryList = summaryListArr.filter(
    ({ sub_module_data }, index) =>
      !subModules.includes(sub_module_data, index + 1)
  );

  // console.log('jhkjhj 2', lessonData[0]?.module?.lesson);
  // console.log('filhkghj', filteredSummaryList);
  console.log('ohhkl', isOk);

  const getAudio = () => {
    console.log('fhjhgjhggg');
    let speechText = `${topic}.\n\n`;
    // let materialId = '';
    // summary.map(summary_data => {
    //   speechText += summary_data;
    //   return null;
    // });
    filteredSummaryList.map((summary_data, index) => {
      if (index === 0) {
        speechText += `${summary_data.sub_module_topic}.\n`;
        speechText += `${summary_data.sub_module_data} \n\n`;
      } else {
        speechText +=
          filteredSummaryList[index]?.sub_module_topic ===
          filteredSummaryList[index - 1]?.sub_module_topic
            ? '\n\n'
            : `${summary_data.sub_module_topic}.\n`;
        speechText += `${summary_data.sub_module_data} \n\n`;
      }

      return null;
    });
    // speechText = speechText.replace(/\./g, '');
    // console.log('jhkjhj', speechText);

    // lessonData?.map(lesson_data => {
    //   lesson_data.module.lesson.map(lesson_in_data => {
    //     materialId = `${lesson_data.module._id}${lesson_in_data.id}`;

    //     return null;
    //   });

    //   return null;
    // });
    // console.log('jhkjhj 3', materialId);

    axios({
      method: 'POST',
      // url: `${REACT_APP_EH_BACKEND}/nuggets/audio/${speechText}`,
      url: `${REACT_APP_EH_BACKEND}/nuggets/audio/${userLocalData._id}/NuggetArticleWebscrapes/${knowledgeNuggetId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
      data: { text: speechText },
    })
      .then(audioResponse => {
        console.log('asddh', audioResponse);
        setIsOk(true);
        setPath(audioResponse?.data?.filePath);
        setIsListenClicked(false);
      })
      .catch(loginError => {
        return loginError;
      });
  };

  useEffect(() => {
    // console.log('hkkhjkhjkkkjkkj');
    // window.onhashchange = event => {
    //   event.preventDefault();
    //   console.log('onghjhkhk 1');
    // };
    window.onload = () => {
      console.log('onghjhkhk 2');
      removeAudio();
    };
    window.onpopstate = () => {
      console.log('onghjhkhk 3');
      removeAudio();
    };
    window.onbeforeunload = () => {
      console.log('onghjhkhk 4');
      removeAudio();
    };
  });

  useEffect(() => {
    setPosition(percentage);
  }, [percentage]);

  useEffect(() => {
    const audio = audioRef.current;
    if (currentTime === duration) {
      setIsPlaying2(false);
      audio?.pause();
    }
  }, [currentTime, duration]);

  const onChange = v => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * v;
    setPercentage(v);
  };

  const play = () => {
    const audio = audioRef.current;

    if (!isPlaying2) {
      setIsPlaying2(true);
      audio.play();
    }

    if (isPlaying2) {
      setIsPlaying2(false);
      audio.pause();
    }
  };

  const getCurrDuration = e => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;
    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  return (
    <ChakraProvider theme={extendTheme}>
      {editState ? (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {formik => (
            <Form
              onSubmit={handlethis}
              autoComplete="off"
              encType="multipart/form-data"
            >
              <Flex
                px={{ base: '4', md: '7' }}
                color="gray.500"
                borderBottom="1px solid #E2E8F0"
                // position="sticky"
                // top="73"
              >
                <Flex
                  // pr="4"
                  // py="2"
                  w="100%"
                  borderRight={{
                    base: 'none',
                    md: '1px solid #E2E8F0',
                    lg: '1px solid #E2E8F0',
                  }}
                  display={{ base: editDisplay ? 'flex' : 'none', md: 'flex' }}
                >
                  <Box py="2" cursor={'pointer'} w="100%">
                    <Flex>
                      <Icon
                        as={AiOutlineArrowLeft}
                        mt={'6px'}
                        fontSize="22px"
                        onClick={() => goToPrevious()}
                      />

                      <Text
                        pt="1"
                        pl="10.5px"
                        color="black"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="18px"
                        mb="3"
                      >
                        {fileName}
                      </Text>
                      <Icon
                        as={FiExternalLink}
                        color="purple.500"
                        ml="2"
                        mt="1"
                        width="6"
                        height="6"
                        onClick={event => {
                          removeAudio();
                          let sourceURL = metaData.doi;
                          event.stopPropagation();
                          window.open(
                            sourceURL.includes('https://')
                              ? sourceURL
                              : ` https://doi.org/${metaData.doi}`
                          );
                        }}
                      />

                      <Spacer />

                      <Box>
                        {editDisplay ? (
                          <Button
                            display={{ base: 'flex', md: 'none', lg: 'none' }}
                            p="1"
                            ml="3"
                            rounded="5px"
                            bgColor={'gray.200'}
                            color={'purple.600'}
                            onClick={() => {
                              setEditDisplay(!editDisplay);
                            }}
                          >
                            <BiUpArrowAlt fontSize="24px" />
                          </Button>
                        ) : (
                          ''
                        )}
                      </Box>
                    </Flex>
                    <Flex flexDirection="column">
                      {/* tags */}
                      <FormErrorMessage>
                        <ErrorMessage name="file" />
                      </FormErrorMessage>

                      <InputTagRecurrent
                        setFieldValue={formik.setFieldValue}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        values={tagData}
                        errors={formik.errors.tags}
                        suggestionTag={suggestionTag}
                        setSuggestionTag={setSuggestionTag}
                        update={refresh}
                      />

                      {formik.errors.tags && formik.touched.tags && (
                        <Text fontFamily={'body'} color="red">
                          {formik.errors.tags}
                        </Text>
                      )}
                      <FormErrorMessage>
                        <ErrorMessage name="tags" />
                      </FormErrorMessage>
                      <Box
                        aria-invalid="true"
                        display={'flex'}
                        flexDir="row"
                        gap={2}
                        my={2}
                      >
                        <Text ml="10px" fontFamily={'body'}>
                          Suggested
                        </Text>
                        <Grid
                          templateColumns="repeat(3, 1fr)"
                          gap={2}
                          overflow="hidden"
                          display="flex"
                          flexWrap="wrap"
                        >
                          {suggestionList.map(
                            (tag, index) => (
                              <GridItem key={index}>
                                <Box onClick={() => setSuggestionTag(tag)}>
                                  <Badge
                                    variant={'subtle'}
                                    borderRadius="20px"
                                    bgColor={'gray.200'}
                                    _hover={{
                                      cursor: 'pointer',
                                      bgColor: 'gray.300',
                                    }}
                                  >
                                    <Text
                                      fontFamily={'body'}
                                      fontSize="14px"
                                      color={'gray.600'}
                                    >
                                      {tag}
                                    </Text>
                                  </Badge>
                                </Box>
                              </GridItem>
                            ),
                            this
                          )}
                        </Grid>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
                <Spacer />
                {/* for normal screen sizes */}
                <Box
                  display={{ base: 'none', md: 'flex', lg: 'flex' }}
                  pl="4"
                  py="2"
                >
                  <Button
                    bg="yellow.400"
                    color="gray.900"
                    fontWeight="700"
                    fontFamily="Inter"
                    fontSize="16px"
                    leftIcon={<AiOutlineCheck />}
                    px="4"
                    py="2"
                    mr="6"
                    rounded="4px"
                    type="button"
                    ref={cancelRef}
                    isLoading={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Flex>
              {/* for mobile screns */}
              <Box
                display={{ base: 'flex', md: 'none', lg: 'none' }}
                px="4"
                py="2"
                top="54vh"
              >
                <Button
                  bg="yellow.400"
                  color="gray.900"
                  fontWeight="700"
                  fontFamily="Inter"
                  fontSize="16px"
                  leftIcon={<AiOutlineCheck />}
                  px="4"
                  py="2"
                  mr="6"
                  rounded="4px"
                  type="button"
                  ref={cancelRef}
                  isLoading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  Save Changes
                </Button>
                <Spacer />
                {editDisplay ? (
                  ''
                ) : (
                  <Button
                    p="1"
                    rounded="5px"
                    bgColor={'gray.200'}
                    color={'purple.600'}
                    onClick={() => {
                      setEditDisplay(!editDisplay);
                    }}
                  >
                    <BiDownArrowAlt fontSize="24px" />
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <Flex
          px="2"
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          borderTop="1px solid #E2E8F0"
          borderBottom="1px solid #E2E8F0"
        >
          <Flex
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
            // pr="112px"
          >
            <Flex>
              <Icon
                as={AiOutlineArrowLeft}
                mt={{ base: '4', sm: '4', md: '4', lg: '6' }}
                fontSize={{ base: '15px', md: '20px', lg: '20px' }}
                onClick={() => goToPrevious()}
              />

              <Text
                pt={{ base: '1', sm: '3', md: '3', lg: '20px' }}
                pl="10.5px"
                color="black"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize={{ base: '22px', sm: '14px', md: '18px', lg: '20px' }}
              >
                {/* file name was hided only for pilot launch */}
                {/* {fileName} */}
                {metaData.doi !== '' ? (
                  <Tooltip
                    hasArrow
                    label="View Source"
                    bg="gray.800"
                    placement="top"
                  >
                    <Icon
                      as={FiExternalLink}
                      fontSize={{ base: '20px', md: '25px', lg: '25px' }}
                      pl="3px"
                      color="purple.500"
                      cursor="pointer"
                      onClick={event => {
                        removeAudio();
                        let sourceURL = metaData.doi;
                        event.stopPropagation();
                        window.open(
                          sourceURL.includes('https://')
                            ? sourceURL
                            : ` https://doi.org/${metaData.doi}`
                        );
                      }}
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </Text>
            </Flex>
            {/* desktop view more option menu */}
            <Flex pt={{ base: '0', md: '4', lg: '4' }} pb="2" flexWrap="wrap">
              {tagData?.map((tag_data, index) => (
                <Box key={index}>
                  <Spacer />
                  <Badge
                    px="2"
                    py="1"
                    rounded="full"
                    textTransform="initial"
                    bg="gray.200"
                    cursor="pointer"
                    marginTop="1"
                    marginLeft="3"
                  >
                    {tag_data}
                  </Badge>
                  <Spacer />
                </Box>
              ))}
            </Flex>
          </Flex>
          {/* dot more option menu */}
          <Box
            borderLeft={{ base: '1px solid #E2E8F0', md: 'none', lg: 'none' }}
            pl={{ base: '2', sm: '2' }}
            onClick={onBottomDrawerOpen}
          >
            <Icon
              as={TbGridDots}
              display={{ base: 'flex', md: 'none', lg: 'none' }}
              p="2"
              m="2"
              height="8"
              width="8"
              borderRadius="5"
              border="1px solid #E2E8F0"
            />
          </Box>
          <Box
            display={{ base: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
            borderLeft="1px solid #E2E8F0"
            pl="4"
            py="2"
          >
            <Flex align="center">
              {numOfUploadedFiles !== 0 ? (
                <Flex flexDirection="column">
                  {numOfUploadedFiles !== 0 ? (
                    <Box position="absolute" top="94px">
                      <Notification py="2" value={numOfUploadedFiles} />
                    </Box>
                  ) : (
                    ''
                  )}
                  <Box
                    mt="2"
                    mb="1"
                    p="2"
                    border="1px solid #E2E8F0"
                    mr="4"
                    rounded="5px"
                  >
                    <ImAttachment
                      cursor="pointer"
                      onClick={() => {
                        onEditOpen();
                      }}
                    />
                  </Box>
                </Flex>
              ) : (
                <Box
                  mt="2"
                  mb="1"
                  p="2"
                  border="1px solid #E2E8F0"
                  mr="4"
                  rounded="5px"
                >
                  <ImAttachment
                    cursor="pointer"
                    onClick={() => {
                      onUploadOpen();
                    }}
                  />
                </Box>
              )}
              {numberOfNotes > 0 ? (
                <Flex flexDirection="column">
                  <Box position="absolute" top="94px">
                    <Notification value={numberOfNotes} />
                  </Box>
                  <Box
                    mt="2"
                    mb="1"
                    p="2"
                    border="1px solid #E2E8F0"
                    mr="4"
                    rounded="5px"
                  >
                    <BiNote
                      cursor="pointer"
                      onClick={() => {
                        onAddNotesOpen();
                      }}
                    />
                  </Box>
                </Flex>
              ) : (
                <Box
                  mt="2"
                  mb="1"
                  p="2"
                  border="1px solid #E2E8F0"
                  mr="4"
                  rounded="5px"
                >
                  <BiNote
                    cursor="pointer"
                    onClick={() => {
                      onAddNotesOpen();
                    }}
                  />
                </Box>
              )}
              <Box
                cursor="pointer"
                mt="2"
                mb="1"
                p="2"
                border="1px solid #E2E8F0"
                mr="4"
                rounded="5px"
                id="print"
              >
                <FiDownload
                  onClick={() => {
                    exportPDF(lessonData);
                  }}
                />
              </Box>
              <Box
                cursor="pointer"
                mt="2"
                mb="1"
                px="2"
                py="1"
                border="1px solid #E2E8F0"
                mr="4"
                rounded="5px"
                onClick={() => onDeleteOpen()}
              >
                <Icon as={RiDeleteBin6Line} color="#C53030" />
              </Box>
              <Box
                mt="1"
                py="1"
                px="2"
                border="1px solid #E2E8F0"
                rounded="5px"
              >
                <Flex
                  align="center"
                  cursor="pointer"
                  onClick={() => {
                    onEditClick();
                  }}
                >
                  <BiEditAlt />
                  <Text
                    p="0"
                    pl="3"
                    color="gray.900"
                    fontFamily="Inter"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="16px"
                  >
                    Edit
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      )}
      {haveUploadedFiles ? (
        <UploadedDrawerWithFiles
          refresh={refresh}
          setRefresh={setRefresh}
          isOpen={isEditOpen}
          onClose={onEditClose}
          isUploadOpen={isUploadOpen}
          onUploadClose={onUploadClose}
          onUploadOpen={onUploadOpen}
          tagD={tagData}
        />
      ) : (
        <UploadFiles
          kNuggetId={knowledgeNuggetId}
          isOpen={isUploadOpen}
          onClose={onUploadClose}
          refresh={refresh}
          setRefresh={setRefresh}
          tagD={tagData}
        />
      )}
      {onAddNotesOpen && (
        <AddNotesDrawer
          lesson={lessonData}
          isOpen={isAddNotesOpen}
          onClose={onAddNotesClose}
          onNoteChange={handleOnNoteChange}
        />
      )}
      <Drawer
        placement={'bottom'}
        onClose={onBottomDrawerClose}
        isOpen={isBottomDrawerOpen}
        width="100%"
      >
        <DrawerOverlay />
        <DrawerContent borderRadius="10px">
          <DrawerBody p="5">
            <Flex
              align="center"
              cursor="pointer"
              onClick={numOfUploadedFiles !== 0 ? onEditOpen : onUploadOpen}
            >
              <Icon as={GrAttachment} />

              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Uploads
              </Text>
            </Flex>
            <Flex align="center" cursor="pointer" onClick={onAddNotesOpen}>
              <BiNote />
              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Notes
              </Text>
            </Flex>
            <Flex
              align="center"
              cursor="pointer"
              onClick={() => exportPDF(lessonData)}
            >
              <FiDownload />
              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Download
              </Text>
            </Flex>
            <Flex
              align="center"
              cursor="pointer"
              onClick={() => {
                onEditClick();
                onBottomDrawerClose();
              }}
            >
              <BiEditAlt />
              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Edit Knowledge Nugget
              </Text>
            </Flex>
            <Flex align="center" cursor="pointer" onClick={onDeleteOpen}>
              <Icon as={RiDeleteBin6Line} color="red.500" />

              <Text
                p="2"
                pl="3"
                color="gray.600"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="16px"
              >
                Delete Knowledge Nugget
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box>
        <Tabs variant="soft-rounded" defaultIndex={1}>
          <TabList
            style={{ position: 'fixed', zIndex: 4 }}
            display={{ base: 'none', md: 'flex', lg: 'flex' }}
            p="0"
            mt="4"
            position="absolute"
            left={leftVal}
            right={rightVal}
            bg="#e2e8f0"
            rounded="full"
          >
            <Tab
              m="0"
              py="3"
              px="7"
              onClick={() => {
                setLeft('');
                setRight('16px');
                setPauseState('file');
                // setRatingMdLeft('14px');
                setRatingLgLeft('14px');
                // setRatingMdTop('73vh');
                setRatingLgtop('73vh');
              }}
              _hover={{
                bg: 'gray.50',
              }}
              _selected={{
                bg: 'gray.50',
                shadow: 'base',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              <ImFileText2 size="18" color="#718096" />
            </Tab>
            <Tab
              m="0"
              py="3"
              px="7"
              onClick={() => {
                setLeft('480px');
                setRight('');
                setLgLeftZoom('550px');
                setMdLeftZoom('390px');
                setEditTagModalX('500');
                setEditTagModalY('450');
                setPauseState('list');
                // setRatingMdLeft('320px');
                setRatingLgLeft('480px');
                // setRatingMdTop('85vh');
                setRatingLgtop('85vh');
              }}
              _hover={{
                bg: 'gray.50',
              }}
              _selected={{
                bg: 'gray.50',
                shadow: 'base',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              <CgArrowsShrinkH size="18" color="#718096" />
            </Tab>
            <Tab
              m="0"
              py="3"
              px="7"
              onClick={() => {
                setLeft('16px');
                setRight('');
                setLgLeftZoom('88px');
                setMdLeftZoom('80px');
                setEditTagModalX('0');
                setEditTagModalY('250');
                setPauseState('network');
                // setRatingMdLeft('14px');
                setRatingLgLeft('14px');
                // setRatingMdTop('73vh');
                setRatingLgtop('73vh');
              }}
              _hover={{
                bg: 'gray.50',
              }}
              _selected={{
                bg: 'gray.50',
                shadow: 'base',
              }}
              _focus={{
                outline: 'none',
              }}
            >
              <CgListTree size="18" color="#718096" />
            </Tab>
          </TabList>
          <TabPanels ref={exportRef} /*h="75vh"*/ p="0">
            <TabPanel p="0" m="0">
              <ListTab
                lesson={lessonData}
                pauseStateVal={pauseState}
                editStateVal={academicPaperEditState}
                editCompleteVal={editComplete}
                refresh={refresh}
                setRefresh={setRefresh}
                isOk={isOk}
                isListenClicked={isListenClicked}
                setIsListenClicked={setIsListenClicked}
                path={path}
                getAudio={getAudio}
                onChange={onChange}
                percentage={percentage}
                position={position}
                setPosition={setPosition}
                audioRef={audioRef}
                play={play}
                isPlaying2={isPlaying2}
                getCurrDuration={getCurrDuration}
                duration={duration}
                setDuration={setDuration}
                currentTime={currentTime}
              />
            </TabPanel>
            <TabPanel p="0" m="0">
              <ListNMapTab
                mdLeftVal={mdLeftValZoom}
                lgLeftVal={lgLeftValZoom}
                editStateVal={academicPaperEditState}
                editCompleteVal={editComplete}
                lesson={lessonData}
                editTagModalXVal={editTagModalX}
                editTagModalYVal={editTagModalY}
                pauseStateVal={pauseState}
                refresh={refresh}
                setRefresh={setRefresh}
                isOk={isOk}
                isListenClicked={isListenClicked}
                setIsListenClicked={setIsListenClicked}
                path={path}
                getAudio={getAudio}
                onChange={onChange}
                percentage={percentage}
                position={position}
                setPosition={setPosition}
                audioRef={audioRef}
                play={play}
                isPlaying2={isPlaying2}
                getCurrDuration={getCurrDuration}
                duration={duration}
                setDuration={setDuration}
                currentTime={currentTime}
              />
            </TabPanel>
            <TabPanel p="0" m="0">
              <MapTab
                mdLeftVal={mdLeftValZoom}
                lgLeftVal={lgLeftValZoom}
                editStateVal={academicPaperEditState}
                lesson={lessonData}
                editTagModalXVal={editTagModalX}
                editTagModalYVal={editTagModalY}
                pauseStateVal={pauseState}
                isOk={isOk}
                isListenClicked={isListenClicked}
                setIsListenClicked={setIsListenClicked}
                path={path}
                getAudio={getAudio}
                onChange={onChange}
                percentage={percentage}
                position={position}
                setPosition={setPosition}
                audioRef={audioRef}
                play={play}
                isPlaying2={isPlaying2}
                getCurrDuration={getCurrDuration}
                duration={duration}
                setDuration={setDuration}
                currentTime={currentTime}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* rating container */}
        <Flex
          display={{ base: 'none', md: 'flex', lg: 'flex' }}
          style={{ position: 'fixed', zIndex: 4 }}
          p="30"
          borderRadius="40px"
          mt="4"
          position="absolute"
          left={{ /*md: ratingMdLeft,*/ md: ratingLgLeft }}
          top={{ /*md: ratingMdTop,*/ md: ratingLgTop }}
          bg="#EDF2F7"
          onClick={onRatingOpen}
          cursor="pointer"
        >
          <Box>
            <Text
              pb="2"
              cursor="pointer"
              color="gray.600"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
            >
              Rate this summary
            </Text>
            {/* rating section */}
            <Flex>
              <Flex align="center">
                {Array(Math.trunc(Number(knuggetRating))) // Variable ratings
                  .fill('')
                  .map((_, i) => (
                    <Icon
                      as={AiFillStar}
                      color="yellow.400"
                      fontSize="20px"
                      mr="1"
                      key={i}
                    />
                  ))}
                {Array(5 - Math.trunc(Number(knuggetRating)))
                  .fill('')
                  .map((_, i) => (
                    <Icon
                      as={AiFillStar}
                      color="gray.400"
                      fontSize="20px"
                      mr="1"
                      key={i}
                    />
                  ))}
              </Flex>
            </Flex>
          </Box>
        </Flex>
        {onRatingOpen && (
          <RatingModel
            knowledgeNuggetIdVal={knowledgeNuggetId}
            isOpen={isRatingOpen}
            onClose={onRatingClose}
            // onRating={handleOnRating}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </Box>
      {onDeleteOpen && (
        <Delete
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          content={fileName}
          onDelete={handleDeleteKN}
          isproject={false}
          type={'knowledgeNugget'}
        />
      )}
    </ChakraProvider>
  );
};

export default TabController;
