import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Icon,
  Image,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spacer,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, {  useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { useState } from 'react';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import Pagination from './Pagination';
import Arrowupdown from '../../../assests/Icons/ArrowsDownUp.png';
import TableBody from './TableBody';
import TableAccodianBody from './TableAccodianBody';
import BackToTop from '../../../assests/Icons/KnowledgeLibrary/ArrowLineLeftPurple.png';
import gtag from 'ga-gtag';
import { BiBookBookmark } from 'react-icons/bi';
import { TiDocumentText } from 'react-icons/ti';
import { IoHeadsetOutline, IoSchoolOutline } from 'react-icons/io5';
import { TbHexagons } from 'react-icons/tb';

function KnowledgeTable({
  haveknowledgeData,
  knowledgeData,
  indexOfFirstPost,
  indexOfLastPost,
  searchValue,
  setSearchValue,
  props,
  postsPerPage,
  paginate,
  currentPage,
  sortByTitle,
  sortByAuthors,
  sortByDate,
  sortByRatings,
}) {
  const [loading, setLoading] = useState(true);
  const [filteredKnowledgeData, setFilteredKnowledgeData] = useState();
  const [filteredKnowledgeData2, setFilteredKnowledgeData2] = useState();
  const [defaultFilter, setDefaultFilter] = useState(true);
  const [filterbyArticle, setFilterbyArticle] = useState(false);
  const [filterbyTextBook, setFilterbyTextBook] = useState(false);
  const [filterbyPodecast, setFilterbyPodecast] = useState(false);
  const [filterbyAccademic, setFilterbyAccademic] = useState(false);
  const [updateFilteringKLData, setUpdateFilteringKLData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('Newest');
  const [alert, setAlert] = useState('');
  const [update, setUpdate] = useState(false);
  const [alertType, setAlertType] = useState('');
  const toastposition = useBreakpointValue({ base: 'bottom', md: 'top' });
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { REACT_APP_GA_MEASUREMENT_ID } = process.env;
  let ratings = 0;
  let userLocalData = JSON.parse(localStorage.user);

  const history = useHistory();

  useEffect(() => {
    gtag('config', REACT_APP_GA_MEASUREMENT_ID);
  }, [REACT_APP_GA_MEASUREMENT_ID]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // handle toast respond resived from backend
  const alertHandler = ({ status, message }) => {
    setAlert(message);
    setAlertType(status);
  };

  const toast = useToast();
  useEffect(() => {
    if (alert && alertType === 'error' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
    if (alert && alertType === 'success' && toast.isActive) {
      toast({
        position: toastposition,
        title: alert,
        status: alertType === 'success' ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginTop: '140px',
          marginBottom: '60px',
        },
      });
    }
  }, [alert, alertType, toast, toastposition]);

  // remove toast message so when user retype a wrong details it will fetched and be updated
  useEffect(() => {
    setTimeout(() => {
      setAlert('');
      setAlertType('');
    }, 2000);
  }, [update]);

  //get knowledge nugget data
  useEffect(() => {
    const { REACT_APP_EH_BACKEND } = process.env;

    axios({
      method: 'GET',
      url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/library/all/${userLocalData._id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    })
      .then(knowledgeLibraryResponse => {
        const filteredData = knowledgeData.filter(res => {
          return (
            !knowledgeLibraryResponse.data.filter(KDLres => {
              return KDLres._id === res._id;
            }).length > 0
          );
        });

        setFilteredKnowledgeData(filteredData);
        setFilteredKnowledgeData2(filteredData);
        setUpdateFilteringKLData(false);
        setLoading(false);
      })
      .catch(err => {
        return err;
      });
  }, [knowledgeData, userLocalData._id, updateFilteringKLData]);

  useEffect(() => {
    if (filterbyArticle) {
      const filteredData = filteredKnowledgeData2.filter(res => {
        return res.type === 'Article';
      });

      setFilteredKnowledgeData(filteredData);
      setFilteredData(filteredData);
    }
    if (filterbyTextBook) {
      const filteredData = filteredKnowledgeData2.filter(res => {
        return res.type === 'course';
      });

      setFilteredKnowledgeData(filteredData);
      setFilteredData(filteredData);
    }
    if (filterbyPodecast) {
      const filteredData = filteredKnowledgeData2.filter(res => {
        return res.type === 'Podcast';
      });

      setFilteredKnowledgeData(filteredData);
      setFilteredData(filteredData);
    }
    if (filterbyAccademic) {
      const filteredData = filteredKnowledgeData2.filter(res => {
        return res.type === 'Accademic';
      });

      setFilteredKnowledgeData(filteredData);
      setFilteredData(filteredData);
    }
  }, [
    filterbyAccademic,
    filterbyArticle,
    filterbyPodecast,
    filterbyTextBook,
    filteredKnowledgeData2,
  ]);

  useEffect(() => {
    if (
      !filterbyAccademic &&
      !filterbyArticle &&
      !filterbyPodecast &&
      !filterbyTextBook
    ) {
      setUpdateFilteringKLData(true);
    }
  }, [filterbyAccademic, filterbyArticle, filterbyPodecast, filterbyTextBook]);

  function addToMyKnowledgeNuggets(event, knowledgeId, knowledgeName, type) {
    event.stopPropagation();
    const { REACT_APP_EH_BACKEND } = process.env;

    if (knowledgeId !== '') {
      axios({
        method: 'PUT',
        url: `${REACT_APP_EH_BACKEND}/knowledge/nuggets/library/${userLocalData._id}/${knowledgeId}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.login_token,
        },
        data: {
          type: type,
        },
      })
        .then(res => {
          if (res.data.status) {
            alertHandler({
              status: res.data.status,
              message: res.data.message,
            });
            // setLoading(false);
            if (res.data.status === 'success') {
              gtag('event', 'add_to_hive', {
                article_id: `${knowledgeId}`,
                article_name: `${knowledgeName}`,
                userid: `${userLocalData._id}`,
                full_name: `${userLocalData.firstName} ${userLocalData.lastName}`,
                verified: `${userLocalData.isVerified}`,
              });
            }
            if(res.data.status === 'success'){
              setUpdateFilteringKLData(true);
              if(type === "Article" || type === "Podcast" || type === "Accademic"){
                history.push(
                  `/knowledge/nugget?knowledge_nugget_id=${knowledgeId}`
                );
              }
              if(type === "course"){
                history.push(`/resource/overview?resource_id=${knowledgeId}`);
              }
            }
          }
          setUpdateFilteringKLData(true);
          setUpdate(!update);
        })

        .catch(loginError => {
          gtag('event', 'exception', {
            process: `adding to hive the article "${knowledgeName}" of Id: "${knowledgeId}" by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
            description: loginError,
            fatal: false,
          });
          return loginError;
        });
    } else {
      gtag('event', 'exception', {
        process: `adding to hive the article "${knowledgeName}" of Id: "${knowledgeId}" by ${userLocalData.firstName} ${userLocalData.lastName} of user id: ${userLocalData._id}`,
        description: 'Adding failed! Something went wrong',
        fatal: false,
      });
      alertHandler({
        status: 'error',
        message: 'Adding failed! Something went wrong',
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loading text="Loading Elephant Hive Library" />
      ) : (
        <Box mb="12">
          <Box>
            <Flex
              align={'center'}
              overflow="auto"
              m={3}
              gap={3}
              display={['flex', 'flex', 'none']}
            >
              <Badge
                p={2}
                bg={defaultFilter ? '#F2C94C' : 'rgba(242, 201, 76, 0.05)'}
                border="1px"
                color={defaultFilter ? '#828282' : '#F2C94C'}
                fontFamily={'body'}
                borderColor="#F2C94C"
                cursor={'pointer'}
                onClick={() => {
                  setDefaultFilter(true);
                  setFilterbyTextBook(false);
                  setFilterbyArticle(false);
                  setFilterbyPodecast(false);
                  setFilterbyAccademic(false);
                }}
              >
                <Flex align={'center'}>
                  <Icon as={TbHexagons} mr={2} />
                  All
                </Flex>
              </Badge>
              <Badge
                p={2}
                bg={filterbyTextBook ? 'green.500' : 'green.50'}
                border="1px"
                color={filterbyTextBook ? 'white' : 'green.500'}
                fontFamily={'body'}
                borderColor="green.300"
                cursor={'pointer'}
                onClick={() => {
                  setDefaultFilter(false);
                  setFilterbyTextBook(true);
                  setFilterbyArticle(false);
                  setFilterbyPodecast(false);
                  setFilterbyAccademic(false);
                }}
              >
                <Flex align={'center'}>
                  <Icon as={BiBookBookmark} mr={2} />
                  Textbook
                </Flex>
              </Badge>
              <Badge
                p={2}
                bg={filterbyArticle ? 'purple.600' : 'purple.50'}
                border="1px"
                color={filterbyArticle ? 'purple.50' : 'purple.400'}
                fontFamily={'body'}
                borderColor="purple.200"
                cursor={'pointer'}
                onClick={() => {
                  setDefaultFilter(false);
                  setFilterbyTextBook(false);
                  setFilterbyArticle(true);
                  setFilterbyPodecast(false);
                  setFilterbyAccademic(false);
                }}
              >
                <Flex align={'center'}>
                  <Icon as={TiDocumentText} mr={2} />
                  Article
                </Flex>
              </Badge>
              <Badge
                p={2}
                bg={filterbyPodecast ? 'orange.400' : 'orange.50'}
                border="1px"
                color={filterbyPodecast ? 'orange.50' : 'orange.400'}
                fontFamily={'body'}
                borderColor="orange.200"
                cursor={'pointer'}
                onClick={() => {
                  setDefaultFilter(false);
                  setFilterbyTextBook(false);
                  setFilterbyArticle(false);
                  setFilterbyPodecast(true);
                  setFilterbyAccademic(false);
                }}
              >
                <Flex align={'center'}>
                  <Icon as={IoHeadsetOutline} mr={2} />
                  Podcast
                </Flex>
              </Badge>
              <Badge
                p={2}
                bg={filterbyAccademic ? 'blue.500' : 'teal.50'}
                border="1px"
                color={filterbyAccademic ? 'blue.50' : 'blue.400'}
                fontFamily={'body'}
                borderColor="blue.200"
                cursor={'pointer'}
                onClick={() => {
                  setDefaultFilter(false);
                  setFilterbyTextBook(false);
                  setFilterbyArticle(false);
                  setFilterbyPodecast(false);
                  setFilterbyAccademic(true);
                }}
              >
                <Flex align={'center'}>
                  <Icon as={IoSchoolOutline} mr={2} />
                  Academic Paper
                </Flex>
              </Badge>
            </Flex>

            <Flex mx={5} my={'4'} align="center">
              <Box
                mb={2}
                overflow="hidden"
                display={['none', 'none', 'none', 'flex']}
                alignItems="center"
              >
                <FormControl>
                  <InputGroup w={['343px', '400px']} h="40px" mr={5}>
                    <Input
                      id="projsearch"
                      name="projsearch"
                      fontFamily={'body'}
                      autoComplete="off"
                      placeholder="Search in Library"
                      type="text"
                      onChange={e => setSearchValue(e.target.value)}
                      value={searchValue}
                    />
                    <InputRightElement children={<BsSearch />} />
                  </InputGroup>
                </FormControl>
              </Box>
              {/* Add KLibrary filtering buttons here as a flex with  buttons */}
              <Flex align={'center'} gap={3} display={['none', 'none', 'flex']}>
                <Badge
                  p={2}
                  bg={defaultFilter ? '#F2C94C' : 'rgba(242, 201, 76, 0.05)'}
                  border="1px"
                  color={defaultFilter ? '#828282' : '#F2C94C'}
                  fontFamily={'body'}
                  borderColor="#F2C94C"
                  cursor={'pointer'}
                  onClick={() => {
                    setDefaultFilter(true);
                    setFilterbyTextBook(false);
                    setFilterbyArticle(false);
                    setFilterbyPodecast(false);
                    setFilterbyAccademic(false);
                  }}
                >
                  <Flex align={'center'}>
                    <Icon as={TbHexagons} mr={2} />
                    All
                  </Flex>
                </Badge>
                <Badge
                  p={2}
                  bg={filterbyTextBook ? 'green.500' : 'green.50'}
                  border="1px"
                  color={filterbyTextBook ? 'white' : 'green.500'}
                  fontFamily={'body'}
                  borderColor="green.300"
                  cursor={'pointer'}
                  onClick={() => {
                    setDefaultFilter(false);
                    setFilterbyTextBook(true);
                    setFilterbyArticle(false);
                    setFilterbyPodecast(false);
                    setFilterbyAccademic(false);
                  }}
                >
                  <Flex align={'center'}>
                    <Icon as={BiBookBookmark} mr={2} />
                    Textbook
                  </Flex>
                </Badge>
                <Badge
                  p={2}
                  bg={filterbyArticle ? 'purple.600' : 'purple.50'}
                  border="1px"
                  color={filterbyArticle ? 'purple.50' : 'purple.400'}
                  fontFamily={'body'}
                  borderColor="purple.200"
                  cursor={'pointer'}
                  onClick={() => {
                    setDefaultFilter(false);
                    setFilterbyTextBook(false);
                    setFilterbyArticle(true);
                    setFilterbyPodecast(false);
                    setFilterbyAccademic(false);
                  }}
                >
                  <Flex align={'center'}>
                    <Icon as={TiDocumentText} mr={2} />
                    Article
                  </Flex>
                </Badge>
                <Badge
                  p={2}
                  bg={filterbyPodecast ? 'orange.400' : 'orange.50'}
                  border="1px"
                  color={filterbyPodecast ? 'orange.50' : 'orange.400'}
                  fontFamily={'body'}
                  borderColor="orange.200"
                  cursor={'pointer'}
                  onClick={() => {
                    setDefaultFilter(false);
                    setFilterbyTextBook(false);
                    setFilterbyArticle(false);
                    setFilterbyPodecast(true);
                    setFilterbyAccademic(false);
                  }}
                >
                  <Flex align={'center'}>
                    <Icon as={IoHeadsetOutline} mr={2} />
                    Podcast
                  </Flex>
                </Badge>
                <Badge
                  p={2}
                  bg={filterbyAccademic ? 'blue.500' : 'teal.50'}
                  border="1px"
                  color={filterbyAccademic ? 'blue.50' : 'blue.400'}
                  fontFamily={'body'}
                  borderColor="blue.200"
                  cursor={'pointer'}
                  onClick={() => {
                    setDefaultFilter(false);
                    setFilterbyTextBook(false);
                    setFilterbyArticle(false);
                    setFilterbyPodecast(false);
                    setFilterbyAccademic(true);
                  }}
                >
                  <Flex align={'center'}>
                    <Icon as={IoSchoolOutline} mr={2} />
                    Academic Paper
                  </Flex>
                </Badge>
              </Flex>
              <Spacer />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDir="row"
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Img src={Arrowupdown} />
                  <Text
                    py="6px"
                    px={2}
                    color="gray.500"
                    fontFamily="body"
                    fontWeight="normal"
                    fontSize="16px"
                  >
                    Sort
                  </Text>
                  <Text
                    py="6px"
                    color="gray.500"
                    fontFamily="body"
                    fontWeight="normal"
                    fontSize="16px"
                    mr={'7'}
                  >
                    by
                  </Text>
                </Box>
                <Select
                  onChange={event => {
                    setFilter(event.target.value);
                    switch (event.target.value) {
                      case 'Title':
                        filteredKnowledgeData?.sort(sortByTitle);

                        break;
                      case 'Author':
                        filteredKnowledgeData?.sort(sortByAuthors);

                        break;

                      case 'Date':
                        filteredKnowledgeData?.sort(sortByDate);

                        break;

                      case 'Ratings':
                        filteredKnowledgeData?.sort(sortByRatings);

                        break;

                      default:
                        return knowledgeData;
                    }

                    // setFilteredData(resourceDuplicate);
                    setFilteredData(filteredKnowledgeData);
                  }}
                >
                  <option value="Newest">Newest</option>
                  <option value="Title">Title</option>
                  <option value="Author">Author</option>
                  <option value="Date">Date</option>
                  <option value="Ratings">Ratings</option>
                </Select>
              </Box>
            </Flex>
          </Box>
          <Box
            display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
            w="full"
          >
            <TableContainer
              mx={'5'}
              w="100%"
              borderLeft="1px solid #E2E8F0"
              borderRight="1px solid #E2E8F0"
              borderTop="1px solid #E2E8F0"
              borderTopLeftRadius={'2xl'}
              borderTopRightRadius={'2xl'}
              borderBottomLeftRadius={'2xl'}
              borderBottomRightRadius={'2xl'}
              display={{ base: 'none', sm: 'none', md: 'none', lg: 'flex' }}
            >
              <Table variant="simple">
                <Thead borderTop="none" className="table-header-bigscreens">
                  <Tr w={'full'}>
                    <Th
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    />
                    <Th
                      m="0"
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    >
                      Title
                    </Th>
                    <Th
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    >
                      Author
                    </Th>
                    <Th
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    >
                      <Text>Date Added</Text>{' '}
                    </Th>
                    <Th
                      w={['20px', '200px']}
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    >
                      <Center>Source</Center>
                    </Th>
                    <Th
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    >
                      Rating
                    </Th>
                    <Th
                      fontFamily="Inter"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="14px"
                      bg="gray.200"
                      py="18px"
                      px="3"
                      borderTop="1px solid #E2E8F0"
                    />
                  </Tr>
                </Thead>

                {filter === 'Newest' ? (
                  <TableBody
                    searchValue={searchValue}
                    props={props}
                    ratings={ratings}
                    haveknowledgeData={haveknowledgeData}
                    filteredKnowledgeData={filteredKnowledgeData
                      .sort(sortByDate)
                      .reverse()}
                    indexOfFirstPost={indexOfFirstPost}
                    indexOfLastPost={indexOfLastPost}
                    addToMyKnowledgeNuggets={addToMyKnowledgeNuggets}
                  />
                ) : (
                  <TableBody
                    searchValue={searchValue}
                    props={props}
                    ratings={ratings}
                    haveknowledgeData={haveknowledgeData}
                    filteredKnowledgeData={filteredData}
                    indexOfFirstPost={indexOfFirstPost}
                    indexOfLastPost={indexOfLastPost}
                    addToMyKnowledgeNuggets={addToMyKnowledgeNuggets}
                  />
                )}
              </Table>
            </TableContainer>
          </Box>
          <Box
            display={{ base: 'flex', sm: 'flex', md: 'flex', lg: 'none' }}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Box
              mx={'5'}
              border="1px solid #E2E8F0"
              rounded={'2xl'}
              mb="6"
              w="100%"
            >
              <Flex
                className="flex-table-header-smallscreens"
                borderTopLeftRadius={'2xl'}
                borderTopRightRadius={'2xl'}
                borderTop="1px solid #E2E8F0"
                fontFamily="Inter"
                fontStyle="normal"
                fontWeight="700"
                fontSize="14px"
                bg="gray.200"
                py="18px"
                color="gray.500"
                align={'center'}
                w={'full'}
                justify={'space-between'}
                display={{ base: 'none', md: 'flex' }}
              >
                <Text px="3">Title</Text>
                <Text display={{ base: 'none', md: 'flex' }} px="6">
                  Author
                </Text>
                <Text
                  textAlign="center"
                  w={['20px', '200px']}
                  px="3"
                  right="12px"
                >
                  Source
                </Text>
              </Flex>

              {filter === 'Newest' ? (
                <TableAccodianBody
                  searchValue={searchValue}
                  props={props}
                  ratings={ratings}
                  haveknowledgeData={haveknowledgeData}
                  filteredKnowledgeData={filteredKnowledgeData
                    .sort(sortByDate)
                    .reverse()}
                  indexOfFirstPost={indexOfFirstPost}
                  indexOfLastPost={indexOfLastPost}
                  addToMyKnowledgeNuggets={addToMyKnowledgeNuggets}
                />
              ) : (
                <TableAccodianBody
                  searchValue={searchValue}
                  props={props}
                  ratings={ratings}
                  haveknowledgeData={haveknowledgeData}
                  filteredKnowledgeData={filteredData}
                  indexOfFirstPost={indexOfFirstPost}
                  indexOfLastPost={indexOfLastPost}
                  addToMyKnowledgeNuggets={addToMyKnowledgeNuggets}
                />
              )}
            </Box>
            {showTopBtn && (
              <Box position="sticky" bottom="24px" w="100%">
                <Center>
                  <Button
                    w="180px"
                    px="3"
                    py="10px"
                    backgroundColor="purple.100"
                    color="purple.400"
                    rounded="4px"
                    variant="solid"
                    shadow="md"
                    _active={{ backgroundColor: 'purple.100' }}
                    _focus={{ backgroundColor: 'purple.100' }}
                    onClick={goToTop}
                  >
                    <Flex align="center">
                      <Text
                        pr="50px"
                        fontFamily="Inter"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                      >
                        Back to Top
                      </Text>

                      <Box>
                        <Image
                          w="20px"
                          src={BackToTop}
                          fontSize="16px"
                          alt="Back to top button"
                        />
                      </Box>
                    </Flex>
                  </Button>
                </Center>
              </Box>
            )}
          </Box>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={filteredKnowledgeData.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Box>
      )}
    </>
  );
}

export default KnowledgeTable;
