import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import SummaryList from '../SummaryList';
import SummaryPlayer from '../SummaryPlayer';

function ListTab({
  lesson,
  pauseStateVal,
  isOk,
  path,
  getAudio,
  onChange,
  percentage,
  audioRef,
  play,
  getCurrDuration,
  duration,
  setDuration,
  position,
  setPosition,
  currentTime,
  isPlaying2,
  isListenClicked,
  setIsListenClicked,
}) {
  const lessonData = lesson;
  const pauseState = pauseStateVal;

  return (
    <ChakraProvider theme={theme}>
      <SummaryList lesson={lessonData} bgw="100%" w="716px" al="center" />
      <SummaryPlayer
        pauseStateVal={pauseState}
        lesson={lessonData}
        // materialId={playerMatId}
        isOk={isOk}
        path={path}
        getAudio={getAudio}
        // lesson={lessonData}
        onChange={onChange}
        percentage={percentage}
        audioRef={audioRef}
        play={play}
        getCurrDuration={getCurrDuration}
        duration={duration}
        setDuration={setDuration}
        position={position}
        setPosition={setPosition}
        currentTime={currentTime}
        isPlaying2={isPlaying2}
        isListenClicked={isListenClicked}
        setIsListenClicked={setIsListenClicked}
        w="100%"
      />
    </ChakraProvider>
  );
}
export default ListTab;
