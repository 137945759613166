import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Spacer,
  Button,
  Heading,
  Image,
  Stack,
  Icon,
  Avatar,
  useBreakpointValue,
  useDisclosure,
  // Img,
  Tooltip,
  Center,
  Menu,
  MenuButton,
  MenuList,
  Badge,
} from '@chakra-ui/react';
import Logo from '../../assests/Logos/HeaderLogo.png';
// import BulbLogo from '../../assests/Icons/Group 1.png';
import blacklogo from '../../assests/Elephant-hive-Logo-Top.png';
import { useLocation, useHistory } from 'react-router-dom';
import ProfileViewer from './ProfileViewer';
import { AiOutlineMenu } from 'react-icons/ai';
import { GrClose, GrGraphQl } from 'react-icons/gr';
import CreateKnowledgeNugget from '../KnowledgeNugget/CreateKnoledgeNugget';
import { AiOutlineUser } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { motion } from 'framer-motion';
import ProjectInvitation from '../Homepage/Components/ProjectComps/ShareProject/ProjectInvitation';
import './common.css';
import axios from 'axios';
import UserLevelKNGraph from './UserLevelKNGraph';

function Header2({
  haveResourceData,
  haveNonsummeryData,
  haveKnowledgeNuggetData,
  haveProjectdata,
  haveSharedProjectdata,
  resourceData,
  nonsummaryhiveData,
  knowledgeNuggetData,
  knowledgeLibraryData,
  updatehivedata,
  setUpdatehivedata,
  storageUsed,
  handleSearchClose,
  removeAudio,
}) {
  let userLocalData = JSON.parse(localStorage.user);
  const location = useLocation();
  const history = useHistory();
  const [click, setClick] = useState(false);
  const [update, setUpdate] = useState(false);
  const [haveshared, setHaveshared] = useState(false);
  const [sharedRes, setSharedRes] = useState('');
  const [userList, setUserList] = useState('');
  const [haveUsers, setHaveUsers] = useState(false);

  useEffect(() => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.login_token,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_EH_BACKEND}/shared-project-shared-user/${userLocalData._id}`,
        headers
      )
      .then(sharedRes => {
        setSharedRes(sharedRes.data);
        setHaveshared(true);
      });
  }, [userLocalData._id, update]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_EH_BACKEND}/auth/listusers`)
      .then(res => {
        setUserList(res.data);
        setHaveUsers(true);
      })
      .catch(err => {
        return err;
      });
  }, []);

  const handleClick = () => {
    onMenuOpen();
    setClick(!click);
  };

  const {
    isOpen: isCreateKnowledgeNuggetOpen,
    onOpen: onCreateKnowledgeNuggetOpen,
    onClose: onCreateKnowledgeNuggetClose,
  } = useDisclosure();

  const {
    onOpen: onMenuOpen,
    isOpen: isMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();

  const {
    isOpen: isProjectInvitationOpen,
    onOpen: onProjectInvitationOpen,
    onClose: onProjectInvitationClose,
  } = useDisclosure();

  const {
    isOpen: isKNGraphOpen,
    onOpen: onKNGraphOpen,
    onClose: onKNGraphClose,
  } = useDisclosure();

  const coloronsizes = useBreakpointValue({
    base: 'white',
    sm: 'white',
    md: 'white',
    lg: 'gray.700',
  });
  const coloronText = useBreakpointValue({
    base: 'gray.700',
    sm: 'gray.700',
    md: 'gray.700',
    lg: 'white',
  });
  const logoOnSize = useBreakpointValue({
    base: blacklogo,
    sm: blacklogo,
    md: blacklogo,
    lg: Logo,
  });

  return (
    <Box>
      <Box
        bg="white"
        border={
          haveResourceData ||
          haveNonsummeryData ||
          haveKnowledgeNuggetData ||
          haveProjectdata
            ? 'none'
            : '1px solid #E2E8F0'
        }
        position="sticky"
        zIndex="100"
        top="0"
        w="full"
      >
        <Flex align={'center'} my={['2', '2', '0']} mx={['2', '2', '0']}>
          <Flex align={'center'} justify="space-between">
            {/* logo with name part in black */}
            <Box
              w="388px"
              py={2}
              bg={
                location.pathname === '/homepage' &&
                (haveResourceData ||
                  haveNonsummeryData ||
                  haveKnowledgeNuggetData ||
                  haveSharedProjectdata ||
                  haveProjectdata)
                  ? coloronsizes
                  : 'white'
              }
              overflow="hidden"
              display={['none', 'none', 'flex']}
              alignItems="start"
              justifyContent="start"
            >
              <Stack
                isInline
                align="center"
                overflow="hidden"
                onClick={() => {
                  if (removeAudio !== undefined) {
                    removeAudio();
                  }
                  history.push('/homepage');
                  handleSearchClose();
                }}
                cursor={'pointer'}
                ml={4}
              >
                <Image
                  display={['none', 'none', 'flex']}
                  src={
                    location.pathname === '/homepage' &&
                    (haveResourceData ||
                      haveNonsummeryData ||
                      haveKnowledgeNuggetData ||
                      haveSharedProjectdata ||
                      haveProjectdata)
                      ? logoOnSize
                      : blacklogo
                  }
                  alt="The Elephant Hive Logo"
                  w={
                    location.pathname === '/homepage' &&
                    (haveResourceData ||
                      haveNonsummeryData ||
                      haveKnowledgeNuggetData ||
                      haveSharedProjectdata ||
                      haveProjectdata)
                      ? '44.2px'
                      : '54.73px'
                  }
                />
                <Heading
                  display={['none', 'flex']}
                  fontSize="22px"
                  fontWeight={'bold'}
                  letterSpacing="1px"
                  color={
                    location.pathname === '/homepage' &&
                    (haveResourceData ||
                      haveNonsummeryData ||
                      haveKnowledgeNuggetData ||
                      haveSharedProjectdata ||
                      haveProjectdata)
                      ? coloronText
                      : 'black'
                  }
                  fontFamily="body"
                >
                  Elephant Hive
                </Heading>
              </Stack>
            </Box>
            {/* hamberger menu in mobile view */}
            <Menu onClose={onMenuClose}>
              <MenuButton as={'button'} onClick={() => handleClick()}>
                <Box
                  display={['flex', 'flex', 'none']}
                  alignItems="center"
                  justifyContent="center"
                  ml={4}
                  pt={click ? '1' : '0'}
                  cursor="pointer"
                  overflow="hidden"
                >
                  {isMenuOpen ? (
                    <GrClose size={24} />
                  ) : (
                    <AiOutlineMenu size={24} />
                  )}
                </Box>
              </MenuButton>
              <MenuList>
                {onMenuOpen && (
                  <ProfileViewer
                    onCreateKnowledgeNuggetOpen={onCreateKnowledgeNuggetOpen}
                    userLocalData={userLocalData}
                    handleClick={handleClick}
                    storageUsed={storageUsed}
                  />
                )}
              </MenuList>
            </Menu>
          </Flex>
          {/* spacer in middle  */}
          <Spacer />
          {/* middle elephanthive logo */}
          <Box
            display="flex"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            pt={click ? '2' : '2'}
            mr={click ? ['0'] : ['0', '0', '5', 1]}
          >
            <Image
              display={['flex', 'flex', 'none']}
              src={
                location.pathname === '/homepage' &&
                (haveResourceData ||
                  haveNonsummeryData ||
                  haveKnowledgeNuggetData ||
                  haveSharedProjectdata ||
                  haveProjectdata)
                  ? logoOnSize
                  : blacklogo
              }
              alt="The Elephant Hive Logo"
              w={
                location.pathname === '/homepage' &&
                (haveResourceData ||
                  haveNonsummeryData ||
                  haveKnowledgeNuggetData ||
                  haveSharedProjectdata ||
                  haveProjectdata)
                  ? '44.2px'
                  : '54.73px'
              }
              h="31.22px"
              mb={click ? ['4', '0'] : ['0']}
              onClick={() => {
                if (removeAudio !== undefined) {
                  removeAudio();
                }
                history.push('/homepage');
                handleSearchClose();
              }}
            />
          </Box>

          {/* right section */}
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent="center"
            py="4"
            mr={{ md: '6' }}
          >
            {/* create Knugget Button */}
            {/* commentedout for new ui design */}
            {/* <Tooltip
              label="Create Knowledge Nugget"
              placement="bottom"
              color="gray.500"
              bg="gray.100"
            >
              <Button
                display={click ? ['none', 'none', 'flex'] : 'flex'}
                bg="yellow.400"
                colorScheme={'yellow'}
                color="gray.500"
                fontWeight="700"
                fontFamily="Inter"
                fontSize={['14px', '16px']}
                mr={{ base: '4', md: '6' }}
                p="3"
                rounded="4px"
                onClick={() => onCreateKnowledgeNuggetOpen()}
              >
                Create Nugget
              </Button>
            </Tooltip> */}
            {/* knowledge library button */}
            {/* <Tooltip
              label="Knowledge Library"
              placement="bottom"
              color="gray.500"
              bg="gray.100"
            >
              <Button
                display={['none', 'none', 'flex']}
                color="gray.500"
                bg="gray.100"
                fontSize="18px"
                rounded="full"
                p="3"
                mr={['4', '6']}
                onClick={() => {
                  if (removeAudio !== undefined) {
                    removeAudio();
                  }
                  history.push(`/knowledge-library`);
                }}
              >
                <Img boxSize="5" src={BulbLogo} />
              </Button>
            </Tooltip> */}
            {/* commented out for new ui design */}
            {/* empty button with question mark */}
            <Tooltip
              label="For Help, click on the yellow chat icon at the bottom of your screen"
              placement="bottom-start"
              color="gray.500"
              bg="gray.100"
            >
              <Button
                display={['none', 'none', 'flex']}
                color="gray.500"
                bg="gray.100"
                fontSize="18px"
                rounded="full"
                p="3"
                mr={['0', '6']}
              >
                <Icon />
              </Button>
            </Tooltip>
            {/* UserLevel KNGraph Button */}
            {location.pathname === '/homepage' &&
              (haveResourceData ||
                haveNonsummeryData ||
                haveKnowledgeNuggetData) && (
                <Tooltip
                  label="Knowledge Graph"
                  placement="bottom-start"
                  color="gray.500"
                  bg="gray.100"
                >
                  <Button
                    display={['none', 'none', 'flex']}
                    color="gray.500"
                    bg="gray.100"
                    fontSize="18px"
                    rounded="full"
                    p="3"
                    mr={['0', '6']}
                    onClick={() => {
                      if (removeAudio !== undefined) {
                        removeAudio();
                      }
                      onKNGraphOpen();
                    }}
                  >
                    <GrGraphQl />
                  </Button>
                </Tooltip>
              )}
            <Flex align={'center'}>
              {haveshared && sharedRes && sharedRes.length > 0 && (
                <Badge
                  display={['none', 'none', 'flex']}
                  cursor={'pointer'}
                  onClick={onProjectInvitationOpen}
                  style={{
                    WebkitClipPath:
                      'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
                    clipPath:
                      'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
                  }}
                  zIndex={2}
                  variant="solid"
                  bg={'yellow.400'}
                  p={'1.5'}
                  mr={'-3'}
                  mt={'5'}
                  border="1.5px solid #ECC94B"
                  fontWeight={'bold'}
                  fontSize={'10px'}
                  fontFamily={'body'}
                  color={'gray.800'}
                >
                  <Flex align={'center'}>{sharedRes.length}</Flex>
                </Badge>
              )}
              <Menu onClose={onMenuClose}>
                <MenuButton as={'button'} onClick={() => handleClick()}>
                  <Box
                    display={['none', 'none', 'flex']}
                    w={'50px'}
                    h={'50px'}
                    border="4px"
                    borderColor={isMenuOpen ? 'yellow.500' : 'white'}
                    rounded="full"
                    objectFit="cover"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      as={motion.div}
                      colorScheme={'yellow'}
                      animate={{
                        scale: click ? [1, 1.1] : [1, 1],
                      }}
                      initial="initial"
                      icon={
                        <Box
                          position="relative"
                          alignItems="center"
                          justifyContent="center"
                          mr={5}
                          mb={5}
                        >
                          <Center
                            zIndex={1}
                            position="absolute"
                            top={'50%'}
                            left={'50%'}
                            as={motion.div}
                            animate={{
                              scale: click ? [1, 1.3] : [1.4, 1.0],
                              opacity: [0, 1],

                              transition: {
                                delay: 12.4,
                              },
                            }}
                            initial={{
                              opacity: 0,
                            }}
                          >
                            <AiOutlineUser
                              style={{ border: '0.7px', color: '#718096' }}
                            />
                          </Center>
                          <Center
                            as={motion.div}
                            position="absolute"
                            top={'50%'}
                            left={'50%'}
                            animate={{
                              scale: [2.3, 1.4],
                              opacity: [1, 0],

                              transition: {
                                duration: 10.0,
                              },
                            }}
                          >
                            <BiUserCircle
                              style={{ border: '0.4px', color: '#718096' }}
                            />
                          </Center>
                        </Box>
                      }
                      cursor={'pointer'}
                      w="42px"
                      h="42px"
                      bg={'gray.200'}
                    />
                  </Box>
                </MenuButton>
                <MenuList>
                  {onMenuOpen && (
                    <ProfileViewer
                      onCreateKnowledgeNuggetOpen={onCreateKnowledgeNuggetOpen}
                      userLocalData={userLocalData}
                      handleClick={handleClick}
                      storageUsed={storageUsed}
                    />
                  )}
                </MenuList>
              </Menu>
            </Flex>
          </Box>
          {onCreateKnowledgeNuggetOpen && (
            <CreateKnowledgeNugget
              updateKnowledgeNuggetData={updatehivedata}
              setUpdateKnowledgeNuggetData={setUpdatehivedata}
              isOpen={isCreateKnowledgeNuggetOpen}
              onClose={onCreateKnowledgeNuggetClose}
              removeAudio={removeAudio}
            />
          )}
        </Flex>
      </Box>
      {haveUsers && haveshared && onProjectInvitationOpen && (
        <ProjectInvitation
          isOpen={isProjectInvitationOpen}
          onClose={onProjectInvitationClose}
          sharedRes={sharedRes}
          userList={userList}
          update={update}
          setUpdate={setUpdate}
          updatehivedata={updatehivedata}
          setUpdatehivedata={setUpdatehivedata}
        />
      )}
      {onKNGraphOpen && (
        <UserLevelKNGraph
          isOpen={isKNGraphOpen}
          onClose={onKNGraphClose}
          resourceData={resourceData}
          nonsummaryhiveData={nonsummaryhiveData}
          knowledgeNuggetData={knowledgeNuggetData}
          knowledgeLibraryData={knowledgeLibraryData}
        />
      )}
    </Box>
  );
}

export default Header2;
